import React from 'react';
import { observer, inject } from 'mobx-react';
import HomeIcon from 'img/icons/techfires/card/home.svg';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
export default class Effects extends React.Component {
	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('effects', areFieldsFilled);
	};

	render() {
		const { fire } = this.props.fireStore;
		const rows = [
			[
				{ property: 'name', label: 'techFireCauseType.title', type: 'relation', relation: 'causeType' },
				{ property: 'empty', type: 'default' }, // по типу <div />
			],
			[
				{ property: 'destroyedBuildings', label: 'techFire.destroyedBuildings', type: 'NumberInput' },
				{ property: 'area', label: 'techFire.area', type: 'NumberInput' },
				{ property: 'destroyedVehicles', label: 'techFire.destroyVeh', type: 'NumberInput' },
				{ property: 'destroyedLivestock', label: 'techFire.destroyAnimals', type: 'NumberInput' },
			],
			[{ property: 'addition', label: 'techFire.addition', type: 'textarea', props: { rows: 4 } }],
		];

		return (
			<FieldBasedCard
				title='techFire.effects'
				icon={HomeIcon}
				fields={[fire?.causeType?.name, fire.destroyedBuildings, fire.destroyedVehicles, fire.destroyedLivestock, fire.addition]}
				rows={rows}
				statusKey='effects'
				fireStore={this.props.fireStore}
				isCard={true}
			/>
		);
	}
}
