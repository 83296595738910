import React from 'react';
import { inject, observer } from 'mobx-react';
import CustomTechFireCard from './CustomTechFireCard';
import FieldChecker from '../../helpers/fieldChecker';
import { Card } from 'components';
import t from 'i18n';
import PropTypes from 'prop-types';
import { observable } from 'mobx';

/**
 * FieldBasedCard компонент, который рендерит карточку с динамическим набором полей и проверяет их заполненность.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.fireStore - Хранилище для управления состоянием.
 * @param {string} props.statusKey - Ключ статуса для обновления состояния в хранилище.
 * @param {string} props.title - Заголовок карточки, локализованный ключ.
 * @param {React.ComponentType} props.icon - Иконка для карточки.
 * @param {Array} [props.rows=[]] - Массив строк (для верстки - один под другим), где каждая строка содержит массив объектов полей.
 * @param {Array} rows[].fields - Массив объектов, представляющих поля.
 * @param {string} fields[].property - Имя свойства поля.
 * @param {string} fields[].label - Локализованный ключ для метки поля.
 * @param {string} fields[].type - Тип поля (DatePicker, textarea, NumberInput, Select, Input, LabelField, relation, label).
 * @param {Object} [fields[].props] - Дополнительные свойства для компонента поля.
 * @param {string} [fields[].relation] - Используется для отношения, если type равен 'relation'.
 * @param {boolean} [props.isCard=true] - Флаг для определения, нужно ли использовать CustomTechFireCard или Card.
 * @param {React.ReactNode} [props.children] - Дети компонента, если используется Card.
 */

@inject('fireStore')
@observer
class FieldBasedCard extends React.Component {
	@observable collapsed = false;

	handleStatusChange = (areFieldsFilled) => {
		const { fireStore, statusKey } = this.props;
		fireStore.updateFieldStatuses(statusKey, areFieldsFilled);
	};

	handleCollapseToggle = () => {
		this.collapsed = !this.collapsed;
	};

	renderCard = (areFieldsFilled) => {
		const { title, icon, rows, isCard } = this.props;
		const commonProps = {
			title: isCard ? title : t(`${title}`),
			icon,
			isValid: areFieldsFilled,
			collapsible: true,
			collapsed: this.collapsed,
			onCollapseToggle: this.handleCollapseToggle,
		};

		if (isCard) {
			return <CustomTechFireCard {...commonProps} rows={rows} />;
		}

		return <Card {...commonProps}>{this.props.children}</Card>;
	};

	render() {
		const { fields } = this.props;

		return (
			<FieldChecker
				fields={fields.map((field) => (typeof field === 'function' ? field(this.props.fireStore.fire) : field))}
				onStatusChange={this.handleStatusChange}
			>
				{this.renderCard}
			</FieldChecker>
		);
	}
}

FieldBasedCard.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.elementType.isRequired,
	fields: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.bool,
			PropTypes.func,
			PropTypes.shape({
				type: PropTypes.string.isRequired,
				property: PropTypes.string.isRequired,
				label: PropTypes.string,
				props: PropTypes.object,
			}),
		])
	).isRequired,
	rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
	statusKey: PropTypes.string.isRequired,
	fireStore: PropTypes.object.isRequired,
	isCard: PropTypes.bool,
	children: PropTypes.node,
};

FieldBasedCard.defaultProps = {
	isCard: false,
	children: null,
};

export default FieldBasedCard;
