import { Column, RecordSelect, Checkbox, Pager, Table, DatePicker } from '@smartplatform/ui';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { ListStore, Toolbar } from 'components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import './style.scss';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

const FILTER_INCLUDE = ['type'];

@observer
export default class List extends React.Component {
	@observable name = null;
	@observable working = null;
	@observable division = null;
	@observable type = null;
	@observable filter = {};
	@observable inspectorSearchName = '';
	@observable inspectorSearchOrganization = '';
	@observable garrison = null;

	constructor(props) {
		super(props);
		//this.store = new ListStore({ path: props.path, fields: {include: FILTER_INCLUDE }, model: store.model[props.modelName]});
		this.store = new ListStore({ path: props.path, fields: { include: FILTER_INCLUDE }, model: store.model['ViewWaterSupply'] });
		this.overdueInspectionFilter = false;
		this.inspectionDateFrom = null;
		this.inspectionDateTo = null;
		this.updateFilter();
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.updateFilter();
	};

	onSearchInspector = (e) => {
		this.inspectorSearchName = e.target.value;
		this.updateFilter();
	};

	onSearchInspectorOrg = (e) => {
		this.inspectorSearchOrganization = e.target.value;
		this.updateFilter();
	};

	updateFilter = () => {
		const filter = {};
		filter.where = { and: [] };
		if (this.garrison) {
			filter.where.and.push({ garrisonId: this.garrison.id });
		}
		if (this.name) {
			filter.where.and.push({ name: this.name.name });
		}
		if (this.working) {
			filter.where.and.push({ working: this.working === true });
		}
		if (this.type) {
			filter.where.and.push({ typeId: this.type.id });
		}
		if (this.dateLastCheck) {
			filter.where.and.push({ dateLastCheck: this.dateLastCheck });
		}
		if (this.scheduledInspectionDate) {
			filter.where.and.push({ scheduledInspectionDate: this.scheduledInspectionDate });
		}
		if (this.inspectorSearchName) {
			const trimmed = this.inspectorSearchName.trim();
			if (trimmed.length > 0) {
				const words = trimmed.replace(',', ' ').replace(/\s+/g, ' ').split(' ');
				const or = [];
				const and = words.map((word) => ({
					or: [
						{ inspectorFullName: { ilike: `%${word}%` } },
						{ inspectorFirstName: { ilike: `%${word}%` } },
						{ inspectorLastName: { ilike: `%${word}%` } },
						{ inspectorMiddleName: { ilike: `%${word}%` } },
					],
				}));
				or.push({ and });
				filter.where.and.push({ or });
			}
		}
		if (this.inspectorSearchOrganization) {
			const trimmed = this.inspectorSearchOrganization.trim();
			if (trimmed.length > 0) {
				const words = trimmed.replace(',', ' ').replace(/\s+/g, ' ').split(' ');
				const or = [];
				const and = words.map((word) => ({
					or: [{ inspectorOrganization: { ilike: `%${word}%` } }, { orgName: { ilike: `%${word}%` } }],
				}));
				or.push({ and });
				filter.where.and.push({ or });
			}
		}
		if (this.overdueInspectionFilter) {
			filter.where.and.push({ scheduledInspectionDate: { lt: Date.now() } });
		}
		if (this.inspectionDateFrom || this.inspectionDateTo) {
			const start = this.inspectionDateFrom ? startOfDay(new Date(this.inspectionDateFrom)) : null;
			const end = this.inspectionDateTo ? endOfDay(new Date(this.inspectionDateTo)) : null;
			const and = [];
			if (start) and.push({ scheduledInspectionDate: { gte: start } });
			if (end) and.push({ scheduledInspectionDate: { lte: end } });
			filter.where.and.push({ and });
		}
		filter.include = FILTER_INCLUDE;
		this.filter = filter;
		this.store.updateFilters(this.filter);
	};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
				</Toolbar>
				<div className='registry-waterSupply-filters'>
					<GarrisonSelect value={this.garrison} onChange={this.onChange('garrison')} width={200} />
					<RecordSelect
						model={store.model.WaterSupply}
						property='name'
						value={this.name}
						onChange={this.onChange('name')}
						width={150}
						placeholder={t('waterSupply.name')}
					/>
					<RecordSelect
						model={store.model.WaterSupplyType}
						property='name'
						value={this.type}
						onChange={this.onChange('type')}
						width={150}
						placeholder={t('waterSupply.type')}
					/>
					<label>{t('waterSupply.working')}</label>
					<Checkbox value={this.working} onChange={this.onChange('working')} />
					<label>{t('waterSupply.overdueInspection')}</label>
					<Checkbox value={this.overdueInspectionFilter} onChange={this.onChange('overdueInspectionFilter')} />
				</div>
				<div className='registry-waterSupply-filters'>
					<div className='form-field'>
						<label>{t('waterSupply.dateLastCheck')}</label>
						<DatePicker
							model={store.model.WaterSupply}
							property='dateLastCheck'
							value={this.dateLastCheck}
							onChange={this.onChange('dateLastCheck')}
							placeholder={t('waterSupply.dateLastCheck')}
						/>
					</div>
					<div className='form-field'>
						<label>{t('waterSupply.scheduledInspectionDate')}</label>
						<DatePicker
							model={store.model.WaterSupply}
							property='scheduledInspectionDate'
							value={this.scheduledInspectionDate}
							onChange={this.onChange('scheduledInspectionDate')}
							placeholder={t('waterSupply.scheduledInspectionDate')}
						/>
					</div>
					<div className='form-field'>
						<label>{t('waterSupply.inspectionDateFrom')}</label>
						<DatePicker value={this.inspectionDateFrom} onChange={this.onChange('inspectionDateFrom')} placeholder={t('waterSupply.inspectionDateFrom')} />
					</div>
					<div className='form-field'>
						<label>{t('waterSupply.inspectionDateTo')}</label>
						<DatePicker value={this.inspectionDateTo} onChange={this.onChange('inspectionDateTo')} placeholder={t('waterSupply.inspectionDateTo')} />
					</div>
				</div>
				<div className='registry-waterSupply-filters'>
					<div className='form-field'>
						<label>{t('waterSupply.inspectorFullName')}</label>
						<Toolbar.SearchIconInput value={this.inspectorSearchName} onChange={this.onSearchInspector} />
					</div>
					<div className='form-field'>
						<label>{t('waterSupply.inspectorOrganization')}</label>
						<Toolbar.SearchIconInput value={this.inspectorSearchOrganization} onChange={this.onSearchInspectorOrg} />
					</div>
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps}>
					<Column property='id' label={t('id')} width={80} />
					<Column property='name' label={t('waterSupply.name')} />
					<Column property='working' label={t('waterSupply.working')} />
					<Column property='number' label={t('waterSupply.number')} />
					<Column property='createdAt' label={t('createdAt')} />
					<Column property='updatedAt' label={t('updatedAt')} />
					<Column relation='division' property='name' label={t('waterSupply.division')} />
					<Column relation='type' property='name' label={t('waterSupply.type')} />
				</Table>
			</>
		);
	}
}
