import { IconButton } from './IconButton';
import t from 'i18n';
import './style.scss';
import React from 'react';
import FilterIcon from 'img/icons/filter.svg';
import PlusIcon from 'img/icons/plus.svg';
import ExportIcon from 'img/icons/shared/export.svg';
import CrossIcon from 'img/icons/cross.svg';
import ContragentsIcon from 'img/icons/shared/contragents.svg';
import CloseIcon from 'img/icons/shared/x-close.svg';
import { faCopy, faTimes, faUpload, faBullseye, faBan, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopconfirmButton } from './PopconfirmButton';
import RecycleIcon from 'img/icons/shared/delete.svg';
export * from './RowAddButton';
export * from './PopconfirmButton';

const makeIconButton = (funcProps) => (props) => <IconButton {...funcProps} {...props} />;

export const BanButton = makeIconButton({ icon: <FontAwesomeIcon icon={faBan} />, className: 'ban-button' });
export const BullsEyeButton = makeIconButton({ icon: <FontAwesomeIcon icon={faBullseye} />, className: 'bulls-eye-button' });
export const AddButton = makeIconButton({ icon: <PlusIcon />, className: 'plus-btn' });
export const AddButtonSecondary = makeIconButton({ icon: <PlusIcon />, className: 'add-btn-secondary' });
export const FilterButton = makeIconButton({ icon: <FilterIcon />, className: 'filter-btn', text: t('_filter') });
export const ExportButton = makeIconButton({ icon: <ExportIcon />, className: 'export-btn', text: t('export') });
export const UploadButton = makeIconButton({ icon: <FontAwesomeIcon icon={faUpload} />, className: 'upload-btn', text: t('upload') });
export const CleanButton = makeIconButton({ className: 'clean-btn', text: t('clean') });
export const CleanFiltersButton = makeIconButton({ icon: <CloseIcon />, text: t('cleanFilters'), className: 'clean-filters-btn' });
export const SaveButton = makeIconButton({ text: t('save') });
export const CancelButton = makeIconButton({ className: 'cancel-btn', text: t('cancel') });
export const AddMemberButton = makeIconButton({ icon: <ContragentsIcon />, className: 'add-contragent-button ', text: t('request.addMember') });
export const CloseButton = makeIconButton({ className: 'close-btn', icon: <CrossIcon /> });
export const CopyButton = makeIconButton({ icon: <FontAwesomeIcon icon={faCopy} />, text: t('copy') });
export const DefaultButton = makeIconButton({ className: 'default-btn' });

const makePopconfirmButton = (funcProps) => (props) => <PopconfirmButton {...funcProps} {...props} />;
export const DeleteButton = makePopconfirmButton({ content: t('delete'), icon: <RecycleIcon />, variant: 'default', className: 'delete-button' });
export const PopconfirmFaTimesIcon = makePopconfirmButton({
	children: <FontAwesomeIcon className='fa-icon popconfirm-fa-times' icon={faTimes} />,
	content: t('delete'),
});

export const PopconfirmFaTrashIcon = makePopconfirmButton({
	children: <FontAwesomeIcon className='fa-icon popconfirm-fa-trash' icon={faTrash} />,
	content: t('delete'),
});

export const FaIcon = (props) => <FontAwesomeIcon className='fa-icon' {...props} />;
