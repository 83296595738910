import { observable, runInAction } from 'mobx';
import store from 'client/store';
import { replaceDecimal } from 'client/tools';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import { Icon, Style } from 'ol/style';
import markerIconGeo from 'img/icons/marker.svg?url';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { AddressLayer, WaterSupplyLayer } from 'components';
import { MENU_ITEMS } from './constants';

export default class TechFireStore {
	@observable id;
	@observable fire = null;
	@observable vehicles = [];
	@observable isLoading = true;
	@observable changed = false;
	@observable fieldStatuses = {};
	@observable barrelsCount = 0;

	constructor(id) {
		this.id = id;
		this.init();
		this.initializeFieldStatuses();
	}

	init = async () => {
		this.isLoading = true;
		if (this.id) {
			this.fire = await store.model.TechFire.findById(this.id, {
				include: [
					{
						relation: 'object',
						scope: {
							include: [
								{ relation: 'address', scope: { fields: ['id', 'name', 'geo'] } },
								{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
							],
						},
					},
					{ relation: 'source' },
					{ relation: 'rank' },
					{ relation: 'chiefs' },
					{ relation: 'trunks', scope: { fields: ['id', 'county'] } },
					{
						relation: 'alarmMessage',
						scope: {
							include: ['adpiDevice'],
						},
					},
					{ relation: 'wayBills', scope: { fields: ['id'] } },
					{ relation: 'casualties', scope: { fields: ['id'] } },
					{ relation: 'service', scope: { fields: ['id'] } },
				],
			});
			this.fire.fireDepDistance = replaceDecimal(this.fire.fireDepDistance, ',');
			this.fire.damageNumber = replaceDecimal(this.fire.damageNumber, ',');
			this.calculateBarrelsCount();
			if (this.fire.object?.description && !this.fire.description) {
				this.fire.description = this.fire.object?.description;
			}
			await this.updateVehicles();
		} else {
			this.fire = new store.model.TechFire();
		}

		this.isLoading = false;
	};

	updateVehicles = async () => {
		const wayBills = await store.model.TechFireWayBill.find({
			where: { fireId: this.id },
			include: [{ relation: 'vehicles', scope: { fields: ['id'] } }],
		});
		const vehicles = [];
		for (let wayBill of wayBills) {
			vehicles.push(...wayBill.vehicles());
		}
		const allVehicles = await store.model.ViewFireDepVehicle.find({ where: { id: { inq: vehicles.map((v) => v.id) } } });
		this.vehicles = allVehicles;
	};

	calculateBarrelsCount = async () => {
		if (this.fire.trunks()) {
			const trunks = await store.model.TechFireTrunk.find({ where: { fireId: this.id } });
			const countyArray = trunks.map((trunk) => trunk.county);
			this.barrelsCount = countyArray.reduce((acc, count) => acc + count, 0);
			this.fire.barrelsCount = this.barrelsCount;
		}
	};

	beforeSave = async () => {
		this.fire.fireDepDistance = await replaceDecimal(this.fire.fireDepDistance, '.', ',');
		this.fire.damageNumber = await replaceDecimal(this.fire.damageNumber, '.', ',');
	};

	onSave = async () => {
		const isNew = !this.fire.id;
		await this.fire.save();
		if (isNew) {
			store.route.push({ path: `${this.path}/${this.fire.id}` });
		}
		this.fire.fireDepDistance = replaceDecimal(this.fire.fireDepDistance, ',');
		this.fire.damageNumber = replaceDecimal(this.fire.damageNumber, ',');
		store.ui.setNotification({
			message: 'Запись сохранена',
			type: 'SUCCESS',
		});
	};

	deleteFire = async () => {
		await this.fire.delete();
		this.goBack();
	};

	clearMainInfo = () => {
		this.fire.phoneOperator = null;
	};

	onChange = (prop) => (value) => {
		this.fire[prop] = value;
		if (prop === 'reportTime') this.fire.detectTime = value - 60000;
		this.updateFieldStatus(prop, value);
	};

	initializeFieldStatuses() {
		this.fieldStatuses = MENU_ITEMS.reduce((statuses, { value }) => {
			statuses[value] = false;
			return statuses;
		}, {});
	}

	updateFieldStatus = (prop, value) => {
		const isFilled = value != null && value !== '';
		this.fieldStatuses[prop] = isFilled;
	};

	updateFieldStatuses = (value, areFieldsFilled) => {
		const initialStatuses = MENU_ITEMS.reduce((statuses, { value }) => {
			statuses[value] = false;
			return statuses;
		}, {});

		// Обновляем статус конкретного поля
		const updatedStatuses = {
			...initialStatuses,
			...this.fieldStatuses,
			[value]: areFieldsFilled,
		};

		// Устанавливаем обновленные статусы
		runInAction(() => {
			this.fieldStatuses = updatedStatuses;
		});
	};

	getFieldStatus = (field) => this.fieldStatuses[field] || false;

	goBack = () => store.route.push({ path: this.path });

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.mapStore.addLayer(this.layer);
		this.waterSupplyLayer = new WaterSupplyLayer(mapStore, {
			show: true,
			filter: {
				selectedTypes: [],
			},
		});
		this.addressLayer = new AddressLayer(mapStore, {
			show: true,
			filter: {
				fields: {
					overnightStay: true,
				},
				ranks: [],
			},
		});
		this.waterSupplyLayer.init(mapStore);
		this.addressLayer.init(mapStore);
		this.mapInitialized = true;
		if (this.fire.object) {
			this.drawObjectOnMap();
		}
	};

	onObjectChange = async (object) => {
		this.errors = [];
		console.log('onObjectChange', object, object?.rank, object?.address);
		this.fire.object = object;
		this.fire.fireDep = null;
		this.fireDeps = {};
		if (object?.address) {
			if (object.rank) {
				this.fire.rank = object.rank;
			}
			if (this.mapInitialized) {
				this.drawObjectOnMap();
			}

			const fireDep = (await object.address.getFireDep())[0];
			if (fireDep) {
				await this.onFireDepChange(fireDep);
			}
		}
		if (this.fire.object?.description && !this.fire.description) {
			this.fire.description = this.fire.object?.description;
		}
	};

	drawObjectOnMap = () => {
		const { geo } = this.fire.object.address;
		const marker = drawGeoMarker(geo, {
			style: new Style({
				image: new Icon({
					src: markerIconGeo,
					anchor: [9, 45],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			}),
		});
		this.layer.getSource().clear();
		this.layer.getSource().addFeatures([...marker]);
		this.mapStore.fitGeo(geo, { maxZoom: 18, padding: [50, 50, 50, 50] });
	};

	onObjectReset = () => {
		this.fire.object = null;
		this.layer.getSource().clear();
		this.wayBillStore.fireDeps = {};
		this.fire.rank = this.firstRank[0] || null;
	};

	onFireDepChange = async (fireDep) => {
		this.fire.fireDep = fireDep;
	};
}
