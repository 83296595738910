import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Table, Column } from '@smartplatform/ui';
import store from 'client/store';
import { LabelField } from 'components';
import t from 'i18n';
import { format } from 'date-fns';

@observer
export class ShipPopup extends React.Component {
	@observable record = {};

	componentDidMount = async () => {
		this.record = await store.model.RequestShip.findById(this.props.record.id, {
			include: [{ relation: 'ladingBills', scope: { include: [{ relation: 'destinationCity', scope: { fields: ['id', 'name'] } }] } }],
		});
	};

	render() {
		const ladingBills = this.record.ladingBills?.() || [];
		return (
			<div>
				<Row>
					<LabelField label={t('name')} children={this.record.name ?? '-'} />
					<LabelField label={t('nameLat')} children={this.record.nameLat ?? '-'} />
				</Row>
				<Row>
					<LabelField label={t('ship.callSign')} children={this.record.callSign ?? '-'} />
					<LabelField label={t('ship.imo')} children={this.record.imo ?? '-'} />
				</Row>
				<Row>
					<LabelField label={t('speed')} children={this.record.speed ?? '-'} />
					<LabelField label={t('course')} children={this.record.course ?? '-'} />
				</Row>
				<Row>
					<LabelField label={t('ship.marineReg')} children={this.record.marineReg ?? '-'} />
					<LabelField label={t('ship.source')} children={this.record.source ?? '-'} />
				</Row>
				<Row>
					<LabelField label={t('ship.receiveDate')} children={this.record.receiveDate ? format(new Date(this.record.receiveDate), 'dd.MM.yyyy HH:mm') : '-'} />
				</Row>
				{ladingBills.length > 0 && (
					<>
						<h3 style={{ marginBottom: 10 }}>{t('ladingBill.plural')}</h3>
						<Table rows={ladingBills} onRowClick={({ id }) => store.route.push({ path: `/delivery/tracking/${id}` })}>
							<Column property='trackNumber' label={t('tracking.trackNumber')} />
							<Column relation='destinationCity' property='name' label={t('request.destinationCity')} />
						</Table>
					</>
				)}
			</div>
		);
	}
}
