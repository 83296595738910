import React from 'react';
import { inject, observer } from 'mobx-react';
import { Card, LabelField, TechFireAddressSearch } from 'components';
import MapPin from 'img/icons/techfires/card/fi_map-pin.svg';
import t from 'i18n';
import { Row, Field, Input, Button } from '@smartplatform/ui';
import { getPrefixByCountyName } from 'client/tools/getPrefixByCountyName';
import store from 'client/store';
import { Map } from '@smartplatform/map/client';
import './style.scss';
import FieldChecker from '../../../../helpers/fieldChecker';
import { observable } from 'mobx';

@inject('fireStore')
@observer
export default class AboutObject extends React.Component {
	@observable collapsed = false;
	constructor(props) {
		super(props);
	}

	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('aboutObject', areFieldsFilled);
	};
	handleCollapseToggle = () => {
		this.collapsed = !this.collapsed;
	};

	render() {
		const { fire, onMapInit, onObjectChange, onObjectReset } = this.props.fireStore;

		return (
			<FieldChecker fields={[fire.additionalInfo, fire.description]} onStatusChange={this.handleStatusChange}>
				{(areFieldsFilled) => (
					<Card
						title={t('aboutObject')}
						icon={<MapPin />}
						isValid={areFieldsFilled}
						collapsible
						collapsed={this.collapsed}
						onCollapseToggle={this.handleCollapseToggle}
					>
						<div className='techfire-card-form'>
							<div className='left'>
								<Row className='card-row'>
									<LabelField>
										<Field relation='object' label={t('techFireAddress.title')}>
											<TechFireAddressSearch
												value={fire.object}
												onChange={onObjectChange}
												onReset={onObjectReset}
												prefix={getPrefixByCountyName(store.userCounty?.name)}
											/>
										</Field>
										<Row className='card-left-row'>
											<Field property='additionalInfo' className='left-row-field' label={t('fireAlarm.objectBuildingManualEntry')} />
											<Row>
												<Row />
												<Button variant='primary'>{t('fire.pickPoint')}</Button>
											</Row>
										</Row>

										<Field property='description' label={t('techFireAddress.description')}>
											<textarea rows={3} />
										</Field>
									</LabelField>
								</Row>
								<Row className='card-row'>
									<LabelField label={t('techFireAddress.title')}>
										<Input value={fire.object?.name} disabled />
									</LabelField>
									<LabelField label={t('techFireAddress.fireDep')}>
										<Input value={fire.fireDep?.name} disabled />
									</LabelField>
								</Row>
								<Row className='card-row'>
									<LabelField label={t('garrison')}>
										<Input value={fire.garrison?.name} disabled />
									</LabelField>
									<LabelField label={t('techFireAddress.ptp')}>
										<Input value={fire.object?.ptp} disabled />
									</LabelField>
									<LabelField label={t('techFireAddress.ktp')}>
										<Input value={fire.object?.ktp} disabled />
									</LabelField>
								</Row>
							</div>
							<div className='right'>
								<Row className='card-row map'>
									<Map onInit={onMapInit} params={store.config.map} className='techfire-create-map' />
								</Row>
							</div>
						</div>
					</Card>
				)}
			</FieldChecker>
		);
	}
}
