import { Column, Pager, RecordSelect, Table } from '@smartplatform/ui';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { ListStore, Toolbar } from 'components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import './style.scss';
import { renderStatus } from 'client/tools';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

const FILTER_INCLUDE = ['organization', 'status'];

@observer
export default class List extends React.Component {
	@observable brand = null;
	@observable organization = null;
	@observable status = null;
	@observable garrison = null;
	@observable filter = {};

	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, fields: { include: FILTER_INCLUDE }, model: store.model.ViewAdpiDevice });
		this.updateFilter();
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.updateFilter();
	};

	updateFilter = () => {
		const filter = {};
		filter.where = { and: [] };
		if (this.garrison) filter.where.and.push({ garrisonId: this.garrison.id });
		if (this.organization) {
			filter.where.and.push({ organizationId: this.organization.id });
		}
		if (this.status) {
			filter.where.and.push({ statusId: this.status.id });
		}
		filter.include = FILTER_INCLUDE;
		this.filter = filter;
		this.store.updateFilters(this.filter);
	};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
				</Toolbar>
				<div className='filterSelectsAdpi'>
					<div className='selectBox'>
						<GarrisonSelect value={this.garrison} onChange={this.onChange('garrison')} width={200} />
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.FireAlarmOrganizationAdpiService}
							property='name'
							value={this.organization}
							onChange={this.onChange('organization')}
							width={150}
							placeholder={t('adpiDevice.organization')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.AdpiDeviceStatus}
							computed={renderStatus}
							value={this.status}
							onChange={this.onChange('status')}
							width={150}
							placeholder={t('adpiDevice.status')}
						/>
					</div>
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps}>
					<Column property='id' label={t('id')} width={80} />
					<Column property='name' label={t('county.name')} />
					<Column property='phoneNumber' label={t('adpiDevice.phoneNumber')} />
					<Column property='contacts' label={t('adpiDevice.contacts')} />
					<Column relation='status' computed={renderStatus} label={t('adpiDevice.status')} />
					<Column relation='type' property='capacity' label={t('adpiDevice.type')} />
					<Column relation='model' property='name' label={t('adpiDevice.model')} />
					<Column relation='address' property='name' label={t('adpiDevice.address')} />
					<Column relation='organization' property='name' label={t('adpiDevice.organization')} />
				</Table>
			</>
		);
	}
}
