import React from 'react';
import t from 'i18n';
import { Column, Pager, Table } from '@smartplatform/ui';
import store from 'client/store';
import { ListStore, Toolbar } from 'components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import './style.scss';

@observer
export default class List extends React.Component {
	@observable garrison = null;
	@observable filter = {};
	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, fields: this.filter, model: store.model[props?.listModelName || props.modelName] });
		this.updateFilter();
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.updateFilter();
	};

	updateFilter = () => {
		const filter = {};
		filter.where = { and: [] };
		if (this.garrison) filter.where.and.push({ garrisonId: this.garrison.id });
		this.filter = filter;
		this.store.updateFilters(this.filter);
	};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;
		return (
			<>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
				</Toolbar>
				<div className='filterSelectsBlockedRoad'>
					<div className='selectBox'>
						<GarrisonSelect value={this.garrison} onChange={this.onChange('garrison')} width={200} />
					</div>
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps}>
					{this.props.listProperties.map((property) => {
						return <Column property={property} label={t(`techFireBlockedRoad.${property}`)} />;
					})}
				</Table>
			</>
		);
	}
}
