import React from 'react';
import { observer } from 'mobx-react';
import { ColorValue, Column, DateValue, Pager, Table } from '@smartplatform/ui';
import { getLabelName } from 'client/tools';
import { DateRangePicker, Toolbar } from 'components';
import { ListStore } from 'components/stores';
import { observable } from 'mobx';
import { EXCLUDED_FIELDS } from './constants';
import PropTypes from 'prop-types';
import { startOfYear } from 'date-fns';
import store from 'client/store';
import './universalList.scss';
@observer
export class UniversalList extends React.Component {
	static propTypes = {
		listRelations: PropTypes.array,
		listProperties: PropTypes.array,
		excludedProperties: PropTypes.array,
		isRangeFiltering: PropTypes.bool,
		listWhere: PropTypes.object,
	};

	static defaultProps = {
		listRelations: [],
		listProperties: [],
		excludedProperties: [],
		isRangeFiltering: false,
	};

	@observable error = null;
	@observable dateRangeValues = {
		period: 'year',
		periodDate: new Date(),
		startDate: null,
		endDate: null,
	};

	constructor(props) {
		super(props);
		this.modelName = props.modelName;
		this.isRangeFiltering = props.isRangeFiltering;
		this.model = store.model[this.modelName];
		if (!this.model) {
			this.error = 'Invalid model/no read access';
		} else {
			const { listProperties, listRelations, excludedProperties } = props;
			const { PROPERTIES, RELATIONS } = this.model;
			this.relations = [...listRelations];
			if (!listProperties.length) {
				// если мы не передаём проперти , то берем все за исключением технических ,объектов и гео
				const modelForeignKeys = Object.values(RELATIONS).map(({ foreignKey }) => foreignKey);
				this.properties = Object.keys(PROPERTIES).filter((prop) => {
					return ![...EXCLUDED_FIELDS, ...modelForeignKeys].includes(prop) && !['Object', 'Geography'].includes(PROPERTIES[prop].type);
				});
			} else {
				this.properties = [...listProperties];
			}

			if (excludedProperties.length) {
				this.properties = this.properties.filter((prop) => !excludedProperties.includes(prop));
			}

			// фильтры
			const relationFields = listRelations.map(({ relation }) => RELATIONS[relation]?.foreignKey);
			const fields = ['id', ...this.properties, ...relationFields];
			const include = listRelations.map(({ relation, property, properties = [] }) => ({
				relation,
				scope: { fields: property || properties.filter(Boolean) },
			}));
			const where = { and: [] };
			if (props.listWhere) where.and.push(props.listWhere);
			if (this.isRangeFiltering && this.dateRangeValues.startDate) where.and.push({ createdAt: { gte: this.dateRangeValues.startDate } });
			if (this.isRangeFiltering && this.dateRangeValues.endDate) where.and.push({ createdAt: { lte: this.dateRangeValues.endDate } });
			this.store = new ListStore({ path: props.path, filters: { where, fields, include }, model: this.model });
		}
	}

	reloadFilters = () => {
		this.store.query.where.and = [];
		if (this.isRangeFiltering && this.dateRangeValues.startDate) this.store.query.where.and.push({ createdAt: { gte: this.dateRangeValues.startDate } });
		if (this.isRangeFiltering && this.dateRangeValues.endDate) this.store.query.where.and.push({ createdAt: { lte: this.dateRangeValues.endDate } });
		this.store.reload();
	};

	componentDidMount() {
		if (!this.error) this.store.setPerPage();
	}

	get exportUrl() {
		const esc = encodeURIComponent;
		const path = '/api/modelRecordsToExcel';
		const { limit, skip, _totalCount, ...restQuery } = this.store.query;
		const cols = [];
		const modelName = this.modelName.replace(/^View/, '');
		this.properties.forEach((property) => {
			cols.push({ property, label: getLabelName(property, modelName) });
		});
		this.relations.forEach(({ relation, property }) => {
			cols.push({ property, label: getLabelName(relation, modelName), relation });
		});

		const params = { modelName: this.modelName, filters: {}, cols };

		// уборка пустых квери параметров
		if (restQuery instanceof Object) {
			for (const key in restQuery) {
				const isFilledKey = restQuery[key]?.length || Object.keys(restQuery[key] || {}).length;
				if (isFilledKey) {
					params.filters[key] = restQuery[key];
				}
			}
		}

		const query = '?params=' + esc(JSON.stringify(params));
		return path + query;
	}

	render() {
		if (this.error) return this.error;
		const { onChange, totalCount, page, perPage, search, onSearch, create, tableProps } = this.store;

		return (
			<div className='model-list universal-list'>
				<Toolbar>
					{this.model.INFO.WRITE && <Toolbar.AddButton onClick={create} />}
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
					<Toolbar.ExportButton exportUrl={this.exportUrl} disabled={!totalCount} />
				</Toolbar>
				{this.isRangeFiltering && (
					<div className='range-picker-filters'>
						<DateRangePicker dateValues={this.dateRangeValues} onChange={this.reloadFilters} />
					</div>
				)}
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />

				<div id='table'>
					<Table {...tableProps}>
						{this.properties.map((prop) => {
							const label = getLabelName(prop, this.modelName.replace(/^View/, ''));
							const width = prop === 'id' ? 100 : undefined;
							let children;
							if (prop === 'color') children = <ColorValue />;
							else if (this.model.PROPERTIES[prop]?.type === 'Date') {
								children = <DateValue format='dd.MM.yyyy' />;
							}
							return (
								<Column key={prop} label={label} property={prop} width={width}>
									{children}
								</Column>
							);
						})}
						{this.relations.map(({ relation, property, computed }) => {
							const label = getLabelName(relation, this.modelName);
							return <Column relation={relation} property={property} key={relation + property} computed={computed} label={label} />;
						})}
					</Table>
				</div>
			</div>
		);
	}
}
