export default {
	translation: {
		formation: 'Формирование',
		total2: 'Всего',
		open: 'Открыть',
		reject: 'Отклонить',
		forestFireCard: 'Карточка лесного пожара',
		record: {
			saved: 'Запись сохранена',
			deleted: 'Запись удалена',
		},
		nameLat: 'Название на латинице',
		back: 'Назад',
		edited: 'Редактирование',
		generate: 'Сгенерировать',
		print: 'Печать',
		download: 'Скачать',
		ok: 'ок',
		return: 'Возврат',
		addMarker: 'Добавить маркер',
		isdmCode: 'Код в ИСДМ',
		departureDate: 'Дата выезда',
		returnDate: 'Дата возвращения',
		closestCityName: 'Ближайший НП',
		closestCityDistance: 'Растояние до ближайшего НП',
		shipped: 'Отгружено',
		ready: 'Готово',
		notReady: 'Не готово',
		lastEditDate: 'Последнее изменение',
		accessLevel: 'Уровень доступа',
		garrison: 'Гарнизон',
		newStage: 'Новый этап',
		fio: 'ФИО',
		statusReserve: 'Статус резерва',
		delivered: 'Доставлено',
		transportShip: 'Транспортный корабль',
		comment: 'Комментарий',
		showFires: 'Показать пожары',
		showCitiesBufferZone: 'Показать буферную зону городов',
		cancelEdit: 'Отменить изменения',
		fill: 'Заполнить',
		contacts: 'Контактные данные',
		dashboard: 'Сводка',
		showFireDepZones: 'Показывать ПЧ и их зоны ответственности',
		edit: 'Редактировать',
		recordsNotFound: 'Записи не найдены',
		plannedVolume: 'Запланированный объем',
		iApprove: 'Утверждаю',
		flights: 'Рейсы',
		zoom: 'Увеличение',
		fact: 'Факт',
		aboutObject: 'Об объекте',
		editAvatar: 'Редактировать аватар',
		addAvatar: 'Добавить аватар',
		upload: 'Загрузить',
		profile: 'Профиль',
		administration: 'Администрирование',
		checkAll: 'Отметить все',
		uncheckAll: 'Убрать все',
		addComment: 'Добавить комментарий',
		overall: 'Итого',
		editComment: 'Редактировать комментарий',
		population: 'Население',
		downloadReport: 'Скачать отчет',
		result: 'Результат',
		note: 'Примечание',
		extinguishingAgents: 'Огнетушащие вещества',
		division: 'Подразделение',
		risks: 'Риски',
		riskCategory: {
			title: 'Категория риска',
			plural: 'Категории риска',
		},
		peopleCount: 'Количество людей',
		teenagersCount: 'Количество детей',
		group: 'Группа',
		geo: 'Геометрия',
		pointGeo: 'Геометрия(точка)',
		id: 'Id',
		nearestAddress: 'Ближайший адрес',
		phoneNumber: 'Телефонный номер',
		fireNotifier: 'Пожарный извещатель',
		registries: 'Реестры',
		notSave: 'Не сохранять',
		historyLog: 'Журнал событий',
		agreement: 'Согласование',
		equipment: 'Оборудование',
		measure: 'Единица измерения',
		level: 'Уровень',
		price: 'Цена',
		byAddress: 'По адресам',
		byObjects: 'По объектам',
		quantity: 'Количество',
		fullName_full: 'Фамилия, имя, отчество',
		fullName: 'ФИО',
		position: 'Должность',
		attachFiles: 'Прикрепить файлы',
		deleteFile: 'Удалить файл',
		clean: 'Очистить',
		type: 'Тип',
		typeVehicle: 'Вид Техники',
		category: 'Категория',
		categories: 'Категории',
		number: '№',
		createdAt: 'Дата создания',
		selectedStatuses: 'Выбрано статусов',
		selectStatus: 'Выберите статус',
		selectType: 'Выберите тип',
		selectField: 'Выберите поле',
		location: 'Местонахождение',
		color: 'Цвет',
		accept: 'Подтвердить',
		active: 'Активен',
		cleanFilters: 'Очистить фильтры',
		_filter: 'Фильтр',
		week: 'Неделя',
		quarter: 'Квартал',
		year: 'Год',
		title: 'Оперативный мониторинг обстановки',
		name: 'Наименование',
		noName: 'Без названия',
		rank: 'Звание',
		shortName: 'Сокращенное наименование',
		section: 'Отдел',
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
		description: 'Описание',
		create: 'Создать',
		save: 'Сохранить',
		saveChanges: 'Сохранить изменения',
		backToList: 'Назад к списку',
		notSaved: 'не сохранено',
		longitude: 'Долгота',
		latitude: 'Широта',
		lonShort: 'д.',
		latShort: 'ш.',
		coordinates: 'Координаты',
		azimuth: 'Азимут',
		distance: 'Дистанция',
		metersPerSecond: 'м/с',
		loading: 'Загрузка...',
		showOnMap: 'Показывать на карте',
		addRecord: 'Добавить новую запись',
		editRecord: 'Изменить запись',
		copyRecord: 'Копировать запись',
		update: 'Обновить',
		send: 'Отправить',
		sending: 'Отправляется',
		export: 'Экспорт',
		import: 'Импорт',
		exportXLSX: 'Экспорт XLSX',
		confirm: 'Подтвердить?',
		confirmDelete: 'Удалить?',
		squareKm: 'кв.км',
		km: 'км',
		ga: 'га',
		all: 'Все',
		off: 'выкл.',
		date: 'Дата/Время',
		date_short: 'Дата',
		date1: 'Дата (от)',
		date2: 'Дата (до)',
		age1: 'Возраст (от)',
		age2: 'Возраст (до)',
		attachment: 'Прикрепленый файл',
		attachments: 'Прикрепленые файлов',
		default: 'По умолчанию',
		count: 'Количество',
		countShort: 'Кол-во',
		copy: 'Копирование',
		mainInfo: 'Основные данные',
		info: 'Информация',
		dateTime: 'Дата/время',
		dateFrom: 'от',
		at: 'в',
		downloadExcel: 'Скачать в Excel',
		no: 'нет',
		depTitle: 'Звание',
		opacity: 'Прозрачность',
		go: 'Перейти',
		reset: 'Сбросить',
		system112: 'Система 112',
		emergencyPlan: 'Планирование ЧС',
		statuses: 'Статусы',
		messageDate: 'Дата сообщения',
		createdRecord: 'Создать запись',
		recordNotFound: 'Запись не найдена',
		trunk: {
			title: 'Ствол',
			plural: 'Стволы',
		},

		metre: 'м',
		priority: 'Приоритет',
		requestVehicleType: {
			title: 'Вид транспорта',
			plural: 'Виды транспорта',
		},
		requestIncident: {
			searchPlaceholderMap: 'Поиск по названию инцидента, объекта',
			title: 'Инцидент',
			plural: 'Инциденты',
			riverLevel: 'Информация об уровне рек',
			objects: 'Информация об объектах предприятий',
			tracks: 'Треки',
		},
		requestIncidentObject: {
			title: 'Объект инцидента',
			plural: 'Объекты',
		},
		requestIncidentType: {
			title: 'Тип инцидента',
			plural: 'Типы инцидентов',
		},
		requestIncidentLevel: {
			title: 'Уровень инцидента',
			plural: 'Уровни инцидентов',
		},
		requestIncidentStatus: {
			title: 'Статус инцидента',
			plural: 'Статусы инцидентов',
		},
		enter: {
			title: 'Введите',
			ptp: 'Введите ПТП',
			ktp: 'Введите КТП',
			name: 'Введите название',
			addressName: 'Введите адрес',
			description: 'Введите краткую характеристику',
			objectCount: 'Введите количество объектов',
		},
		admin: {
			title: 'Администрирование',
			titleShort: 'Админ',
		},
		module: {
			fires: 'Лесные пожары',
			covid: 'COVID-19',
			appeals: 'Обращения граждан',
			errands: 'Поручения Главы РС(Я)',
			delivery: 'Северный завоз',
			emergency: 'Планирование по ЧС',
			techfires: 'Техногенные пожары',
			permafrost: 'Мерзлота',
		},

		day: {
			sunday: 'Воскресенье',
			monday: 'Понедельник',
			tuesday: 'Вторник',
			wednesday: 'Среда',
			thursday: 'Четверг',
			friday: 'Пятница',
			saturday: 'Суббота',
		},
		month: {
			january: 'Январь',
			february: 'Февраль',
			march: 'Март',
			april: 'Апрель',
			may: 'Май',
			june: 'Июнь',
			july: 'Июль',
			august: 'Август',
			september: 'Сентябрь',
			october: 'Октябрь',
			november: 'Ноябрь',
			december: 'Декабрь',
		},
		validation: {
			REQUIRED_FIELDS: 'Не заполнены обязательные поля',
			REQUIRED_CREWTYPE: "Не заполнено поле 'Тип команды'",
			REQUIRED_EXTINGUISHING_AGENTS_TYPE: "Не заполнено поле 'Средство тушения'",
			ALREADY_EXIST: 'Такая запись уже существует',
			INCORRECT_AIR: "Наверно заполнено поле 'Авиа'",
			INCORRECT_EVACUATED: "Наверно заполнено поле 'Вывезено'",
		},
		dictionaries: {
			search: 'Поиск справочника',
			title: 'Справочник',
			plural: 'Справочники',
			editMode: 'Режим редактирования',
			addGroup: 'Добавить группу',
			group: {
				title: 'Группа справочников',
				norm: 'Нормативно-справочная информация',
				fires: 'Справочники реестра пожаров',
				air: 'Справочники налета воздушных судов',
				weather: 'Справочники метеостанции',
				other: 'Прочие',
				region: 'Региональные справочники',
				layersData: 'Справочные данные слоев',
				reserves: 'Резервы',
			},
		},
		code: 'Код',
		codeForYasen: 'Код для модуля Ясень',
		externalCode: 'Код во внешней системе',
		weatherScaleValue: {
			title: 'Значение метеошкалы',
			plural: 'Значения метеошкал',
			startDate: 'Дата начала',
			endDate: 'Дата окончания',
			KPO1: 'КПО1',
			KPO2: 'КПО2',
			KPO3: 'КПО3',
			KPO4: 'КПО4',
			temperature: 'Температура',
			dewPoint: 'Точка росы',
			windSpeed: 'Скорость ветра',
			precipitation: 'Осадки',
			complexNumber: 'Комплексное число',
			KPPNesterov: 'КПП (Нестеров)',
			KPONesterov: 'КПО (Нестеров)',
		},
		forestUseType: {
			title: 'Вид использования лесов',
			plural: 'Виды использования лесов',
		},

		firePlanPreventionWork: {
			title: 'Работа по предовращению пожаров',
			plural: 'Работы по предовращению пожаров',
			volume: 'Объем работ',
			executionTerm: 'Срок исполнения',
			venue: 'Место проведения',
			responsiblePerson: 'Ответственное лицо',
		},
		firePlanPreventionWorkType: {
			code: {
				HYDRO: 'Работы по гидромелиорации',
				REGULATION:
					'Снижение природной пожарной опасности лесов путем регулирования породного состава лесных насаждений и проведения санитарно-оздоровительных мероприятий',
				MAINTENANCE:
					'Проведение профилактического контролируемого противопожарного выжигания хвороста, лесной подстилки, сухой травы и других лесных,горючих материалов',
				OTHER: 'Иные меры, определенные в соответствии с постановлением Правительства Российской Федерации от 16 апреля 2011 г. N 281',
			},
		},
		firePlanEvent: {
			plural: 'Мероприятия',
			duration: 'Срок проведения',
			responsiblePerson: 'Ответственное лицо',
			name: 'Вид мероприятия',
			organizations: 'Органы государственной власти, органы местного самоуправления, организации, привлекаемые к осуществлению мероприятий',
		},
		firePlanEventType: {
			code: {
				TENANT_CONTROL:
					'Мероприятия по контролю за осуществлением лицами, использующими леса, мер противопожарного обустройства лесов на лесных участках, предоставленных в постоянное (бессрочное) пользование, в аренду',
				OBSERVATION_CONTROL: 'Мероприятия по организации наблюдения и контроля за пожарной опасностью в лесах и лесными пожарами',
				SYSTEM_DEVELOPMENT: 'Мероприятия по организации системы обнаружения и учета лесных пожаров, системы наблюдения за их развитием',
				PREVENT_FOREST_FIRE: 'Мероприятия по недопущению распространения лесных пожаров на земли населенных пунктов, земли иных категорий',
				PREVENT_FIRE:
					'Мероприятия по недопущению распространения пожаров, возникших на землях населенных пунктов, землях сельскохозяйственного назначения и землях иных категорий',
			},
		},
		staffPosition: {
			title: 'Должность',
			plural: 'Должности',
		},
		paymentGroup: {
			title: 'Группа оплаты',
			plural: 'Группы оплаты',
		},
		firePlanResponsibleOrganization: {
			territory: 'Территория, на которой осуществляется мониторинг',
			responsiblePerson: 'Ответственное лицо',
			title: 'Организация, осуществляющий мониторинг пожарной опасности',
			patrollingPeriod: 'Период патрулирования, частота и время проведения',
			plural: 'Организации, осуществляющие мониторинг пожарной опасности',
			plural_short: 'Организации',
			patrollingType: 'Вид патрулирования (наземное, авиационное)',
			messageRecievingMethod: 'Способы приема сообщений и контактные данные',
			notifyMethod: 'Способы оповещения',
			description: 'Примечание',
			dutyPersons: 'ФИО ответственных дежурных',
			relatedOrganizations: 'Контактные данные организаций, с которыми  осуществляет взаимодействие',
			assistanceInformation: 'Вид содействия, его объем',
			positionPerson: 'Должность руководителя организации',
		},
		firePlanResponsibleOrganizationType: {
			code: {
				MONITORING: 'Оргиназации, осуществляющие мониторинг пожарной опасности в лесах и лесных пожаров',
				PATROLLING: 'Организации, ответственные за патрулирование лесов',
				MESSAGES: 'Организации, ответственные за прием и учет сообщений о лесных пожарах',
				NOTIFY: 'Организации, ответственные за оповещение населения и противопожарных служб о пожарной опасности в лесах и лесных пожарах',
				DISPATCH: 'Диспечерские службы',
				ASSISTANCE: 'Перечень органов государственной власти, органов местного самоуправления, организаций, оказывающих содействие в тушении лесных пожаров',
				ASSISTANCE_SUMMARY:
					'Перечень органов государственной власти, органов местного самоуправления, организаций, оказывающих содействие в тушении лесных пожаров (Общий)',
			},
		},
		firePlan: {
			forces: 'Силы и средства',
			title: 'План тушения',
			plural: 'Планы тушения',
			forestry: 'Лесничество',
			new: 'Новый план тушения',
			downloadForm: 'Скачать форму сводного плана',
			delete: 'Удалить план тушения',
			avgFireHazardClass: 'Средний класс пожарной опасности',
			forestCharacteristics: 'Общая характеристика лесов на территории лесничества (лесопарка)',
			extinguishingOrganizations: 'Информация об органах государственной власти, осуществляющих организацию тушения лесных пожаров',
			voluntaryOrganizations: 'Информация об организациях добровольной пожарной охраны',
			relevantOrganizations: 'Информация об организации и функционировании соответствующих комиссий, штабов, групп по тушению лесных пожаров',
			maneuveringScheme: 'Схема маневрирования лесопожарных формирований, пожарной техники и оборудования',

			summaryPlan: {
				title: 'Данные сводного плана',
				tab: {
					mainInfo: 'Титульный лист',
					responsiblePersonsMo: 'Ответсвенные за организацию тушения на терр-и МО (таблица 1.5)',
					readiness: 'Готовность субъекта (таблица 7)',
					forcesAndMeans: 'Cилы и средства (таблица 5.2)',
					supportingOrganizationsFighting: 'Перечень органов государственной власти (таблица 4)',
					measuresToCreateReserveEquipment: 'Меры по созданию резерва пожарной техники и оборудования (таблица 5.1)',
					measuresForProtectionLands: 'Меры по охране земель и земельных участков (таблица 2)',
				},
			},
			tab: {
				mainInfo: 'I. Основная информация',
				preventionWorks: 'II. Противопожарное обустройство лесов',
				monitoring: 'III.Организация мониторинга пожарной опасности',
				reserve: 'VI. Резервы',
				coordination: 'V. Мероприятия по координации работ',
				attractedForces: 'VII. Привлечение сил и средств',
				availableForces: 'IV. Лесопожарных формирования, пожарная техника и оборудование',
				firehazard: 'Пожарная опасность',
			},
		},
		firePlanForcesAndMeans: {
			title: 'Cилы и средства',
			code: 'Тип отчета (для сводного отчета)',
			organization: 'Наименование организации (органа)',
			municipality: 'Муниципальное образование',
			typeOfFormation: 'Вид формирования (ППО, АСФ)',
			numberOfFormations: 'Количество формирований',
			numberOfFireExtinguishingForcesPersons: 'Количество сил пожаротушения (чел.)',
			numberOfFireExtinguishingForcesGroups: 'Количество сил пожаротушения (групп.)',
			extinguishingLeaders: 'Руководители тушения  лесных пожаров (чел.)',
			pilots: 'Летчики-наблюдатели (чел.)',
			fireParatroopersPersons: 'Парашютисты-пожарные (чел.)',
			fireParatroopersGroups: 'Парашютисты-пожарные (групп.)',
			firefightersPersons: 'Десантники-пожарные (чел.)',
			firefightersGroups: 'Десантники-пожарные (групп.)',
			groundWorkersPersons: 'Работники наземных служб (чел.)',
			groundWorkersGroups: 'Работники наземных служб (групп.)',
			airFireInstructorsPersons: 'Инструкторы авиапожарных служб (чел.)',
			airFireInstructorsGroups: 'Инструкторы авиапожарных служб (групп.)',
			permanentGroundWorkersPersons: 'Постоянные работники наземных служб (чел.)',
			permanentGroundWorkersGroups: 'Постоянные работники наземных служб (групп.)',
			temporaryGroundWorkersPersons: 'Временные работники наземных служб (чел.)',
			temporaryGroundWorkersGroups: 'Временные работники наземных служб (групп.)',
			forestFireTrucks: 'Лесопожарные автоцистерны (лесопожарные машины)',
			forestFireTractors: 'Тракторы лесопожарные',
			bulldozers: 'Бульдозеры',
			forestPlows: 'Плуги лесные',
			trailers: 'Трейлеры',
			motorPumps: 'Мотопомпы',
			chainsaws: 'Бензопилы',
			blowers: 'Воздуходувки',
			unmannedAerialVehicles: 'Беспилотные летательные аппараты (комплексы)',
			incendiaryDevices: 'Зажигательные аппараты',
			helicopterSpillways: 'Вертолетные водосливные устройства',
			aviationFireTanks: 'Авиационные пожарные емкости',
			caterpillarTractor: 'Трактор гусеничный',
			wheeledTractor: 'Трактор колесный',
			fireTrucksCar: 'Пожарные автоцистерны (машины)',
			excavators: 'Экскаваторы',
			allTerrainVehicles: 'Вездеходы',
			trucks: 'Грузовые машины',
			busesShifts: 'Автобусы, вахтовки',
			cars: 'Легковые машины',
			trawls: 'Тралы',
			fireTanks: 'Пожарные емкости',
			backpackForestFireExtinguishers: 'Ранцевые лесные огнетушители',
			shovels: 'Лопаты',
			axesHoes: 'Топоры-мотыги',
			YKVRadioStations: 'Радиостанции УКВ-диапазона',
			KVRadioStations: 'Радиостанции КВ-диапазона',
			helicopters: 'Вертолеты',
			aircraft: 'Самолеты',
			specializedTrackedVehicles: 'Специализированная гусеничная техника',
			forestFireBoatsAndMotorBoats: 'Лесопожарные катера, моторные лодки',
		},
		firePlanAttractedForce: {
			name: 'Наименование привлекаемой силы или средства',
			fireHazardLevel: 'Уровень пожарной опасности',
			description: 'Примечание',
			plural:
				'Привлечение сил и средств подразделений пожарной охраны и аварийно-спасательных формирований, иных сил и средств в зависимости от уровня пожарной опасности',
		},
		firePlanFormation: {
			title: 'Лесопожарные формирования, на которые возложено тушение',
			peopleCount: 'Количество необходимых сотрудников',
			equipmentCount: 'Количество необходимого снаряжения и инвентаря',
			vehicleCount: 'Количество необходимых пожарной техники и оборудования',
			description: 'Примечание',
			name: 'Наименование формирования',
			location: 'Местонахождение, контактные данные',
			licence: 'Реквизиты лицензии на тушение ',
		},
		measuresForProtectionLands: {
			opashka: 'Опашка, м',
			preventiveBurning: 'Профилактические выжигания, га',
			firebreaks: 'Противопожарные разрывы*, км (Минполосы)',
			cleaningClutter: 'Уборка захламленности, га',
			person: 'Ответственные лица за выполнение мероприятий',
		},

		forestFireMovement: {
			extinguishingParticipants: 'Участвовали в тушении',
			returnMessage: 'После подтверждения карточка будет закрыта и недоступна для редактирования',
			return: 'Возврат техники и людей',
			edit: 'Редактировать',
			cancelEdit: 'Закончить редактирование',
			notAvailableForces: 'На тушении лесных пожарах',
		},
		firePlanNecessaryFormation: {
			title: 'Необходимые лесопожарные формирования для тушения лесных пожаров в зависимости от уровня пожарной опасности',
			fireHazardLevel: 'Уровень пожарной опасности',
			count: 'Количество необходимых лесопожарных формирований',
			composition: 'Состав лесопожарного формирования',
			peopleCount: 'Количество необходимых сотрудников',
			equipmentCount: 'Количество необходимого снаряжения и инвентаря',
			vehicleCount: 'Количество необходимых пожарной техники и оборудования',
			description: 'Примечание',
			organization: 'Наименование организации',
			events: 'Мероприятия',
			responsiblePersons: 'Фамилия, имя, отчество ответственного лица',
			positionPersons: 'Должность ответственного лица',
		},
		firePlanAttractedForceProvision: {
			count: 'Количество, единица измерения',
			name: 'Вид обеспечения',
			description: 'Примечание',
			plural:
				'Обеспечение сил и средств, привлекаемых для тушения лесных пожаров, водой, продовольствием, медицинским обслуживанием, картографическим материалом, иными необходимыми материалами и средствами',
		},
		report: {
			exportAsExcel: 'Экспорт страница в Excel',
			downloadSummaryFirePlan: 'Скачать Сводный план тушения',
			search: 'Поиск отчета',
			downloadDate: 'Дата загрузки отчета',
			title: 'Отчет',
			plural: 'Отчеты',
			group: 'Группа отчетов',
			type: 'Вид отчета',
			download: 'Скачать отчет',
			exportAsPDF: 'Экспорт страницы в PDF',
			downloadLO1: 'Скачать Форму 1-ЛО.Раздел 1',
			section: 'Раздел',
			date1: 'Дата (от)',
			date2: 'Дата (до)',
			inProgress: 'В разработке...',
			name: 'Название отчета',
			summary: 'Суммарный',
			detailed: 'Детальный',
			first: 'Часть 1',
			last: 'Часть 2',
			FiresOnCountyAndDate: 'Сведения о лесных пожарах по районам на дату',
			FireCard: 'Карточка лесного пожара',
			ExtinguishedFires: 'Ликвидированные пожары',
			FiresOnDate: 'Сведения о лесных пожарах на дату',
			CumulativeFires: 'Сведения о лесных пожарах нарастающим итогом',
			DetailedFireSituation: 'Подробная информация о лесопожарной ситуации и принимаемых мерах по тушению лесных пожаров',
			FireAct: 'Акт о пожаре',
			FireRegistryOnDate: 'Реестр лесных и ландшафтных (природных) пожаров на дату',
			FireByArea: 'Общая площадь и количество лесных пожаров',
			FireByMonitoringZone: 'Лесные пожары по зонам мониторинга',
			FireByCauseAndWorker: 'Сведения о причинах лесных пожарах, работающих людях и технике',
			LO1Section1: 'Форма 1-ЛО. Раздел 1. Сведения о пожарах в лесах',
			LO1Section2: 'Форма 1-ЛО. Раздел 2. Пожарная опасность и назначение режимов',
			LO1Section3: 'Форма 1-ЛО. Раздел 3. Показатели работы воздушных средств',
			LO1Section4: 'Форма 1-ЛО. Раздел 4. Наличие сил и средств пожаротушения',
			LO2: 'Форма 2-ЛО Противопожарные мероприятия и привлечение к ответственности за лесные пожары',
			LO3: 'Форма 3-ЛО Ежегодная форма сведений о работе в пожароопасном сезоне',
			DetectionMethods: 'Распределение пожаров по способам обнаружения и площади обнаружения. Распределение пожаров по способам и площади обнаружения (период)',
			ExtinguishingCosts: 'Информация о затратах на тушение в разрезе каждого лесного пожара, а также о причиненном ими ущербе',
			ExtinguishingVehiclesAndPeoples: 'Привлекаемые силы и средства для тушения лесных пожаров',
			CityThreat: 'Угрозы лесных пожаров населенным пунктам. Угроза населенным пунктам',
			FireAreaCount: 'Сведения по количеству и площадям лесных пожаров',
			RequestLog: 'Журнал обращений граждан о нарушениях в лесах',
			ForestryHarm: 'Сведения о вреде, причиненном лесному хозяйству вследствие возникновения лесных пожаров. Форма 7-ОИП ч.2',
			PeoplesAndVehiclesByDate: 'Распределение показателей пожароопасного периода. Распределение людей и техники по датам пожароопасного сезона (период)',
			AviaDepByCause: 'Сведения о причинах возникновения пожаров по авиаотделениям на дату. Форма 05 авиа',
			BigForestFire: 'Отчет о ликвидированных крупных лесных пожарах',
			Avia1: 'Общие сведения о площади лесов в субъектах рф с зонами лесоавиационных работ. Форма 01 авиа',
			Avia2_2: 'Сведения о работе воздушных судов по охране лесов и оленьих пастбищ. Форма 02-2 авиа',
			Avia2_1: 'Форма 02-1 авиа',
			Avia4: 'Сведения о возникших пожарах, обнаруженных и потушенных с применением авиации и самостоятельно силами пдпс. Форма 04 авиа',
			Avia7: 'Сведения о распределении лесных и тундровых пожаров, обнаруженных авиацией. Форма 07 авиа',
			Avia15: 'Работа в зоне контроля. Форма 15 авиа',
			Avia15_1: 'Информация о принятии решений  КЧС и ПБ по тушению лесных пожаров в зоне контроля. Форма 15-1 авиа (о пожарах в зоне контроля)',
			FireDangerDiary: 'Дневник пожарной опасности',
			InformationAboutIdentifyingPerpetrators: 'Информация о выявлении виновников лесных пожаров и привлечении к ответственности лиц',
			FlightTimeControl:
				'Контроль объёмов лётного времени и финансовых средств по заключённым государственным контрактам на производство лесоавиационных работ',
			InformationAboutActiveForestFires: 'Сведения о действующих лесных пожарах',
			AirRaidExtinguishForestFires: 'Налёт ВС на тушение лесных пожаров',
			RegisterEliminatedForestFires: 'Реестр ликвидированных лесных пожаров (ХМАО)',
			ReportActiveForestFires: 'Отчет о действующих лесных пожарах',
			DepartmentWorkRecordCard: 'Карточка учета работы авиаотделения',
			OperationalSituationInTerritory: 'Оперативная обстановка на территории Республика Саха (Якутия). Приложение оперативная информация по лесным пожарам',
			KpoMonthlyReport: 'Отчет КПО за месяц',
			ReportOnWorkWithCitizens: 'Отчет о работе с обращениями граждан о нарушениях в лесах',
			CumulativeSummaryForestFires: 'Сводка о лесных пожарах нарастающим итогом',
			ReportOnForestFiresInTheTerritory: 'Донесение о лесных пожарах на территории',
			CumulativeIndicators: 'Показатели нарастающим итогом',
			InformationAboutLargeForestFires: 'Информация о крупных лесных пожарах',
			InformationAboutLargeForestFiresForDate: 'Информация о крупных лесных пожарах по районам и лесничествам',
			InformationOnTheOperationOfAircraftForMonitoring: 'Информация о работе воздушных судов по мониторингу и тушению лесных пожаров по состоянию на дату',
			OperationalSituationOnTheTerritoryOfTheSubject: 'Оперативная обстановка на территории Республика Саха (Якутия)',
			InformationAboutActiveForestFiresForDate: 'Сведения о действующих лесных пожарах (силы детально)',
			InformationAboutForestFiresForDate: 'Справка о лесных пожарах за дату',
			FireDangerIndicators: 'Показатели пожарной опасности',
			ListOfFlightStaff: 'Список летного состава за год. Форма 14 авиа',
			SummaryFirePlan: 'Сводный план тушения',
			DistributionFiresByLiquidationAreaAndExtinguishingTime: 'Распределение пожаров по площади ликвидации и времени тушения',
			InformationAboutWorkOfForestFireFormations: 'Сведения о работе лесопожарных формирований за период',
			JobDetailsAircraftForTheProtectionOfForests:
				'Сведения о работе воздушных судов и подразделений министерства по делам го и чс, и министерства обороны по охране лесов и оленьих пастбищ. Форма 13 авиа',
			ListOfForestFires: 'Ведомость лесных пожаров',
			EliminatedFiresFoundInTheTotalArea: 'Ликвидированные пожары, обнаруженные на общей площади более 5 га',
			ReportOnTheDetectionAndSuppressionForestFires: 'Отчет об обнаружении и тушении лесных пожаров',
			InformationOnTheDistributionOfTheFireSeasonByClass: 'Сведения о распределении пожароопасного сезона по классам пожарной опасности в лесу. Форма 06 авиа',
			InformationAboutTheEliminationOfForestFire: 'Сведения о ликвидации лесного пожара',
			InformationAboutForestFiresTakingIntoAccountPurposeForests:
				'Сведения о возникновении лесных пожаров и их тушении с учетом целевого назначения лесов. Форма 7-ОИП ч.1: Сведения о возникновении лесных пожаров',
			KeyIndicatorsForAviationProtectionOfForests: 'Основные показатели по авиационной охране лесов. Авиамониторинг без активных сил и средств',
			InformationAboutTheWorkOfGroundFireDepartments: 'Сведения о работе наземных лесопожарных подразделений. Форма 03 авиа',
			DistributionOfIndicatorsByDatesOfTheFireHazardPeriod: 'Распределение показателей по датам пожароопасного периода',
			NumberOfFiresForTheFireSeason: 'Количество пожаров',
			TotalFireAreaFireHazardPeriod: 'Общая площадь пожаров',
			ForestAndNonForestAreasFires: 'Лесная и нелесная площади пожаров',
			KppForTheFireHazardPeriod: 'КПП за пожароопасный период',
			KpoForTheFireHazardPeriod: 'КПО за пожароопасный период',
			DistributionOfIndicatorsByDepartments: 'Распределение показателей по подразделениям',
			NumberOfFiresByDepartments: 'Количество пожаров по подразделениям',
			TotalAreaByDepartments: 'Общая площадь по подразделениям',
			ForestAndNonForestAreaByDepartments: 'Лесная и нелесная площадь по подразделениям',
			KpoByDepartments: 'КПО по подразделениям',
			KppByDepartments: 'КПП по подразделениям',
			DistributionOfTheNumberOfFiresByTheirCauses: 'Распределение числа пожаров по причинам их возникновения',
			FireSituationAnalysis: 'Анализ пожарной обстановки',
			InformationAboutTheWorkParachuteAndAirborneFireService:
				'Cведения о работе парашютной и десантно-пожарной службы по тушению лесных и тундровых пожаров. Форма 08 авиа',
			InformationAboutForestFiresAccordingClassificationOfForestFundLands:
				'Сведения о лесных пожарах по классификации земель лесного фонда по целевому назначению лесов и видам пожаров. Формы 6-7-ОИП: Сведения о лесных пожарах (период)',
			pivotTableForm: 'Форма сводная таблица',
			riverInformationForm: 'Форма справки по рекам',
			informationFormForDistricts: 'Форма информации по районам',
			formForTheHeadAndGovernment: 'Форма для Главы и Правительства',
			formByUlus: 'Форма по улусам',
			depositnew: 'Форма по депонации',
			dailyInformationFormFor: 'Форма ежедневной информации',
			arcticUlusForm: 'Форма по арктическим улусам',
			districtsEnterprisesRivers: 'Форма информации по районам',
			indicators: 'Показатели за год',
			ratioTo: 'Соотношение одного года к другому',
			forReference: 'Для справки',
			codeForAnalysis: 'СВОД для анализа',
			rivers: 'Реки',
			year: 'За год',
			yana: 'Яна',
			stsgRivers: 'СЦГ_реки',
			oil: 'Нефть',
			terCustomers: 'ТЭР Заказчики',
		},
		firePlanLandingPads: {
			title: 'Посадочные площадки для самолетов и вертолетов',
			typesAircraft: 'Типы воздушны судов',
			presenceGSM: 'Наличие пунктов заправки авиа ГСМ',
		},
		forceAvailability: {
			title: 'Готовность сил и средств',
			plural: 'Готовность сил и средств',
			newDate: 'Дата новой записи',
			copyWarning: 'Копируются только сохраненные данные',
		},
		auth: {
			welcome: 'Добро пожаловать',
			title: 'Авторизация',
			login: 'Войти',
			order: 'Получить доступ',
			insertLogin: 'Введите логин',
			insertPassword: 'Введите пароль',
			register: 'Зарегистрироваться',
			logout: 'Выйти',
			forgotPassword: 'Забыли пароль?',
			recover: 'Восстановить',
			required: 'Необходимо заполнить поле',
			wait: 'Подождите',
			seconds: 'секунд',
			easy: 'Легко',
			fast: 'Быстро',
			safely: 'Надежно',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электронная почта',
			input: 'Введите E-mail',
			exist: 'E-mail уже занят',
		},
		password: {
			title: 'Пароль',
			hint: 'Пароль...',
			input: 'Введите пароль',
			new: 'Введите новый пароль',
			repeat: 'Повторите пароль',
			newHint: 'Оставьте поля пустыми, чтобы не менять пароль',
			empty: 'Введите пароль в оба поля',
			noMatch: 'Пароли не совпадают!',
			match: 'Пароли совпадают',
		},
		person: {
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			fullName: 'Ф.И.О.',
			status: 'Статус',
			section: 'Отдел',
			gasMask: 'Тип СИЗОД',
			phone: 'Телефон',
			address: 'Адрес',
			fio: 'Пользователь',
		},
		user: {
			title: 'Пользователь',
			plural: 'Пользователи',
			position: 'Должность',
			lastAuthDate: 'Дата последней авторизации',
			create: 'Новый пользователь',
			profile: 'Профиль',
			email: 'E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал задачу',
			telegram: 'Telegram ID',
			username: 'Логин',
			password: 'Пароль',
			avatar: 'Аватар',
			fullName: 'ФИО',
			organizationName: 'Организация',
			permafrostOrganizationName: 'Организация',
			searchFioPositionOrganization: 'Поиск по ФИО, должности, организиации',
			canAccess: 'Пользователю будут доступны',
			allData: 'все данные',
			limitRegion: 'данные по региону:',
			limitCounty: 'данные по району:',
			limitCity: 'данные по городу:',
			countyName: 'Район',
			cityName: 'Город/нас.пункт',
			error: {
				email: {
					custom: {
						email: 'Неверный e-mail',
					},
					uniqueness: 'Пользователь с этим e-mail уже существует',
					verifySent: 'На e-mail отправлено письмо для подтвержения',
				},
			},
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			create: 'Новая роль',
			created: 'Созданo',
			modified: 'Изменено',
			name: 'Код',
			description: 'Название',
			isSaving: 'Права сохраняются...',
		},
		acl: {
			search: 'Поиск по названию или описанию модели',
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: {
				title: 'Модель',
				user: 'Пользователи',
				project: 'Проекты',
				person: 'Персоны',
				description: 'Описание',
			},
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
			allowAll: 'Разрешить все',
			list: 'Список прав',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенния ролей',
			create: 'Новое назначение роли',
		},
		comments: {
			title: 'Комментарий',
			plural: 'Комментарии',
			placeholder: 'Оставьте комментарий',
			submit: 'Отправить',
		},
		label: {
			title: 'Метка',
			plural: 'Метки',
			create: 'Новая метка',
		},
		file: {
			title: 'Файл',
			plural: 'Файлы',
			uploadFile: 'Загрузить файл',
			uploadImage: 'Загрузить изображение',
			uploadStart: 'начинаю загрузку',
			uploading: 'загрузка файла...',
			uploadComplete: 'загрузка завершена',
			notSelected: 'файл не выбран',
			willBeUploaded: 'Будет загружен',
			wrongType: 'Неверный тип файла',
			uploadError: 'Ошибка при загрузке файла',
			fileTooBig: 'Файл слишком большой',
		},
		organization: {
			title: 'Организация',
			plural: 'Организации',
			name: 'Название организации',
			fullName: 'Полное название организации',
			shortName: 'Краткое наименование',
			ownershipForm: 'Форма собственности',
			address: 'Юридический адрес',
			headName: 'ФИО Руководителя',
			headPosition: 'Должность руководителя',
			inn: 'ИНН',
			ogrn: 'ОГРН',
			kpp: 'КПП',
			comment: 'Комментарий',
			externalId: 'ID организации во внешней системе',
			externalData: 'Содержимое организации из внешней системы',
			position: 'Должность',
			description: 'Информация об организации',
			info: 'Основные сведения',
			factAddress: 'Фактический адрес',
			secondaryInfo: 'Дополнительные сведения',
			headInfo: 'Данные о руководителе',
			okato: 'ОКАТО',
			oktmo: 'ОКТМО',
			okved: 'ОКВЭД',
			okpo: 'ОКПО',
			noStaff: 'Сотрудники не найдены',
			noRequests: 'Заявки не найдены',
			noBankAccounts: 'Банковские счета не найдены',
			contractor: 'Подрядчик',
			activityType: 'Вид деятельности',
		},
		organizationType: {
			title: 'Тип организации',
			plural: 'Типы организации',
		},
		appeal: {
			title: 'Обращение гражданина',
			plural: 'Обращения граждан',
			kanban: 'Реестр',
			date: 'Дата обращения',
			endDate: 'Отработано',
			status: 'Статус обращения',
			system: 'Система-источник',
		},
		appealStatus: {
			title: 'Состояние обращения',
			plural: 'Состояния обращений',
			default: 'По умолчанию',
			closing: 'Закрывающее',
		},
		appealSystem: {
			title: 'Внешняя система',
			plural: 'Внешние системы',
		},
		appealCategory: {
			title: 'Категория обращений',
			plural: 'Категории обращений',
		},
		period: {
			title: 'Период',
			start: 'Начало периода',
			end: 'Конец периода',
			select: 'Период',
			day: 'День',
			week: 'Неделя',
			month: 'Месяц',
			quarter: 'Квартал',
			year: 'Год',
			everyday: 'Каждый день',
			everyweek: 'Каждую неделю',
			everymonth: 'Каждый месяц',
			lastDayOfMonth: 'Последний день месяца',
			dayOfMonth: 'числа каждого месяца`',
		},
		stats: {
			title: 'Статистика',
			catAvg: 'Средняя продолжительность обработки обращений по категориям',
			orgAvg: 'Средняя продолжительность обработки обращений по организациям',
			orgCount: 'Количество обращений по организациям',
			sources: 'Обращения по источникам',
			heatmap: 'Тепловая карта',
		},
		audit: {
			title: 'Журнал',
			model: 'Модель',
			action: 'Действие',
			create: 'Создание',
			delete: 'Удаление',
			update: 'Обновление',
			select: 'Выберите запись',
			data: 'Данные записи',
			owner: 'Пользователь',
			hidden: '*скрыто*',
		},
		heatPoint: {
			title: 'Термоточка',
			plural: 'Данные ДЗЗ',
			newPlural: 'Данные ДЗЗ (новая версия)',
			source: 'Спутник',
			period: 'Период',
			periodHint: 'Максимальный период ограничен одним годом. При его превышении даты будут скорректированы.',
			reload: 'Загрузить',
			brightness: 'Яркость (в кельвинах)',
			dif: 'dif',
			eurasia: 'Евразия',
			today: 'сегодня',
			yesterday: 'вчера',
			earlier: 'ранее',
			dayNight: 'Day/Night',
			frp: 'FRP',
			scan: 'Scan',
			track: 'Track',
			createFire: 'Добавить пожар',
		},
		adpiDeviceType: {
			title: 'Тип АДПИ',
			plural: 'Типы АДПИ',
		},
		pump: {
			title: 'Насос',
			plural: 'Насосы',
			capacity: 'Производительность',
		},
		foam: {
			title: 'Пена',
			plural: 'Пены',
		},
		techFireFoam: {
			title: 'Огнетушащее вещество',
			plural: 'Огнетушащие вещества',
		},
		cisternBulk: {
			title: 'Вместимость цистерн',
			plural: 'Вместимость цистерн',
			volume: 'Вместимость',
		},
		personnelStatus: {
			title: 'Статус личного состава',
			plural: 'Статусы личного состава',
		},
		personnel: {
			title: 'Личный состав',
			add: 'Добавить личный состав',
		},
		adpiDeviceStatus: {
			title: 'Статус АДПИ',
			plural: 'Статусы АДПИ',
		},
		adpiDeviceModel: {
			title: 'Модель АДПИ',
			plural: 'Модели АДПИ',
		},
		fireAlarmAdpiModel: {
			plural: 'Модели АДПИ',
		},
		fireAlarmOrganizationAdpiService: {
			plural: 'Организации, обсулижвающие АДПИ',
			title: 'Организации',
		},
		fireDepPowderCapacity: {
			plural: 'Порошок, объем',
			title: 'Порошок, объем',
			capacity: 'Объем',
		},
		fire: {
			lastEditDate: 'Дата последнего редактирования',
			detectArea: 'Площадь в момент обнаружения, га',
			localizedArea: 'Площадь в момент локализации, га',
			notFound: 'Пожар с таким id не найден',
			noAirRaids: 'Налетов по тушению пожара не совершались',
			popup: {
				mainInfo: 'Основные данные',
				vpp: 'Данные о ВПП',
				activity: 'Задействовано в тушении',
			},
			allotmentNumber: 'Выдел',
			fireId: '№ по реестру ОМ-Пожары',
			delete: 'Удалить пожар',
			fieldsSelected: 'Выбрано полей',
			fireNumber: '№ пожара',
			fireAct: 'Акт о пожаре',
			distances: 'Расстояния',
			numbers: 'Нумерация',
			meteo: 'Метеоинформация',
			extinguishingCosts: 'Затраты на тушение',
			forestFireAct: 'Акт о лесном пожаре',
			forestryDamage: 'Затраты и ущерб, причиненные лесному хозяйству',
			cityAndAirfield: 'Город и аэропорт',
			dynamicDate: 'Время',
			new: 'Новый пожар',
			name: 'Наименование',
			shortName: 'Сокращенное наименоваие',
			status: 'Состояние пожара',
			title: 'Лесной пожар',
			plural: 'Лесные пожары',
			short: 'Пожар',
			registry: {
				title: 'Реестр',
			},
			map: 'Карта',
			add: 'Добавить',
			addForm: 'Добавление пожара',
			mainInfo: 'Основные данные',
			resources: 'Силы и средства',
			secondaryInfo: 'Дополнительная информация',
			jumpings: 'Прыжки',
			descents: 'Спуски',
			delivered: 'Грузов доставлено, тонн',
			deliveredAvia: 'В том числе авиа, тонн',
			threat: 'Угроза населенному пункту',
			fireManager: 'Руководитель тушения пожара',
			number: '№',
			numberLong: 'Номер пожара',
			created: 'Создана карточка пожара',
			geo: 'Геометрия',
			coordinates: 'Координаты пожара',
			coordsAndDistances: 'Координаты и расстояния',
			cityAzimuth: 'Азимут от н/п',
			cityDistance: 'Удаление от н/п',
			airfieldAzimuth: 'Азимут от аэропорта',
			airfieldDistance: 'Расстояние до места высадки',
			aviaDepAzimuth: 'Азимут от а/о',
			aviaDepDistance: 'Удаление от а/о',
			transportRoutesDistance: 'Расстояние до транспортных путей',
			fromMap: 'Рисовать на карте',
			time: 'Время',
			detectDate: 'Дата обнаружения',
			reportDate: 'Дата сообщения',
			startDate: 'Дата начала тушения',
			extinguishedDate: 'Дата/время тушения',
			localizationDate: 'Дата/время локализации',
			dynamic: 'Динамика',
			createDynamic: 'Создание новой динамики',
			edge: 'Кромка, км',
			edgeShort: 'Кромка',
			protectiveArea: 'Защитная площадь, га',
			protectiveAreaShort: 'Защитная',
			operationalArea: 'Эксплуатационная площадь, га',
			operationalAreaShort: 'Эксплуатационная',
			reserveArea: 'Резервная площадь, га',
			reserveAreaShort: 'Резервная',
			soilArea: 'Почвенная площадь, га',
			soilAreaShort: 'Почвенная',
			upperArea: 'Верховая площадь, га',
			upperAreaShort: 'Верховая',
			lowerArea: 'Низовая площадь, га',
			lowerAreaShort: 'Низовая',
			forestArea: 'Лесная площадь, га',
			forestAreaShort: 'Лесная',
			nonForestArea: 'Нелесная площадь, га',
			nonForestAreaShort: 'Нелесная',
			coverArea: 'Покрытая площадь, га',
			coverAreaShort: 'Покрытая',
			nonCoverArea: 'Непокрытая площадь, га',
			nonCoverAreaShort: 'Непокрытая',
			activeBurningArea: 'Зона активного горения, га',
			activeBurningAreaShort: 'Активн. гор.',
			description: 'Примечание',
			startDrawing: 'Рисовать',
			finishDrawing: 'Закончить',
			showCounties: 'Показывать районы',
			showForestryQuarters: 'Показывать квартальную сеть',
			area: 'Площадь, га',
			areaKm: 'Площадь, кв.км',
			areaShort: 'Общая',
			areaTitle: 'Площадь пожара, га',
			leasedArea: 'Площадь на арендуемых лесных участках, га',
			geoChanged: 'Изменена геометрия пожара',
			forestOwnership: 'Принадлежность лесов',
			combustionIntensity: 'Интенсивность горения',
			combustionPattern: 'Характер лесных насаждения',
			combustionType: 'Вид пожара',
			combustionCharacteristic: 'Характеристика горения',
			decisionDetailsOpb: 'Реквизиты решения КЧС и ОПБ РС(Я)',
			date: 'Дата',
			protocolNumber: 'Номер протокола',
			forestryPrecinct: 'Участковое лесничество',
			cause: 'Причина пожара',
			zone: 'Зона мониторинга',
			forestryFireNumber: '№ пожара по лесничеству',
			aviaDepFireNumber: '№ пожара по авиаотделению',
			bigFireNumber: '№ крупного пожара',
			isdmFireNumber: '№ в ИСДМ',
			isdmSync: 'Синхронизация с ИСДМ',
			notLandingReason: 'Причина непринятия мер',
			notInspectionReason: 'Причина неосмотра',
			windSpeed: 'Скорость ветра',
			extinguishingCostsSubject: 'Субъекта',
			rentBC: 'В т.ч. аренда ВС',
			totalCost: 'Общий ущерб',
			actDate: 'Дата акта',
			actPercent: '% акта',
			compiler: 'Составитель',
			woodLoss: 'Потери древесины на корню',
			youngWoodLoss: 'Погибло молодняков',
			burnedWood: 'Сгорело и повреждено лесной продукции',
			burnedHarvestedWood: 'В т.ч. заготовленной древесины',
			KPOdetected: 'КПО обнаружения',
			KPOliquidation: 'КПО ликвидации',
			frontalPrecipitation: 'Фронтальные осадки',
			origin: 'Координаты и расстояния',
			pickPoint: 'Указать на карте',
			pickPointHint: 'Указать точку курсором на карте',
			lastDynamic: 'Дата/время последней динамики',
			updateMainInfo: 'Автозаполнение',
			updateMainInfoHint: 'Автоматически заполнить некоторые поля на основе точки возникновения или геометрии пожара',
			isdmFireNumberManual: '№ в ИДСМ (ручнной ввод)',
		},
		dynamic: {
			popup: {
				confirm: 'Подтвердить',
				message: 'Внесенные в редактируемую динамику данные будут утеряны',
			},
		},
		notifyRecipient: {
			title: 'Получатель рассылки',
			plural: 'Получатели рассылки',
			group_filter: 'Фильтр получателей по группе',
			group: 'Группа получателей',
			name: 'Имя',
			email: 'Email',
		},
		notifyRecipientGroup: {
			title: 'Группа получателей рассылки',
			plural: 'Группы получателей рассылки',
			showAll: 'Показывать всех',
		},
		transportData: {
			title: 'Транспортный модуль',
			period: 'Период',
			schedule: 'Установленное расписание',
			report: 'Отчет',
			nextDate: 'Дата следующей отправки',
			disabled: 'Рассылка неактивна',
			active: 'Активность',
			minute: 'Мин',
			hour: 'Час',
			day: 'День месяца',
			dayOfWeek: 'День недели',
			subject: 'Заголовок письма',
			text: 'Текст письма',
		},
		notLandingReason: {
			title: 'Причина невысадки',
			plural: 'Причины невысадки',
		},
		notInspectionReason: {
			title: 'Причина неосмотра',
			plural: 'Причины	 неосмотра',
		},
		aviaDep: {
			title: 'Авиаотделение',
			plural: 'Авиаотделения',
			active: 'Активность',
			location: 'Расположение',
			area: 'Осматриваемая площадь',
		},
		forestry: {
			title: 'Лесничество',
			plural: 'Лесничества',
			forestOwnership: 'Принадлежность леса',
			all: 'Все лесничества',
		},
		landCategory: {
			title: 'Категория земли',
			plural: 'Категории земель',
		},
		fireAnalyticalIndicator: {
			title: 'Аналитический показатель пожаров',
			plural: 'Аналитические показатели пожаров',
		},
		tenant: {
			title: 'Арендатор',
			plural: 'Арендаторы',
		},
		emergencyPerson: {
			fullName: 'ФИО',
			title: 'Ответственное лицо',
			plural: 'Ответственные лица',
			cities: 'Города',
		},
		emergencyOrganization: {
			title: 'Ответственная организация',
			plural: 'Ответственные организации',
			responsibility: 'Стоящие задачи по организации',
			cities: 'Города',
			isAllRisks: 'Все категории рисков',
			riskCategories: 'Категории рисков',
		},
		county: {
			my: 'Мой район',
			title: 'Район',
			plural: 'Районы',
			name: 'Название',
			population: 'Население',
			oktmo: 'ОКТМО',
		},
		municipality: {
			attachExplanatoryNoteFiles: 'Прикрепить файлы пояснительной записки',
			explanatoryNote: 'Краткие выводы из оценки ситуации',
			title: 'Муниципальное образование',
			plural: 'Муниципальные образования',
			name: 'Наименование',
			citiesCount: 'Населеных пунктов',
			population: 'Население',
			area: 'Общая площадь, кв.м',
			status: 'Статус',
		},
		city: {
			forcesAndResources: 'Силы и средства',
			my: 'Мой город',
			title: 'Населенный пункт',
			plural: 'Населенные пункты',
			pluralShort: 'Нас. пункты',
			add: 'Добавить населенный пункт',
			okato: 'Код ОКАТО',
			fireDanger: 'Угроза населенному пункту',
			population: 'Население (общее)',
			area: 'Площадь, кв.м',
			participatingEnterprises: 'Перечень предприятий, принимающих участие в подготовке к ЧС',
			importantObjects: 'Перечень важных по ЧС объектов',
			county: 'Район',
			oktmo: 'Код ОКТМО',
			sufficiencyStatus: 'Статус достаточности',
			municipality: 'Муниципальное образование',
			nomenclatures: 'Номенклатура',
			childrenPopulation: 'Население (дети)',
			mainData: 'Основные данные',
			planFile: 'Файл плана ЧС',
			planExplanation: 'Пояснение к плану ЧС',
		},
		operationalManagementWorkControl: {
			responsibleOrganization: 'Привлеченные силы',
			arrivalDate: 'Время прибытия',
			departureDate: 'Время убытия',
			peopleNumber: 'Личный состав',
			vehiclesNumber: 'Техника',
			noAvailableOrganizations: 'Нет доступных привлекаемых сил',
		},
		operationalManagementObject: {
			title: 'Объект ОУ',
			plural: 'Объекты ОУ',
			name: 'Название объекта ЧС',
		},
		operationalManagement: {
			searchPlaceholderMap: 'Поиск по названию ОУ, объекта',
			createRecord: 'Создать новую запись',
			workControlsHead: 'Руководитель АСДНР',
			title: 'Оперативное управление ЧС',
			title_short: 'Оперативное управление ',
			threatReport: 'Доклад об угрозе (возникновении) ЧС',
			notifyResponsibleOrganizations: 'Оповещение сил и средств для ликвидации (локализации) угрозы (возникновения) последствий ЧС',
			rescueOperationsControl: 'Контроль проведения АСДНР',
			rescueOperationsReport: 'Доклад о проводимых (проведенных) АСДНР по ликвидации (локализации ЧС)',
			collectingInformation: 'Сбор информации',
			occurrenceDate: 'Время возникновения',
			source: 'Источник информации (Ф.И.О., должность, адрес, контактный № телефон)',
			victim: 'Наличие пострадавших (погибших)',
			reason: 'Причина ЧС',
			scale: 'Масштабы предполагаемой ЧС',
			address: 'Место (адрес) ЧС',
			object: 'Предполагаемый район (объект) ЧС',
			name: 'Наименование предполагаемой ЧС',
			tab: {
				threadReport: 'Доклад об угрозе',
				notifyResponsibleOrganizations: 'Оповещение сил и средств',
				rescueOperationsControl: 'Контроль проведения АСДНР',
				rescueOperationsReport: 'Доклад о проводимых (проведенных) АСДНР',
			},
		},
		operationalManagementStatus: {
			title: 'Статус Оперативного управления ЧС',
			plural: 'Статусы Оперативного управления ЧС',
			title_short: 'Статус',
		},
		operationalManagementClassifier: {
			title: 'Классификатор Оперативного управления ЧС',
			plural: 'Классификаторы Оперативного управления ЧС',
			title_short: 'Классификатор',
		},
		region: {
			title: 'Регион',
			plural: 'Регионы',
		},
		crewType: {
			forceKind: 'Тип силы и средства тушения',
			title: 'Тип противопожарной команды',
			title_short: 'Тип команды',
			plural: 'Типы противопожарных команд',
			formations: 'Лесопожарные формирования',
		},
		extinguishingAgentsType: {
			forceKind: 'Тип силы и средства тушения',
			title: 'Тип средства тушения',
			title_short: 'Средство тушения',
			plural: 'Типы средств тушения',
		},
		discoveryMessageType: {
			title: 'Тип входящего сообщения о нарушениях в лесах',
			plural: 'Типы входящих сообщений о нарушениях в лесах',
		},
		breed: {
			title: 'Преобладающая порода',
			plural: 'Преобладающие породы',
		},
		cover: {
			title: 'Преобладающий покров',
			plural: 'Преобладающие покровы',
		},
		forestCategory: {
			title: 'Категория леса',
			plural: 'Категории лесов',
		},
		forestOwnership: {
			title: 'Принадлежность леса',
			plural: 'Принадлежность лесов',
			short: 'Принадлежность',
		},
		fireCause: {
			title: 'Причина пожара',
			plural: 'Причины пожаров',
		},
		fireStatus: {
			title: 'Cостояние пожара',
			plural: 'Состояния пожаров',
			selected: 'Выбрано статусов',
			codes: {
				abandoned: 'Прекращено тушение по решению КЧС',
				extinguished: 'Ликвидирован',
				resumed: 'Возобновился',
				localized: 'Локализован',
				notspreading: 'Не распространяется',
				weakens: 'Ослабевает',
				continues: 'Продолжается',
				intensifies: 'Усиливается',
				active: 'Активен',
				zik: 'На землях иных категорий',
				serviced: 'Обслуживаемые',
				unattended: 'Необслуживаемые',
			},
		},
		determiningMethod: {
			title: 'Способ обнаружения пожара',
			plural: 'Способы обнаружения пожаров',
		},
		combustionType: {
			title: 'Вид пожара',
			plural: 'Виды пожара',
		},
		combustionIntensity: {
			title: 'Интенсивность горения',
			plural: 'Виды интенсивности горения',
		},
		combustionPattern: {
			title: 'Характер леса',
			plural: 'Виды характера леса',
		},
		airRaid: {
			title: 'Налёт',
			plural: 'Налёты',
			pluralLong: 'Воздушные налеты',
			add: 'Добавление налёт',
			requestNumber: 'Номер заявки',
			requestDate: 'Дата заявки',
			departureDate: 'Дата/время вылета',
			arrivalDate: 'Дата/время прилета',
			mainInfo: 'Основная информация',
			addContract: 'Добавить договор',
			noContracts: 'Нет активных договоров',
			startDate: 'Дата (с) ',
			endDate: 'Дата (по)',
			pickAirRaid: 'Выберите налет',
		},
		airJob: {
			title: 'Воздушная работа',
			plural: 'Воздушные работы',
			add: 'Добавить воздушную работу',
			duration: 'Время выполнения',
			duration_short: 'Время',
			drains: 'Сливы',
			effects: 'Воздействия',
			jumpings: 'Тренировочные прыжки',
			jumpings_short: 'прыжки',
			descents: 'Тренировочные спуски',
			descents_short: 'спуски',
		},
		airJobType: {
			title: 'Вид воздушной работы',
			plural: 'Виды воздушных работ',
		},
		staff: {
			title: 'Личный состав',
			plural: 'Личный состав',
			number: 'Номер в табеле',
			fullName: 'ФИО',
			category: 'Категория',
			tableNumber: 'Номер в табеле',
		},
		unit: {
			title: 'Единица измерения',
			plural: 'Единицы измерения',
			nationalSymbol: 'Условное обозначение (национальное)',
			internationalSymbol: 'Условное обозначение (международное)',
			nationalCode: 'Кодовое обозначение (национальное)',
			internationalCode: 'Кодовое обозначение (международное)',
		},
		contract: {
			title: 'Договор',
			plural: 'Договоры',
			number: 'Номер договора',
			date: 'Дата договора',
			term: 'Срок действия',
			organization: 'Организация',
			aircraftType: 'Тип воздушного судна',
			aircraft: 'Бортовой номер',
		},
		contractor: {
			title: 'Подрядчик',
			plural: 'Подрядчики',
		},
		vehicleType: {
			title: 'Тип транспортного средства',
			plural: 'Типы транспортный средств',
		},
		vehicle: {
			// здесь много дублей из fireDepVehicle, в коде много использований "vehicle" вместо "fireDepVehicle"
			// это надо будет переделать
			title: 'Техника',
			add: 'Добавить технику',
			stateNumber: 'Гос. номер',
			passport: 'ПТС',
			capacity: 'Емкость, в кубометрах',
			pumpCapacity: 'Насос, литров/сек',
			foamCapacity: 'Пена, объем',
			glonass: 'Признак наличия системы ГЛОНАСС',
			year: 'Год выпуска',
			callSign: 'Позывной',
			actualFoamValue: 'Факт. объем пены',
			faxFoamValue: 'Факт. объем пены',
			description: 'Описание',
			actualFuelValue: 'Факт. объем топлива',
			endDate: 'Дата выхода',
			actualPowderCapacity: 'Факт. объем порошка',
		},
		fireDepVehicleStatus: {
			plural: 'Статусы техники',
			title: 'Статус техники',
		},
		fireDepVehicleFuel: {
			title: 'Объем топлива',
			fuelValue: 'Объем топлива',
			value: 'Объем',
			plural: 'Объемы топлива для техники',
		},
		foamCapacity: {
			plural: 'Объем пены',
			capacity: 'Объем',
			deletedAt: 'Удалено',
		},
		aircraftType: {
			title: 'Тип воздушного судна',
			plural: 'Типы воздушных судов',
			group: 'Группа',
			class: 'Класс',
			speed: 'Скорость',
			fuelConsumption: 'Расход топлива',
			fuelSupply: 'Запас топлива',
			runwayLength: 'Длина ВПП',
			aeronauticalSupply: 'Аэронавигационный запас',
		},
		weatherStation: {
			title: 'Метеостанция',
			plural: 'Метеостанции',
			latitude: 'Широта',
			longitude: 'Долгота',
			activity: 'Активность',
			aviaDepId: 'Авиаотделение',
			value: 'Метеошкала',
		},
		airfieldRunway: {
			title: 'ВПП аэродрома',
			plural: 'ВПП аэродрома',
			add: 'Добавить ВПП',
			size: 'Размеры',
			number: 'Номер',
			class: 'Класс',
			pcn: 'PCN',
			utpzCategory: 'Категория УТПЗ',
			icaoCategory: 'Категория ИКАО',
			coverage: 'Покрытие',
			magneticBearing: 'Магнитный курс посадки',
			lightingSystem: 'Светосигнальное оборудование',
		},
		airfield: {
			title: 'Аэродром',
			plural: 'Аэродромы',
			name: 'Наименование',
			add: 'Добавление аэродром',
			icaoCode: 'Код по ИКАО',
			icaoLandingCategory: 'Категория посадки по ИКАО',
			localTime: 'Местное время',
			latitude: 'Контрольная точка: широта',
			longitude: 'Контрольная точка: долгота',
			exceeding: 'Превышение аэродрома',
			magneticDeclination: 'Магнитное склонение',
			iataCode: 'Код аэропорта ИАТА',
		},
		fireFightingVehicle: {
			title: 'Противопожарное средство',
			plural: 'Противопожарные средства',
			plan: 'План',
			required: 'Требуется',
			current: 'Задейстовано сейчас',
			current_short: 'Задейстовано',
			date: 'Дата/время',
		},
		fireFightingPeople: {
			title: 'Противопожарная сила',
			plural: 'Противопожарные силы',
			plan: 'План',
			required: 'Требуется',
			current_short: 'Работает',
			evacuated_short: 'Вывезено',
			delivered: 'Доставлено',
			air_short: 'Авиа',
		},
		fireFightingFormation: {
			title: 'Лесопожарное формирование',
			plural: 'Лесопожарные формирования',
			title_short: 'Формирование',
		},
		FireCharacteristics: {
			location: 'Характеристики местности и пожара',
		},
		observerPilot: {
			title: 'Летчик-наблюдатель',
			plural: 'Летчики-наблюдатели',
			inspector: 'Проверяющий',
			intern: 'Стажёр',
			add: 'Добавить летчика-наблюдателя',
		},
		aircraftKind: {
			title: 'Вид летательного аппарата',
			title_short: 'Вид ЛА',
			plural: 'Виды летательных аппаратов',
		},
		silam: {
			title: 'Загрязнение воздуха',
			on: 'Показывать',
		},
		monitoringZone: {
			title: 'Зона мониторинга',
			plural: 'Зоны мониторинга',
			bigFireLimit: 'Лимит крупного пожара, га',
		},
		fightFireForceKind: {
			plural: 'Типы сил и средств тушения',
			title: 'Тип сил и средств тушения',
		},
		emergencySituation: {
			title: 'Режим ЧС и ОПР',
			title_short: 'ЧС. Акты',
			plural: 'Режимы ЧС и ОПР',
			startAct: '№ акта введения',
			endAct: '№ акта снятия',
			republicStatus: 'На всей территории республики',
			comment: 'Примечание',
			startDate: 'Дата введения',
			endDate: 'Дата снятия',
			type: 'Тип',
			counties: 'Районы',
			kpo: 'КПО (Нестерева)',
		},
		emergencySituationDamage: {
			title: 'Ущерб от режима ЧС',
			title_short: 'ЧС. Потери',
			plural: 'Ущерб от режима ЧС',
			dead: 'Погибших',
			injured: 'Пострадавших',
			affectedObjects: 'Поcтрадавших объектов',
			comment: 'Примечание',
		},
		emergencySituationType: {
			title: 'Тип чрезвычайной ситуации',
			plural: 'Типы чрезвычайных ситуаций',
		},
		requestLog: {
			title: 'Журнал обращений',
			plural: 'Журнал обращений',
			number: 'Номер',
			date: 'Дата',
			location: 'Местоположение',
			description: 'Описание',
			measures: 'Принятые меры',
			result: 'Результат',
			contactInfo: 'Контактная информация',
			messageType: 'Тип сообщения',
			operator: 'Оператор',
			processed: 'Обработано',
		},
		fireReport: {
			title: 'Пожарный отчет',
			plural: 'Пожарные отчеты',
			coordinats: 'Координаты пожара',
			tenant: 'Арендатор',
			localization: 'Дата локализации/ликвидации пожара',
			peopleTypes: 'Дополнительные типы людей',
			stayed: 'Люди на конец дня',
			year: 'Год',
			month: 'Месяц',
			groupBy: 'Группировка',
			nonForestArea: 'Нелесная площадь',
			noAviaDep: 'Без авиаотделения',
			noCounty: 'Без района',
			noForestry: 'Без лесничества',
			header: 'Шапка',
			sourceFirefightingDrain: 'Ближайший источник наружного противопожарного водоснабжения',
			fireInfo: 'Сведения о пожаре',
			stewCount: 'К тушению пожара привлекалось',
			securityData: 'Сведения о проведении мероприятий по обеспечению пожарной безопасности объекта',
			postSign: 'Должность (Подпись)',
			sign: 'Подпись',
			group: 'Группировать',
			byArea: 'по площади',
			byExtinguishingTime: 'по времени тушения',
		},
		forestryQuarter: {
			title: 'Квартальная сеть',
			short: 'Квартал',
			plural: 'Квартальная сеть',
			area: 'Площадь кв.м.',
			add: 'Добавить квартал',
			name: 'Наименование квартала',
			code: 'Код квартала',
			forestry: 'Лесничество',
			forestryPrecinct: 'Участковое лесничество',
			forestCategory: 'Категория лесов',
			aviaDep: 'Авиаотделение',
			monitoringZone: 'Зона мониторинга',
		},
		forestryPrecinct: {
			title: 'Участковое лесничество',
			plural: 'Участковые лесничества',
			short: 'Уч.лесничество',
		},
		aircraft: {
			title: 'Воздушное судно',
			plural: 'Воздушные суда',
			number: 'Бортовой номер',
			number_short: 'б/н',
			contract: 'Договор',
		},
		weather: {
			title: 'Погода',
			temp: 'Температура',
			tempDescription: 'Температура на высоте 2 м над землей',
			tempMeasure: '°C',
			pressure: 'Давление',
			pressureDescription: 'Атмосферное давление',
			pressureMeasure: 'мм рт.ст.',
			precipitation: 'Осадки',
			precipitationDescription: 'Накопление осадков за 3 часа',
			precipitationMeasure: 'кг/м²',
			humidity: 'Влажность',
			humidityDescription: 'Относительная влажность на высоте 2 м над землей',
			humidityMeasure: '%',
			pm25: 'PM2.5',
			pm25Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 2.5 мкм и менее',
			pm25Measure: 'мкг/м³',
			pm10: 'PM10',
			pm10Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 10 мкм и менее',
			pm10Measure: 'мкг/м³',
			co: 'CO',
			coDescription: 'Описание слоя CO',
			coMeasure: 'мкг/м³',
			no: 'NO',
			noDescription: 'Описание слоя NO',
			noMeasure: 'мкг/м³',
			no2: 'NO2',
			no2Description: 'Описание слоя NO2',
			no2Measure: 'мкг/м³',
			ozone: 'Озон',
			ozoneDescription: 'Описание слоя O3',
			ozoneMeasure: 'DU',
			so2: 'SO2',
			so2Description: 'Описание слоя SO2',
			so2Measure: 'кг/м²',
			feeling: 'Ощущается как',
		},
		firepopup: {
			fire: 'Пожар',
			point: 'Термоточка',
			info: 'Информация',
			weather: 'Погода',
		},
		wind: {
			title: 'Ветер',
			speedFactor: 'Скорость',
			fadeOpacity: 'Длина шлейфа',
			particleLife: 'Жизнь частицы',
			opacity: 'Прозрачность',
		},
		notifyStatus: {
			title: 'Статус сообщения',
			plural: 'Статусы сообщений',
			status: 'Статус',
		},
		notifyType: {
			title: 'Тип сообщения',
			plural: 'Типы сообщений',
			type: 'Тип',
			default: 'По умолчанию',
			select: 'Выберите тип сообщения',
			active: 'Активное API',
		},
		notifyTemplate: {
			title: 'Шаблон сообщения',
			plural: 'Шаблоны сообщений',
			template: 'Шаблон',
			noTemplates: 'Шаблоны не добавлены',
		},
		notifyLog: {
			title: 'Cообщение',
			plural: 'Сообщения',
			text: 'Текст сообщения',
			phone: 'Телефон получателя',
			recipient: 'Получатель',
			date: 'Дата отправления',
			add: 'Новая рассылка',
			pickTemplate: 'Выбрать шаблон',
			mailing: 'Информирование',
			recipientsCount: 'Количество получателей',
			notSent: 'Не отправлялось',
			noApi: 'API не подключено',
			accepted: 'Подтверждено',
			notAccepted: 'Не подтверждено',
			acceptedPlaceholder: 'Статус подверждения',
		},
		notify: {
			massNotify: 'Для подтверждения массовой рассылки требуется ввести код в конце формы',
			code: 'Код подтверждения',
		},
		case: {
			mainDate: 'Основные данные',
			messages: 'Сообщения',
			sendMessage: 'Отправить сообщение',
			notifyFilter: 'Фильтры по сообщениям',
			noCounty: 'Район не определен',
		},
		camera: {
			title: 'Камера',
			plural: 'Камеры',
			error: 'Ошибка подключения',
			maximize: 'развернуть',
			minimize: 'свернуть',
			url: 'URL',
		},
		dzz: {
			title: 'ДЗЗ',
		},
		genShtab: {
			title: 'Генштаб СССР',
		},
		fireDashboard: {
			currentTime: 'По текущее время',
			firePlanExecution: 'Исполнение плана тушения по районам',
			firePlanExecutionPercent: 'Процент исполнения плана тушения',
			municipality: 'Муниципальный район',

			title: 'Сводка',
			total: 'Всего',
			active: 'Действует пожаров',
			fires: 'Пожаров',
			dynamics: 'Динамика ситуации',
			cityDanger: 'Угроза населенным пунктам',
			area: 'Площадь, Га',
			totalArea: 'Общая пл., Га',
			dailyIncrease: 'Прирост в сутки, Га',
			extinguished: 'Ликвидировано',
			firesCount: 'Количество пожаров',
			fullReport: 'Полная сводка',
			operationalReport: 'Оперативная сводка',
			sectionDescription: 'По ситуации с ландшафтными пожарами на территории Республики Саха (Якутия)',
			underway: 'Ведутся работы',
			suspended: 'Приост. Тушен.',
			unattended: 'Не обслуж.',
			activeArea: 'Действующих пожаров, Га',
			extinguishedArea: 'Ликвидировано пожаров, Га',
			noDanger: 'Угроз населенным пунктам нет',
			noEmergencySituation: 'Активных режимов ЧС и ОПР нет',
			dailyExtinguished: 'Ликвидировано пожаров за сутки',
			dailyDetected: 'Обнаружено пожаров за сутки',
			dailyLocalized: 'Локализовано пожаров за сутки',
			dailyUnderway: 'Задействовано в работе за сутки',
			naturalGrouth: 'Естественный прирост пожаров',
			naturalGrouthShort: 'Ест. пр. пож.',
			personnel: 'Людей',
			vehicles: 'Тех. средств',
			seasonFires: 'Зарегистрировано пожаров с начала сезона',
			currentYear: 'Текущий год',
			lastYear: 'Аналогичный период прошлого года',
			seasonTotalCount: 'Всего, пожаров',
			seasonTotalArea: 'Общая площадь, Га',
			count: 'Кол-во',
			citiesCount: 'Населенных пунктов, шт',
			distance: 'Расст., км',
			citiesSpecial: 'На особом контроле',
			citiesUnderway: 'Ведутся работы',
			citiesUnattended: 'В пред. 10 км зоны',
			citiesSpecialTest: 'На особом контроле (20 км)',
			citiesUnderwayTest: 'Ведутся работы (50 км)',
			citiesUnattendedTest: 'В пред. 50 км зоны',
			dynamicsType: {
				forest: 'Динамика ситуации на территории лесного фонда (лесные пожары)',
				oopt: 'Динамика ситуации на особо охраняемых природных территориях',
				other: 'Динамика ситуации на иных категориях земель',
			},
			forestOwnershipType: {
				forest: 'На территориях лесного фонда',
				oopt: 'На территориях ООПТ',
				other: 'На иных категориях земель',
			},
			emergencySituation: 'Режимы ЧС и ОПР',
			CHS: 'Чрезвычайная ситуация',
			OPR: 'Особый противопожарный режим',
			extinguishing: 'Оперативность тушения лесных пожаров',
			extinguishingDaily: 'В первые сутки (в %)',
			extinguishingDuration: 'Продолжительность тушения (в сутках)',
		},
		address: {
			title: 'Адрес',
			addNew: 'Добавить новый адрес',
		},
		errand: {
			dashboard: 'Статистика исполнения поручений Главы РС(Я)',
			freeMode: 'Произвольный период',
			week: 'неделя',
			month: 'месяц',
			halfYear: 'полгода',
			year: 'год',
			org: 'Исполнитель',
		},
		monMap: {
			hpSelectModeDisabled: 'Включите слой термоточек',
			hpSelectAdd: 'Режим выделения: добавить термоточки',
			hpSelectSubtract: 'Режим выделения: удалить термоточки',
			hpSelectRadius: 'Радиус вокруг точки',
			metersShort: 'м',
			kmShort: 'км',
			searchHint: 'Поиск по широте/долготе',
			gridHint: 'Градусная сетка',
			export: 'Экспорт',
			exportHint: 'Экспортировать в PNG формате',
			exportFormat: 'Формат',
			exportResolution: 'Разрешение',
		},
		technoZone: {
			title: 'Техногенная зона',
			plural: 'Техногенные зоны',
		},
		isdm: {
			title: 'Термоточки ИСДМ',
			titleLong: 'Информационная система дистанционного мониторинга',
			METEO: 'Метеоинформация 1-МЕТЕО',
			ISDM1T: '1-Т ИСДМ',
			ISDM1: '1-ИСДМ',
			ISDM8: '8-ИСДМ',
			ISDM9: '9-ИСДМ',
			nonforest: 'Включая нелесные пожары',
			groupAirDepartments: 'По авиаотделениям',
			groupAdministrativeRegions: 'По административным районам',
			noGroup: 'Не группировать',
		},
		isdmFire: {
			title: 'ИСДМ',
			plural: 'Информационная система дистанционного мониторинга',
			fireNumber: 'Номер пожара',
			externalId: 'Внешний номер',
			reportDate: 'Дата первого наблюдения',
			processed: 'Обработано',
		},
		nasleg: {
			title: 'Наслег',
			plural: 'Наслеги',
		},
		extinguishingAgentsGroup: {
			title: 'Группа средств тушения',
			plural: 'Группы средств тушения',
		},
		delivery: {
			title: 'Северный завоз',
			map: 'Карта',
		},
		reserve: {
			lastMovementUpdate: 'Дата обновления запаса',
			searchPlaceholder: 'Поиск по номенклатуре, категории, складу',
			title: 'Запас',
			plural: 'Запасы',
			nomenclature: 'Номенклатура',
			title_full: 'Контроль запасов',
			measure: 'Единица измерения',
			storage: 'Хранилище',
			quantity: 'Количество запаса',
			fact: 'Факт',
			capacity: 'Вместимость склада',
			available: 'Свободно',
			nomenclatureCard: 'Карточка номенклатуры',
			createNomenclature: 'Создать номенклатуру',
			delete: 'Удалить номенклатуру',
			mainInfo: 'Общая информация',
			movement: 'Движение',
			newNomenclature: 'Новая номенклатура',
			date: 'Дата добавления',
			startQuantity: 'Начальный остаток',
			income: 'Приход',
			cost: 'Расход',
			movementType: 'Тип движения',
			category: 'Категория номенклатуры',
		},

		reserveMovement: {
			title: 'Движение номенклатуры',
			plural: 'Движение номенклатуры',
		},
		contragent: {
			title: 'Контрагент',
			plural: 'Контрагенты',
			delete: 'Удалить контрагента',
			create: 'Добавить контрагента',
			mainInfo: 'Общая информация',
			card: 'Карточка контрагента',
			staff: 'Сотрудники',
			bankAccounts: 'Банковские счета',
			requests: 'Заявки',
			agreements: 'Реестр соглашений',
		},
		nomenclature: {
			title: 'Номенклатура',
		},
		plan: {
			title: 'План',
			plural: 'План',
			total: 'Суммарный плановый объем',
			volume: 'Плановый объем',
		},
		author: {
			title: 'Автор',
			select: 'Выберите автора',
		},
		requestAvailablePeriod: {
			title: 'Период заявки на поставку',
			plural: 'Периоды заявок на поставку',
			startDate: 'Начало периода',
			endDate: 'Конец периода',
		},
		request: {
			closestPeriod: 'Ближайший период для подачи заявок',
			currentPeriod: 'Текущий период для подачи заявок',
			copy: 'Копировать заявку',
			name: 'Название заявки',
			isManualTracker: 'Ручной ввод трек-данных',
			planNumber: '№ Плана',
			messageTitle: 'Опишите запрос',
			sendMessageToOwner: 'Запрос',
			actualDeliveryDate: 'Фактическая дата поставки',
			agreementRole: 'Роль в согласовании',
			title: 'Заявка',
			plural: 'Заявки',
			number: 'Номер заявки',
			type: 'Тип заявки',
			noStatuses: 'Не заданы состояния заявок',
			author: 'Автор',
			members: 'Участники',
			agreementMembers: 'Участники согласовования',
			customer: 'Заказчик',
			contractor: 'Предприятие',
			river: 'Река',
			direction: 'Направление',
			create: 'Создать заявку',
			collective: 'Коллективная заявка',
			member: 'Участник',
			selectMember: 'Выберите участника',
			addMember: 'Добавить участника',
			agreement: 'Согласование',
			mainInfo: 'Основная информация',
			mainInfo_full: 'Основная информация о заявке',
			customerInfo: 'Информация о заказчике',
			requestLog: 'Журнал событий',
			discussion: 'Обсуждение',
			date: 'Дата создания заявки',
			noOwnerOrganization: 'У создателя заявки нет организации',
			departureCity: 'Пункт отгрузки',
			destinationCity: 'Пункт доставки',
			deliveryDate: 'Плановая дата поставки',
			trackNumber: '№ Трека',
			delete: 'Удалить заявку',
			positionsCount: 'Позиций',
			changed: 'Данные заявки были изменены',
			addPlan: 'Добавить план',
			shipment: 'Рейсов',
			status: 'Статус заявки',
			levels: 'Уровни заявки',
			BASIC: 'Заявки',
			SCHEDULE: 'План',
			selectCustomer: 'Выберите заказчика',
			selectCounty: 'Выберите район',
			selectDirection: 'Выберите направление',
			county: 'Район',
			category: 'Категория',
			export: {
				category: 'Категория товара',
				nomenclature: 'Номенклатура груза',
				numberTrack: '№ ТТН',
				numberPlan: '№ плана (по ИАС)',
				quantityTNE: 'План, тонн',
				shippedCount: 'Отгружено, тонн',
				deliveredCount: 'Доставлено, тонн',
				carrier: 'Перевозчик',
				remainingToShip: 'Остаток к отгрузке, тонн',
				shipmentPercentage: '% отгрузки',
				deliveryPercentage: '% доставки',
			},
		},
		ladingBill: {
			short: 'Накладная',
			title: 'Товарно-транспортная накладная',
			plural: 'Товарно-транспортные накладные',
			add: 'Добавить накладную',
			addFull: 'Добавить транспортную накладную',
			delete: 'Удалить накладную',
			sender: 'Отправитель',
			payer: 'Плательщик',
			consignee: 'Грузополучатель',
			totalItems: 'Всего наименований',
			totalReleased: 'Всего отпущено',
			netWeight: 'Масса груза(нетто)',
			grossWeight: 'Масса груза(брутто)',
			noLadingBill: 'Накладные не найдены',
			number: 'Номер документа',
			date: 'Дата документа',
			carrier: 'Перевозчик',
		},
		supply: {
			packageType: 'Тип упаковки',
			placeQuantity: 'Кол-во мест',
			nomenclatureCode: 'Номенклатурный код',
		},
		requestTrack: {
			title: 'Местоположение груза',
			status: 'Статус груза',
		},
		history: {
			planApproved: 'Добавлен план ЧС, статус плана изменен',
			comments: 'Комментарии',
			leftComment: 'оставил(а) комментарий',
			leftCommentDiscussion: 'оставил(а) комментарий в обсуждении',
			updated: 'обновил(а)',
			deleteFile: 'удалил(а) файл',
			updateMemberStatus: 'изменил(а) статус согласования заявки на',
			updateMemberStatus_short: 'изменил(а) статус согласования заявки',
			to: 'на',
			requestCreate: 'создал(а) заявку',
			requestUpdate: 'изменил(а) поля заявки',
			addMember: 'добавил(а) участника',
			addLadingBill: 'добавил(а) накладную',
			addTrack: 'добавил(а) местоположение груза',
			removeMember: 'удалил(а) участника',
			removeLadingBill: 'удалил(а) накладную',
			removeTrack: 'удалил(а) местоположение груза',
			requestStatus: 'изменил(а) статус заявки',
			changeTrackStatus: 'Статус груза изменен на',
			allAgreed: 'все участники согласовали заявку, статус заявки изменен',
			notAllAgreed: 'не все участники согласовали заявку, статус заявки изменен',
			addExplanatoryNoteFile: 'добавил файл пояснительной записки',
			removeExplanatoryNoteFile: 'удалил файл пояснительной записки',
			addPlanFile: 'добавил файл плана ЧС',
			removePlanFile: 'удалил файл плана ЧС',
			addCorrectionPlanFile: 'добавил файл корректированного плана ЧС',
			removeCorrectionPlanFile: 'удалил файл корректированного плана ЧС',
		},
		emergency: {
			title: 'Планирование по ЧС',
			map: 'Карта',
			registry: 'Реестр МО',
			reserves: 'Резервы',
		},
		activityType: {
			title: 'Вид деятельности',
			plural: 'Виды деятельности',
		},
		direction: {
			title: 'Направление',
			plural: 'Направления',
		},
		municipalSufficiencyStatus: {
			title: 'Статус достаточности',
			plural: 'Статусы достаточности',
		},
		municipalReadinessStatus: {
			title: 'Статус готовности',
			plural: 'Статусы готовности',
		},
		municipalFormation: {
			risks: 'Риски / людей / детей',
			citiesCount: 'Городов',
			peopleCount: 'Людей',
			teenagersCount: 'Детей',
			plural: 'Муниципальные образования',
			county: 'Район, городской округ',
			nasleg: 'Наслег',
			name: 'Наименование МО',
			city: 'Населенный пункт',
			cities: 'Населенных пунктов',
			population: 'Население, чел.',
			foam: 'Пена',
			gasi: 'ГАСИ',
			protectiveSuit: 'Защитные костюмы',
			fireRanks: 'Ранги пожара',
			positions: 'Должности',
			ranks: 'Звания',
			typeVehicle: 'Виды техники',
			departments: 'Отделы',
			area: 'Площадь, кв.м',
			sufficiencyStatus: 'Статус достаточности',
			sufficiencyStatusFull: 'Состав сил и средств МО',
			readinessStatus: 'Статус готовности',
			readinessStatusFull: 'Статус готовности плана по предупреждению и ликвидации ЧС',
			number: 'Номер',
		},
		bankAccount: {
			title: 'Банковский счет',
			plural: 'Банковские счета',
			bank: 'Банк',
			bic: 'БИК',
			swift: 'СВИФТ',
			correspondentAccount: 'Расчетный счет',
		},
		mineral: {
			plural: 'Полезные ископаемые',
			mining: 'Добыча полезных ископаемых',
			abc1: 'ABC1',
			c2: 'C2',
			zabC: 'Заб.С',
			PI: 'ПИ',
			PIType: 'Вид ПИ',
			units: 'Ед.изм.',
		},
		speed: 'Скорость',
		course: 'Курс',

		ship: {
			title: 'Судно',
			plural: 'Водный транспорт',
			callSign: 'Позывной',
			imo: 'IMO',
			source: 'Источник данных',
			marineReg: 'Номер РМРС',
			receiveDate: 'Дата обновления данных',
		},
		storehouse: {
			title: 'Склад',
			plural: 'Склады',
			measure: 'Единица измерения',
			capacity: 'Вместимость',
			select: 'Выберите склад',
			address: 'Юридический адрес',
			area: 'Площадь, м2',
			wearout: 'Износ, %',
			county: 'Район, городской округ',
		},
		requestMemberStatus: {
			title: 'Статус участника заявки',
			plural: 'Статусы участника заявки',
		},
		requestStatus: {
			title: 'Статус заявки',
			plural: 'Статусы заявки',
		},
		requestType: {
			title: 'Тип заявки',
			plural: 'Типы заявки',
		},
		requestTrackStatus: {
			title: 'Статус трека заявки',
			plural: 'Статусы трека заявки',
		},
		supplyCategory: {
			title: 'Категория товара',
			plural: 'Категории товара',
			parent: 'Родительская категория',
			select: 'Выберите категорию',
		},
		nomenclatureCategory: {
			title: 'Категория товара',
			plural: 'Категории товара',
			parent: 'Родительская категория',
			select: 'Выберите категорию',
		},
		supplyNomenclature: {
			title: 'Номенклатура товара',
			plural: 'Номенклатуры товаров',
		},
		emergencyNomenclature: {
			title: 'Номенклатура',
			plural: 'Номенклатуры',
			cityId: 'Населенный пункт',
			norma: 'Норма',
			unit: 'Единица измерения',
		},
		emergencyReserves: {
			title: 'Резервы',
			name: 'Название',
			nomenclature: 'Номенклатура',
			fact: 'Факт',
			norma: 'Норма',
			county: 'Район',
			city: 'Населенный пункт',
			unit: 'Единица измерения',
			category: 'Категория',
			date: 'Дата',
		},
		supplyMeasure: {
			title: 'Единица измерения товара',
			plural: 'Единицы измерения товара',
		},
		techFireCauseType: {
			plural: 'Виды и причины возгораний',
			title: 'Вид и причина возгораний',
		},
		techFire: {
			addInfo: 'Дополнительные сведения',
			blockedRoad: 'Перекрытые дороги',
			rescuedPeople: 'Людей',
			firereport: 'Донесение о пожаре',
			additionalTech: 'А/техника',
			specialVehicle: 'Спец.техника',
			damaged: 'Повреждено',
			ond: 'ОНД',
			subscribeSituation: 'Опишите обстановку на момент прибытия',
			reportTimeEmergency: 'Дата/время поступления сообщения на 112',
			phoneOperator: 'Номер оператора 112',
			evacuated: 'Эвакуировано',
			addition: 'Дополнение',
			terminationFireTime: 'Время ликвидации',
			situationToFirstDivison: 'Обстановка на момент прибытия первого пожарного подразделения',
			firstSupplyTime: 'Время подачи первого ствола',
			rank: 'Ранг пожара',
			messageFireDep: 'Дата/время сообщения в ПО',
			status: 'статус ПСЧ для 112',
			title: 'Техногенный пожар',
			plural: 'Техногенные пожары',
			create: 'Новый пожар',
			mainInfo: 'Общая информация',
			infoAboutFire: 'Общие данные о пожаре',
			afterMath: 'Последствия',
			date: 'Дата пожара',
			detectTime: 'Дата/время обнаружения',
			destroyedBuildings: 'Уничтожено зданий',
			destroyedBuildingsCompletely: 'Уничтожено строений полностью',
			message: 'Сообщения в пожарную охрану',
			person: 'Заявитель',
			infoAboutPerson: 'Данные о заявителе',
			personName: 'Ф.И.О. заявителя',
			phone: 'Телефон',
			address: 'Адрес',
			fireAddress: 'Адрес пожара',
			distance: 'Расстояние до пожарной части (км)',
			time: 'Время прибытия',
			situation: 'Обстановка',
			timeServing: 'Время подачи',
			firstUnit: 'Время прибытия первого подразделения',
			effects: 'Последствия пожара',
			situationToFirstDivision: 'Обстановка на момент прибытия первого пожарного подразделения',
			timeFirstSupplyLocalizationTermination: 'Время подачи первого ствола, локализация, ликвидация открытого горения, ликвидация',
			area: 'Площадь (кв.м.)',
			destroyVeh: 'Уничтожено техники',
			destroyAnimals: 'Скота и птицы',
			infoAffected: 'Сведения о погибших и пострадавших',
			resultInquiry: 'Результаты дознания',
			causes: 'Причина',
			culprit: 'Виновник',
			damage: 'Ущерб (руб)',
			damageText: 'Ущерб',
			workFireDep: 'Работа пожарных подразделений',
			vehiclesCount: 'Задействовано техники',
			rescueTeamsCount: 'Звеньев ГДЗС',
			barrelsCount: 'Кол-во стволов',
			waterSupplyMounts: 'Установка на водоисточники',
			dutyInspector: 'Деж. инспектор',
			organizations: 'Службы взаимодействия',
			serviceAdd: 'Добавить службу',
			rescued: 'Спасено',
			savedPeople: 'Людей (всего)',
			savedAdult: 'из них Взрослых (кол-во)',
			savedChildren: 'из них Детей (кол-во)',
			savedMaterials: 'Материалов',
			savedValues: 'Ценностей (руб.)',
			savedBuildings: 'Строений',
			savedVehicles: 'Техники (ед.)',
			savedLivestock: 'Скота и птиц (голов)',
			fireDep: 'Ведомственная принадлежность',
			localizationTime: 'Время локализации',
			terminationTime: 'Время ликвидации открытого горения',
			schedule: 'Расписание выездов',
			selectedGarrisons: 'Выбрано гарнизонов',
			selectGarrison: 'Выберите гарнизон',
			listPCHOnFire: 'Список ПЧ задействованных на пожаре',
			userNotHaveFireDep: 'У пользователя не выбрана Пожарная часть в профиле',
			map: {
				title: 'Карта',
			},
			fires: {
				titleDepartures: 'Учет выездов',
				title: 'Учет пожаров',
			},
			dashboard: {
				title: 'Сводка',
			},
			statistics: {
				dashboardStat: 'Дэшборд статистика',
				title: 'Статистика',
				firesAndDamage: 'Пожары и ущерб',
				casualties: 'Людские потери',
				injuriesReceived: 'Получено травм',
				numberOfFires: 'Количество пожаров',
				fireDamage: 'Ущерб от пожара',
				materialAssetsSaved: 'Спасено материальных ценностей',
				thousandRub: 'тыс. руб.',
				includingChildren: 'В том числе детей',
				peopleSaved: 'Спасено людей',
				peopleEvacuated: 'Эвакуировано людей',
				recommendedPeriod: 'Рекомендуется выбирать период в пределах года',
			},
			firesPlanVisits: {
				title: 'Выезд',
				plural: ' Выезда',
				create: 'Создать выезд',
				dateOfCreation: 'Дата и время создания',
				dateOfDeparture: 'Дата и время выезда',
				visitType: 'Тип выезда',
				visitSubType: 'Подтип выезда',
				dateOfReturn: 'Дата и время возврата в ПЧ',
				resultOfVisit: 'Результат выезда',
				moveTechnique: 'Переместить технику',
				returnTechnique: 'Вернуть технику',
				delete: 'Удалить выезд',
				address: 'Адрес, объект',
				returnedBack: 'Техника перемещена обрратно',
				addressManual: 'Адрес, объект (ручной ввод)',
				fireDeps: 'Учавс-щие ПЧ',
				PTZ: 'ПТЗ',
				PTU: 'ПТУ',
				otherTypesVisits: 'Остальные типы выездов',
				select: 'Выберите тип выезда',
				selected: 'Типов выбрано',
			},
			notifier: {
				title: 'Пожарный извещатель',
				fireObject: 'Объект который горит',
				source: 'Источник',
				messageDate: 'Время сообщения',
				operatorNumber: 'Номер оператора',
				incidentCode: 'Код проишествия',
				responseTime: 'Время обработки вызова',
			},
			weather: {
				title: 'Метеосводка',
			},
			calls: {
				title: 'Обзвон',
				updated: 'Обновлено',
				rank: 'Ранг',
			},
			plans: {
				title: 'План выездов на год',
				plural: 'Планы выездов на год',
				year: 'Год планирования',
				visitType: 'Тип выезда',
				visitSubType: 'Подтип выезда',
				amount: 'Количество выездов',
			},
			isDtp: 'ДТП',
		},
		techFireInteractionServiceType: {
			title: 'Служба взаимодействия',
			plural: 'Службы',
		},
		techFireCallStatus: {
			plural: 'Статусы обзвона',
			title: 'Статус обзвона',
		},
		techFireVisitType: {
			plural: 'Типы плановых выездов',
			title: 'Тип планового выезда',
			parent: 'Родительский элемент',
		},
		techFireVisitSubType: {
			plural: 'Подтипы плановых выездов',
			title: 'Подтип планового выезда',
			parent: 'Родительский элемент',
		},
		techFireObjectType: {
			plural: 'Виды объектов',
			title: 'Вид объекта',
		},
		techFireAddress: {
			title: 'Объект, здание',
			address: 'Адрес',
			registry: 'Адреса, объекты',
			name: 'Наименование объекта',
			rank: 'Ранг пожароопасности',
			allRanks: 'Все ранги',
			fireDep: 'Ведомственная принадлежность',
			fireDepFrom: 'Из пожарной части',
			fireDepTo: 'В пожарную часть',
			description: 'Краткая характеристика объекта',
			info: 'Объект',
			objects: 'Объекты',
			pickPoint: 'Выбор точки на карте',
			noObjects: 'Нет объектов',
			newObject: 'Новый объект',
			select: 'Выбрать этот объект',
			noValue: 'Объект не выбран!',
			searchOtherAddress: 'Искать другой адрес',
			ptp: 'ПТП',
			ktp: 'КТП',
			ptpCount: 'ПТП',
			ktpCount: 'КТП',
			overnightStay: 'С ночным пребыванием',
			overnightStayPeople: 'С ночным пребыванием людей',
			status: 'Статус',
			organizationName: 'Наименование организации',
			contactInfo: 'Контактная информация',
			totalPeople: 'Всего людей',
			total: 'всего',
			adults: 'Взрослых',
			children: 'Детей',
			unableMoveIndependently: 'Количество людей не способных передвигаться самостоятельно',
			courtship: 'Ухаживающие',
			personnel: 'Персонал',
			whoTransmittedMessage: 'ФИО лица передавшего сообщение',
			actualDate: 'Дата актуализации',
			selectRank: 'Выберите ранг пожароопасности',
			selectedRanks: 'Выбрано рангов',
			information: 'Информация',
			inspectorFullName: 'ФИО проверяющего',
			inspectorPosition: 'Должность проверяющего',
			inspectorOrganization: 'Организация проверяющего',
			lastInspectionDate: 'Дата последней проверки ПТП и КТП (Отработка)',
			lastInspectionDateCorrect: 'Дата последней проверки ПТП и КТП (Корректировка)',
			inspectionDateFrom: 'Дата отработки ПТП, КТП с',
			inspectionDateFromCorrect: 'Дата корректировки ПТП, КТП с',
			inspectionDateBefore: 'Дата отработки ПТП, КТП до',
			inspectionDateBeforeCorrect: 'Дата корректировки ПТП, КТП до',
			inspectionDate: 'Дата плановой проверки ПТП и КТП (Отработка)',
			inspectionDateCorrect: 'Дата плановой проверки ПТП и КТП (Корректировка)',
			commentInspector: 'Комментарий проверяющего',
			manualFill: 'Заполнить вручную ФИО, должность, организацию проверяющего',
			overduePTPCTP: 'Просроченные отработки ПТП, КТП',
			workingOutPTPCTP: ' Просроченные корректировки ПТП, КТП',
		},
		techFireRank: {
			title: 'Ранг пожароопасности объекта',
			plural: 'Ранги пожароопасности объектов',
			codes: {
				1: '1 ранг пожара',
				2: '1 СБИС',
				3: '2 ранг пожара',
				4: '3 ранг пожара',
			},
		},
		techFireTrunkView: {
			title: 'Вид пожарного ствола',
			plural: 'Виды пожарного ствола',
		},
		techFireTrunkType: {
			title: 'Тип пожарного ствола',
			plural: 'Типы пожарного ствола',
			view: 'Вид',
		},
		techFireTrunk: {
			add: 'Добавить ствол',
		},
		techFireCategory: {
			title: 'Категория объекта',
			plural: 'Категории объектов',
		},
		techFireCasualty: {
			isChild: 'Ребенок',
			isInjured: 'Травмирован',
			deceased: 'Погибший',
			furtherAction: 'Дальнейшие действия',
			detectTime: 'Время обнаружения',
			lastName: 'Фамилия',
			birth: 'Год рождения',
			socialStatus: 'Социальное положение',
			add: 'Добавить пострадавшего',
			firstName: 'Имя',
			middleName: 'Отчество',
		},
		techFireChief: {
			title: 'Руководитель тушения пожара',
			add: 'Добавить РТП',
			text: 'Текст',
			orderNumber: 'Номер приказа',
			endDate: 'Дата окончания',
			startDate: 'Дата приказа',
		},
		fireAlarm: {
			title: 'АДПИ',
			phoneNumber: 'Номер телефона',
			info: 'Дополнительная информация',
			objectBuildingManualEntry: 'Объект, здание (ручной ввод)',
			status: {
				title: 'Состояние АДПИ',
			},
			location: {
				title: 'Тип объекта где установлен',
			},
			type: {
				title: 'Тип АДПИ',
			},
			source: {
				title: 'Источник сигнализации',
			},
		},
		analytics: {
			title: 'Аналитика',
		},
		tracking: {
			title: 'Трекинг грузов',
			titleShort: 'Трекинг',
			create: 'Создать груз',
			trackNumber: 'Номер трека',
			nameList: '№ Накладной',
			nameSingle: 'Номер документа',
			documentDate: 'Дата документа',
			planedDeliveryDate: 'Плановая дата поставки',
			shipmentDate: 'Дата отгрузки',
			deliveredDate: 'Дата доставки',
			organizationFrom: 'Грузоотправитель',
			organizationTo: 'Грузополучатель',
			payer: 'Плательщик',
			cityFrom: 'Пункт отгрузки',
			cityTo: 'Пункт доставки',
			inn: 'ИНН',
			nomenclature: 'Номенклатура',
			category: 'Категория',
			cargoStatus: 'Статус груза',
			direction: 'Направление',
			nomenclatureNames: 'Номенклатуры грузов',
			menu: {
				mainInfo: 'Общая информация',
				cargoTracking: 'Отслеживание груза',
			},
		},
		trackingStatus: {
			SHIPPED: 'Отгружен',
			DELIVERED: 'Доставлен',
		},
		trackingProduction: {
			name: 'Наименование продукции, товара, груза, ТУ, марка, размер, сорт',
			code: 'Код продукции (номенклатурный номер)',
			productionCount: 'Количество',
			price: 'Цена, руб.коп',
			unit: 'Единица измерения',
			containerType: 'Вид упаковки',
			seatsNumber: 'Кол-во мест',
			totalProduction: 'Всего наименований',
			totalNetto: 'Масса груза (нетто)',
			totalBrutto: 'Масса груза (брутто)',
			totalCost: 'Всего отпущено',
			status: 'Статус груза',
			update: 'Обновления',
			transporter: 'Грузоперевозчик',
		},
		regionLevel: {
			title: 'Уровень',
			plural: 'Уровни',
			select: 'Выберите уровень',
		},
		emergencySituationPlan: {
			enterNewName: 'Введите новое название плана ЧС',
			title: 'План ЧС',
			plural: 'Планы ЧС',
			agreement: 'Согласование',
			delete: 'Удалить план ЧС',
			create: 'Создать план ЧС',
			levels: 'Уровни плана ЧС',
			changed: 'Данные плана ЧС были изменены',
			noStatus: 'Статус не назначен',
			dataOnMap: 'Показать данные на карте',
			legend: 'Условные обозначения',
			attachPlanFiles: 'Прикрепить план',
			attachCorrectionPlanFiles: 'Прикрепить корректированный план',
			name: 'Наименование плана ',
		},
		analytic: {
			title: 'Оперативная информация',
			plural: 'Оперативная информация',
			productName: 'Название груза',
			date: 'Дата статистики',
			settlement: 'Населенные пункты',
			organization: 'Контрагенты',
			mainInfo: 'Основная информация',
			plan: 'План',
			ship: 'Отгружено',
			deliver: 'Доставлено',
			contragent: 'Контрагент',
			tab1: 'Страница 1',
			tab2: 'Страница 2',
			winter: 'Зима',
			summer: 'Лето',
		},
		fireDep: {
			selectPlaceholder: 'Выберите ПЧ',
			add: 'Добавить ПЧ',
			name: 'Наименование ПЧ',
			totalCountFireDeps: 'Всего ПЧ: ',
			nameDepartment: 'Наименование подразделения',
			isSPT: 'Является СПТ',
			code: 'Код',
			chief: 'РТП',
			dutyInspector: 'Деж. инспектор',
			distance: 'Расстояние',
			title: 'Пожарная часть',
			plural: 'Пожарные части',
			responsibilityArea: 'Район выезда',
			mainInfo: 'Информация',
			personnel: 'Личный состав',
			vehicles: 'Техника',
			edit: 'Редактировать',
			phone: 'Номер телефона',
			address: 'Адрес',
			staffUnits: 'Штатные ед.',
			select: 'Выберите пожарную часть',
			addPhone: 'Добавить номер телефона',
			enterPhone: 'Введите номер телефона',
			enterStaffUnits: 'Введите штатные единицы',
			addStaffUnits: 'Добавить штатные единицы',
			callSigns: 'Позывные',
			warehouse: 'Склад',
			sentries: 'Караулы',
			officerUnits: 'Штатные ед. офицерского состава',
			addOfficerUnits: 'Добавить еденицы офицерского состава',
			enterOfficerUnits: 'Введите количество',
			distanceProperty: 'Еденицы измерения',
			sbis: '1 СБИС',
			firstRank: '1 ранг',
			secondRank: '2 ранг',
			thirdRank: '3 ранг',
			facilities: 'Объекты ПЧ',
			selected: 'Выбрано пожарных частей',
		},
		fireDepFoam: {
			title: 'Огнетушащие вещества',
			capacity: 'Объем',
			foam: 'Вид огнетушащего вещества',
		},
		fireDepGasMask: {
			title: 'СИЗОД',
			gasMask: 'Тип СИЗОД',
		},
		gasMask: {
			title: 'Тип СИЗОД',
			plural: 'Типы СИЗОД',
		},
		fireDepPersonnel: {
			user: 'Пользователь',
			address: 'Адрес',
			gasMask: 'Тип СИЗОД',
			headOrg: 'Организация',
			vehicle: 'Пожарная техника',
			basic: 'Основное',
			fireDep: 'Пожарная часть',
			plural: 'Личный состав',
			title: 'Личный состав',
			add: 'Добавить',
			choseName: 'Выберите ФИО',
			status: 'Статус',
			dayShift: 'Дневная смена',
			responsibleForCalling: 'Отв. за обзвон',
		},
		fireDepSection: {
			title: 'Отдел',
			plural: 'Отделы',
		},
		fireDepPosition: {
			title: 'Должность',
			plural: 'Должности',
			choosePosition: 'Выберите должность',
			positionCode: 'Строевая',
		},
		fireDepPositionCode: {
			title: 'Должность (строевая)',
			plural: 'Должности (строевая)',
		},
		fireDepRank: {
			title: 'Звание',
			plural: 'Звания',
			chooseRank: 'Выберите звание',
			officers: 'Офицерский состав',
		},
		fireDepVehicle: {
			fuelValue: 'Объем топлива',
			actualFuelValue: 'Факт. объем топлива',
			endDate: 'Дата выхода',
			foamCapacity: 'Пена, объем',
			actualFoamValue: 'Факт.объем пены',
			feature: 'Характеристики',
			additional: 'Доп. информация',
			contacts: 'Контакты',
			fireDep: 'Пожарная часть',
			callSign: 'Позывной',
			passport: 'ПТС',
			pump: 'Насос',
			cisternBulk: 'Емкость, в кубометрах',
			stateNumber: 'Гос. номер',
			stateNumberMiss: 'Гос. номер отсутствует',
			miss: 'отсутствует',
			plural: 'Техника',
			title: 'Пожарная техника',
			brand: 'Марка',
			type: 'Тип',
			year: 'Год выпуска',
			foam: 'Пена, фактический объем',
			name: 'ГАСИ',
			chooseType: 'Выберите год выпуска',
			specialType: 'Спец.тип',
			fireDepShort: 'ПСЧ',
			status: 'Статус',
		},
		fireDepVehicleSuit: {
			suit: 'Тип костюма',
			amount: 'Количество',
			title: 'Защитные костюмы',
			add: 'Добавить костюм',
			glonass: 'Глонасс',
		},
		fireDepVehicleHydraulicTool: {
			title: 'ГАСИ',
			add: 'Добавить ГАСИ',
		},
		fireDepVehicleBrand: {
			title: 'Марка пожарной техники',
			plural: 'Марки пожарной техники',
			chooseBrand: 'Выберите марку',
		},
		fireDepVehicleType: {
			title: 'Тип пожарной техники',
			plural: 'Типы пожарной техники',
			special: 'Спецтехника',
			chooseType: 'Выберите тип',
			specialType: 'Вид техники',
			vehicleCode: 'Строевая',
		},
		fireDepSpecialVehicleType: {
			plural: 'Специальные виды техники',
		},
		fireDepVehicleTypeCode: {
			title: 'Тип пожарной техники (строевая)',
			plural: 'Типы пожарной техники (строевая)',
		},
		fireDepType: {
			title: 'Вид ПЧ',
			plural: 'Виды ПЧ',
		},
		emergencyRiskCategory: {
			title: 'Категория рисков',
			plural: 'Категории рисков',
		},
		emergencySituationPlanStatus: {
			title: 'Статус плана ЧС',
			plural: 'Статусы плана ЧС',
		},
		emergencySituationPlanResource: {
			title: 'Силы и средства плана ЧС',
			title_short: 'Силы и средства',
			personnelCount: 'Личный состав',
			vehicleCount: 'Техника',
			department: 'Подразделение',
			vehicle: 'Техника',
			vehicleType: 'Тип техники',
		},
		emergencySituationPlanResourceTier: {
			title: 'Эшелон сил и средств плана ЧС',
			plural: 'Эшелоны сил и средств плана ЧС',
			title_short: 'Эшелон',
		},
		fireAlarmMessage: {
			newAdpiAlarm: 'Поступило сообщение от датчика АДПИ',
			new112Alarm: 'Поступило сообщение от службы 112',
			title: 'Сообщение о пожарной тревоге',
			plural: 'Сообщения о пожарной тревоге',
			trueMessage: 'Реальная угроза',
			falseMessage: 'Ложное сообщение',
			goToFire: 'Перейти к пожару',
			information: 'Информация',
			incidentCode: 'Код происшествия',
			operatorNumber: 'Номер оператора',
			addressText: 'Адрес (текст, пришедший из 112)',
			reportingPersonName: 'ФИО заявителя',
			reportingPersonPhone: 'Номер телефона заявителя',
			call: 'Вызов',
			newFire: 'Новый пожар',
		},
		fireAlarmMessageStatus: {
			title: 'Статус сообщения пожарной тревоги',
			plural: 'Статусы сообщений пожарной тревоги',
		},
		emergencyDivision: {
			title: 'Подразделение',
			plural: 'Подразделения',
			contacts: 'Контакты',
		},
		emergencyDivisionGroup: {
			title: 'Группа подразделения',
			plural: 'Группы подразделений',
		},
		fireDepPersonnelStatus: {
			title: 'Статус личного состава',
			plural: 'Статусы личного состава',
			chooseStatus: 'Выберите статус',
		},
		fireAlarmMessageSource: {
			title: 'Источник сообщения пожарной тревоги',
			plural: 'Источники сообщения пожарной тревоги',
		},
		protectiveSuit: {
			title: 'Защитный костюм',
			plural: 'Защитные костюмы',
		},
		hydraulicTool: {
			title: 'Гидравлические аварийно-спасательные инструмент',
			plural: 'Гидравлические аварийно-спасательные инструменты',
		},
		municipalityStatus: {
			title: 'Статус готовности МО',
			plural: 'Статусы готовности МО',
		},
		hydrant: {
			title: 'Гидрант',
			plural: 'Гидранты',
			number: 'Номер',
			working: 'Исправен',
			notWorking: 'Неисправен',
			workingPlural: 'Исправные',
			notWorkingPlural: 'Неисправные',
			address: 'Адрес',
			reference: 'Ориентир',
			owner: 'Ведомственная принадлежность',
			type: 'Вид',
			intake: 'Заборное устройство',
			reason: 'Причина неисправности',
		},
		emergencySituationPlanMemberStatus: {
			title: 'Статус участника согласования ЧС',
			plural: 'Статусы участника согласования ЧС',
		},
		adpiDeviceLog: {
			title: 'История изменения статуса АДПИ',
			statusChanged: 'Статус АДПИ изменен на ',
			statusRemoved: 'Cтатус АДПИ убран ',
			statusNotSet: 'Cтатус АДПИ не задан ',
		},
		emergencySituationPlanMemberRole: {
			title: 'Роль участника согласования ЧС',
			plural: 'Роли участника согласования ЧС',
		},
		fireDepVehicleCallSign: {
			title: 'Позывной пожарной техники',
			plural: 'Позывные пожарной техники',
			fireDep: 'Пожарная часть',
		},
		fireDepStatus: {
			plural: 'Статусы ПСЧ для 112',
			title: 'Статус ПСЧ для 112',
			fire: 'Пожар',
			lie: 'Ложный',
			shortCircuit: 'КЗ',
			foodBurning: 'Пригорание пищи',
			bombThreat: 'Угроза взрыва',
			givingHelp: 'Оказание помощи',
			rescueWork: 'АСР',
			redirect: 'Переадресация',
			double: 'Двойник',
		},
		adpiDevice: {
			basic: 'Основная информация',
			add: 'Добавить пожароопасный объект',
			title: 'Датчик АДПИ',
			model: 'Модель АДПИ',
			type: 'Тип',
			phoneNumber: 'Телефонный номер',
			plural: 'Реестры АДПИ',
			status: 'Статус АДПИ',
			address: 'Адрес',
			organization: 'Организация',
			contacts: 'Контакты',
			organizationNumber: 'Номер телефона организации',
			dateAndTime: 'Дата и время',
			changeHistory: 'История изменения статуса АДПИ',
		},
		techFireReportSource: {
			title: 'Источник сообщения о пожаре',
			plural: 'Источники сообщения о пожарах',
		},
		techFireWayBill: {
			title: 'Путевой лист',
			plural: 'Путевые листы',
			detectTime: 'Дата/время обнаружения',
			reportTime: 'Дата/время сообщения',
			source: 'Источник сообщения',
			falseAlarm: 'Ложное сообщение',
			trueAlarm: 'Реальная угроза',
			noConsidShift: 'Не учитывать смену',
			vehicleNoConsidShift: 'Техника без учета смены',
			addFireDep: 'Добавить пожарную часть',
			noVehicles: 'Не будет добавлена в путевой лист',
		},
		techFireSentry: {
			title: 'Караул (общий)',
			plural: 'Караулы (общие)',
		},
		techFireEmergencyCode: {
			plural: 'Коды происшествий по 112',
			title: 'Код происшествия по 112',
		},
		fireDepSentry: {
			title: 'Караул',
			plural: 'Караулы',
			noFireDep: 'Не выбрана пожарная часть',
			firstHint: 'Выберите пожарную часть',
			personnelHint: 'Чтобы составлять личный состав и технику для нового караула, нужно сохранить запись.',
			create: 'Создать караул',
			globalSentry: 'Общий караул',
			selectGlobalSentry: 'Выбрать общий караул',
			customName: 'Свое название',
			setCustomName: 'Задать свое название',
			back: 'К списку караулов',
			units: 'Штатные единицы',
			select: 'Выберите караул',
		},
		waterSupply: {
			number: 'Номер',
			title: 'Источник воды',
			plural: 'Источники воды',
			division: 'Подразделение',
			typeName: 'Вид/диаметр',
			ownership: 'Принадлежность',
			intake: 'Заборное устройство',
			reference: 'Ориентир',
			working: 'Исправен',
			address: 'Адрес',
			comment: 'Комментарий',
			depName: 'Название пожарной части',
			volume: 'Объём',
			defectCause: 'Причина неисправности',
			name: 'Название',
			add: 'Добавить источник воды',
			type: 'Тип',
			dateLastCheck: 'Дата последней проверки',
			addition: 'Дополнительно',
			scheduledInspectionDate: 'Дата плановой проверки',
			identifiedFault: 'Фиксация неисправности',
			remediationOrganization: 'Организация устраняющая неисправность',
			overdueInspection: 'Прострочена проверка',
			inspectorFullName: 'ФИО проверяющего',
			inspectorPosition: 'Должность проверяющего',
			inspectorOrganization: 'Организация проверяющего',
			fireDepPersonnel: 'Проверяющий из личного состава',
			inspectionDateFrom: 'Дата плановой проверки c',
			inspectionDateTo: 'Дата плановой проверки по',
			fromListOfPersonnel: 'Выбрать из списка личного состава',
			manualInspector: 'Заполнить проверяющего вручную',
		},
		waterSupplyType: {
			title: 'Тип источника воды',
			plural: 'Типы источника воды',
		},
		waterSupplyDivision: {
			title: 'Подразделение источника воды',
			plural: 'Подразделения источника воды',
		},
		techFireShift: {
			title: 'Смена',
			plural: 'Смены',
		},
		fireDepShift: {
			title: 'Смена',
			plural: 'Смены',
			submit: 'Заступить на смену',
			readyToLeave: 'Готов к выезду',
			date: 'Дата/время',
			dispatcher: 'Диспетчер',
			mainDispatch: 'Главный диспетчер',
			standIn: 'Подменный диспечтер',
			substituteDispatcher: 'Подменный диспетчер',
			standInSection: 'Отдел подменного диспетчера',
			standInPosition: 'Должность подменного диспетчера',
			guardHead: 'Начальник караула',
			reset: 'Сбросить (главный диспетчер)',
			reserve: 'Резерв',
			confirmReset: 'Сбросить смену пожарной части?',
			readeToLeaveNotification: 'Готов к выезду - ',
		},
		mapLayerHelipad: {
			title: 'Вертолетная площадка',
			plural: 'Вертолетные площадки',
		},
		mapLayerRoad: {
			title: 'Автомобильная дорога',
			plural: 'Автомобильные дороги',
		},
		mapLayerRailway: {
			title: 'Железная дорога',
			plural: 'Железные дороги',
		},
		roadObject: {
			title: 'Объект на автомобильной дороге',
			plural: 'Объекты на автомобильных дорогах',
		},
		waterTransportType: {
			title: 'Тип водного транспорта',
			plural: 'Типы водного транспорта',
		},
		emergencySituationPlanVehicleType: {
			title: 'Тип техники (ЧС)',
			plural: 'Типы техники (ЧС)',
		},
		mapLayerPier: {
			title: 'Причал',
			plural: 'Причалы',
		},
		mapLayerHydraulicStructure: {
			title: 'Гидротехническое сооружение',
			plural: 'Гидротехнические сооружения',
		},
		mapLayerGasPipeline: {
			title: 'Магистральный газопровод',
			plural: 'Магистральные газопроводы',
		},
		mapLayerOilPipeline: {
			title: 'Магистральный нефтепровод',
			plural: 'Магистральные нефтепроводы',
		},
		mapLayerRASCO: {
			title: 'РАСЦО',
			plural: 'РАСЦО',
		},
		techFireReport: {
			techfaulty: 'Неисправная техника',
			'digital-notefree': 'Цифровая записка ЛС - Вольники',
			ls5pso: 'Цифровая записка ЛС - 5ОФПС',
			'112list': 'Список по 112',
			'112statistic': 'Статистика по 112',
			plural: 'Отчеты',
			fuel: 'Топливо',
			lsogps40: 'Цифровая записка ЛС - Вольники',
			saveTemplate: 'Сохранить шаблон',
			sign1: 'Подпись 1',
			sign2: 'Подпись 2',
			sign3: 'Подпись 3',
			header: 'Шапка',
			theOrderFireDeps: 'Очередность вывода Пожарных частей пожарно-спасательного гарнизона',
			title: 'Заголовок',
			date: 'Дата формирования',
			summarylist: 'Сводная ведомость',
			preview: 'Предпросмотр',
			generate: 'Генерация отчета',
			print: 'Печать',
			appg: 'Сведения об оперативной обстановке',
			journalOvernightStayPeople: 'Журнал учета людей на объектах с ночным пребыванием людей',
			infoAboutFireProtectionUnits: 'Сведения о подразделениях пожарной охраны',
			infoWorkFireProtectionUnitsAnalysis: 'Сведения о подразделениях пожарной охраны (анализ)',
			numberOfEventsHeld: 'Количество проведенных мероприятий',
			summaryTableOfWorkFire: 'Сводная таблица работы пожарно-спасательных подразделений',
			infoVisitsFireRescueUnits: 'Сведения о выездах пожарно-спасательных подразделений',
			infoWorkFireProtectionUnits: 'Сведения о работе подразделений пожарной охраны',
			InfoFireService: 'Сведения о службе ПО',
		},
		requestPlan: {
			title: 'Оперативная информация',
			plural: 'Оперативная информация',
		},
		techFireError: {
			noDateAndTime: 'Нет даты/времени обнаружения/сообщения',
			noFireDep: 'У объекта не назначена ответственная пожарная часть',
			noGlobalShift: 'На эту дату нет общей смены',
			noFireDepShift: 'Пожарная часть не заступила на смену',
			shiftWithNoVehicles: 'В смене пожарной части нет техники',
		},
		techFireOrganization: {
			parent: 'Родительская организация',
			title: 'Организация',
			plural: 'Организации',
			staffUnits: 'Штат ед.',
			officerUnits: 'Офицеры ед.',
			supervisorUnits: 'Руководство ед.',
			supervisorOfficerUnits: 'Руководство офицеры ед.',
			OOSPandPUnits: 'ООСПиП штат ед.',
			OOSPandPOfficerUnits: 'ООСПиП офицеры ед.',
			laborProtectionUnits: 'Служба охраны труда штат ед.',
			laborProtectionOfficerUnits: 'Служба охраны труда офицеры ед.',
			isGarrison: 'Является гарнизоном',
		},
		river: {
			title: 'Река',
			plural: 'Реки',
		},
		requestPeriod: {
			title: 'Период',
			plural: 'Периоды',
		},
		requestPeriodDate: {
			title: 'Период (даты)',
			plural: 'Период (даты)',
		},
		requestCategory: {
			title: 'Категория',
			plural: 'Категории',
		},
		operInfo: {
			title: 'Оперативка',
			plural: 'Оперативка',
			plan: 'План',
			ship: 'Отгружено',
			deliver: 'Доставлено',
			organization: 'Предприятие',
			lastEditDate: 'Последнее изменение',
			transitPoints: 'Транзитные пункты',
			deposition: 'Депонация',
		},
		fireDepNote: {
			title: 'Строевая записка',
			number: '№',
			fireDeps: 'Наименования подразделений',
			location: 'Место дислокации',
			vehicles: {
				title: 'Пожарная техника',
				active: {
					title: 'Боевой расчет',
					main: {
						title: 'Основная',
						col4: 'АЦ',
						col5: 'АЦЛ',
						col6: 'АНР',
						col7: 'АА',
						col8: 'АП',
						col9: 'Пожарный поезд',
						col10: 'ПНС',
						col11: 'Прочие',
					},
					special: {
						title: 'Спец.техника',
						col12: 'АЛ',
						col13: 'АКП',
						col14: 'АТП',
						col15: 'АР',
						col16: 'АС',
						col17: 'АШ',
						col18: 'Прочие',
					},
				},
				reserve: {
					title: 'Резерв',
					col19: 'АЦ',
					col20: 'АЦЛ',
					col21: 'АНР',
					col22: 'АА',
					col23: 'ПНС',
					col24: 'АР',
					col25: 'АЛ',
					col26: 'АКП',
					col27: 'Прочие',
				},
				maintenance: {
					title: 'Не в расчете',
					col28: 'ТО 1',
					col29: 'ТО 2',
					col30: 'Ремонт',
					col31: 'Хранилище, консерв.',
				},
				support: {
					title: 'Вспомог. тех-ка',
					col32: 'Автобус',
					col33: 'Автоводовозка',
					col34: 'Грузовая',
					col35: 'Прочие',
				},
			},
			gasMasks: {
				title: 'СИЗОД',
				col36: 'Б/р',
				col37: 'Резерв',
			},
			suits: {
				title: 'Защит. костюмы',
				col38: 'Л-1',
				col39: 'ТОК',
				col40: 'Прочие',
			},
			tools: {
				title: 'ГАСИ',
				col41: 'Б/р',
				col42: 'Резерв',
			},
			personnel: {
				title: 'Личный состав',
				col43: 'По штату',
				col44: 'По списку',
				col45: 'На лицо',
				active: {
					title: 'Боевой расчет',
					col46: 'Всего',
					col47: 'НДС, НК, РДС',
					col48: 'ПНДС, ПНК',
					col49: 'КО',
					col50: 'Водитель',
					col51: 'Пожарные',
					col52: 'ГДЗС',
				},
				col53: 'Диспетчер',
				col54: 'Постовой (невыездной)',
				missing: {
					title: 'Отсутствует',
					col55: 'Всего',
					col56: 'Отпуск',
					col57: 'Болезнь',
					col58: 'Командировка',
					col59: 'Прочие',
					col60: 'Некомплект',
				},
			},
			foams: {
				title: 'Огнетушащие вещества',
				active: {
					title: 'В боевом расчете',
					col61: 'Пенообразователь',
					col62: 'Порошок',
				},
				reserve: {
					title: 'В резеврной технике',
					col63: 'Пенообразователь',
					col64: 'Порошок',
				},
				store: {
					title: 'На складе',
					col65: 'Пенообразователь',
					col66: 'Порошок',
				},
			},
			chief: 'Ф.И.О. начальника караула (начальника дежурной смены) подразделения',
		},
		dateRangePicker: {
			startDate: 'Дата начала',
			endDate: 'Дата конца',
			period: 'Период',
		},
		contact: {
			title: 'Контакт',
			plural: 'Контакты',
		},
		field: {
			empty: 'Нет',
		},
		firePlanReserve: {
			name: 'Вид пожарной техники, оборудования',
			count: 'Количество, единица измерения',
			location: 'Местонахождение резерва (населенный пункт, адрес)',
			responsiblePersons: 'Лица, ответственные за формирование и сохранность резерва (фамилия, имя, отчество, контактные данные)',
			organization: 'Организация',
		},
		firePlanResponsiblePerson: {
			title: 'Лицо, ответственное за органищацию тушения пожара',
			plural: 'Лица, ответственные за органищацию тушения пожара',
			tab: 'Отвественные лица',
			experience: 'Опыт работы',
			description: 'Примечание',
			forestry: 'Лесничество',
			municipality: 'Муниципальное образование',
			code: {
				PROTECTION: 'Ответственные лица за организацию охраны лесов от пожаров',
				EXTINGUISHING_FORESTRY: 'Ответственные лица за организацию тушения лесных пожаров на территории лесничества',
				EXTINGUISHING_MO: 'Ответственные лица за организацию тушения лесных пожаров на МО',
				EXTINGUISHING_HEAD_LPF: 'Руководители тушения лесных пожаров ЛПФ',
				EXTINGUISHING_HEAD_FORESTRY: 'Руководители тушения лесных пожаров из числа работников лесничеств и МО',
			},
		},
		permafrost: {
			map: 'Карта',
			tubeMenu: 'Температурные трубки',
			marksMenu: 'Нивелир. марки',
			marksFull: 'Нивелировочные марки',
			objectsMenu: 'Объекты',
			measurementsMenu: 'Замеры',
			measurementsCount: 'Кол-во замеров:',
			depth: 'Глубина (м)',
			sts: 'Стс (м)',
			number: 'Номер',
			buildings: 'Сооружение',
			approved: 'Утверждено',
			edit: 'Редактировать',
			mark: 'Отметка',
			objects: {
				cadastralNumber: 'Кадастровый номер',
				administrativeRegion: 'Административный район',
				county: 'Муниципальный район',
				city: 'Населеный пункт',
				quarter: 'Квартал',
				street: 'Улица',
				house: '№ дома',
				building: 'Корпус',
				address: 'Адрес',
				objectInfo: 'Сведения об объекте',
				name: 'Название объекта',
				caseNumber: '№ Дела УГСЖН РС(Я)',
				OMKNumber: 'Номер ОМК',
				location: 'Расположение',
				builder: 'Застройщик',
				customer: 'Заказчик',
				contractor: 'Ген. Подрядчик',
				designer: 'Проектировщик',
				operatingOrganization: 'Эксплуатирующая организация',
				entryDate: 'Дата ввода',
				constructionStartDate: 'Дата начала строительства',
				constructionStage: 'Стадия строительства',
				houseStatus: 'Статус дома',
				addInfo: 'Дополнительные сведения',
				inspector: 'Инспектор',
				floorNumber: 'Этажность',
				note: 'Примечание по содержанию',
				additionalInfo: 'Дополнительная информация',
				energyEfficiencyClass: 'Класс энергетической эффективности',
				coolingElementsNumber: 'Количество охлаждающих элементов',
				foundationType: 'Тип фундамента',
				constructive: 'Конструктив',
				designTemperature: 'Расчетная температура',
				permissibleDraft: 'Допустимая осадка',
				minTemperature: 'Диапазон мин. темп.',
				maxTemperature: 'Диапазон макс. темп.',
			},
			temperatureTube: {
				title: 'Температурная трубка',
				maxDepth: 'Максимальная глубина трубки',
				pile: 'Свая',
				lastDate: 'Дата замеров:',
			},
			levelingMarks: {
				title: 'Нивелировочная марка',
				heightSystem: 'Система высот',
			},
		},
		permafrostTemperatureTubeBuilding: {
			title: 'Сооружение',
			plural: 'Сооружения',
		},
		permafrostTemperatureTubeMaxDepth: {
			title: 'Максимальная глубина трубки',
			plural: 'Максимальная глубина трубки',
		},
		permafrostTemperatureTubeStatus: {
			title: 'Статус',
			plural: 'Статусы',
		},
		permafrostTemperatureTubeType: {
			title: 'Тип',
			plural: 'Типы',
		},
		permafrostObjectAdministrativeRegion: {
			title: 'Административный регион',
			plural: 'Административные регионы',
		},
		permafrostObjectConstructionStage: {
			title: 'Стадия строительства',
			plural: 'Стадии строительства',
		},
		permafrostObjectConstructive: {
			title: 'Конструктив',
			plural: 'Конструктивы',
		},
		permafrostObjectEnergyEfficiencyClass: {
			title: 'Класс энергетической эффективности',
			plural: 'Классы энергетической эффективности',
		},
		permafrostObjectFoundationType: {
			title: 'Тип фундамента',
			plural: 'Типы фундамента',
		},
		permafrostObjectHouseStatus: {
			title: 'Статус дома',
			plural: 'Статусы дома',
		},
		permafrostObjectInspector: {
			title: 'Инспектор',
			plural: 'Инспекторы',
		},
		permafrostOrganization: {
			title: 'Организация',
			plural: 'Организации',
			approval: 'Согласие на эл-ное взаимодействие',
		},
		permafrostObjectQuarter: {
			title: 'Квартал',
			plural: 'Кварталы',
		},
		permafrostLevelingMarkHeightSystem: {
			title: 'Система высот',
			plural: 'Системы высот',
		},
		permafrostLevelingMarkStatus: {
			title: 'Статус',
			plural: 'Статусы',
		},
		permafrostLevelingMarkType: {
			title: 'Тип',
			plural: 'Типы',
		},
		techFireBlockedRoad: {
			title: 'Перекрытая дорога',
			plural: 'Перекрытые дороги',
			dateClose: 'Дата перекрытия',
			dateOpen: 'Дата открытия',
			reason: 'Причина',
			info: 'Дополнительная информация',
			willClose: 'Скоро будут перекрыты',
			createdAt: 'Дата создания',
			updatedAt: 'Обновлено',
		},
		countOnPage: 'Кол-во на странице:',
		noteSettings: {
			headNote: 'Заголовок',
			dutyShift: {
				title: 'Дежурная смена',
				position: 'Должность',
				fio: 'ФИО',
			},
			responsiblePerson: {
				title: 'Ответственное лицо',
				position: 'Должность',
				fio: 'ФИО',
			},
		},
		noneAccessPage: 'Вам не разрешено просматривать данную страницу',
	},
};
