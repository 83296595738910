import React from 'react';
import { Card } from 'components';
import ListIcon from 'img/icons/techfires/card/fi_list.svg';
import t from 'i18n';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Tabs, Tab, Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import FireObjectInfo from './FireObjectInfo';
import WayBillEdit from './Edit';
import FieldChecker from '../../../../../helpers/fieldChecker';

@inject('fireStore')
@observer
export default class Waybill extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	@observable collapsed = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		const { id } = this.props.fireStore;
		this.records = await store.model.TechFireWayBill.find({
			where: {
				fireId: id,
			},
			order: 'id asc',
		});
		this.isLoading = false;
	};

	onSave = async (record) => {
		await this.init();
		store.route.push({ path: `${this.props.path}/waybills/${record.id}` });
	};

	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('wayBill', areFieldsFilled);
	};

	handleCollapseToggle = () => {
		this.collapsed = !this.collapsed;
	};

	render() {
		if (this.isLoading) return <Loader />;
		const { path } = this.props;
		const { fire, id } = this.props.fireStore;
		const waybilllPath = `${path}/waybills`;
		if (!this.isLoading && this.records.length > 0) store.route.push({ path: `${waybilllPath}/${this.records[0]?.id}` });
		return (
			<FieldChecker fields={[this.records.length > 0]} onStatusChange={this.handleStatusChange}>
				{(areFieldsFilled) => (
					<Card
						title={t('techFireWayBill.plural')}
						icon={<ListIcon />}
						isValid={areFieldsFilled}
						collapsible
						collapsed={this.collapsed}
						onCollapseToggle={this.handleCollapseToggle}
					>
						<div className='techfire-waybills'>
							<Row className='card-row'>
								<Tabs>
									<Route path={waybilllPath} exact>
										<Redirect to={waybilllPath + `/${this.records[0]?.id || 'create'}`} />
									</Route>
									{this.records.map((record, i) => {
										const props = {
											key: record.id,
											path: waybilllPath + `/${record.id}`,
											title: `${t('techFireWayBill.title')} #${i + 1}`,
										};
										return <Tab {...props} />;
									})}
									<Tab path={waybilllPath + '/create'} title={t('add')} />
									<FireObjectInfo fire={fire} />
									<Switch>
										<Route path={`${waybilllPath}/create`} render={(routeProps) => <WayBillEdit onSave={this.onSave} fireId={id} {...routeProps} />} />
										<Route path={`${waybilllPath}/:wayBillId`} render={(routeProps) => <WayBillEdit {...routeProps} fireId={id} />} />
									</Switch>
								</Tabs>
							</Row>
						</div>
					</Card>
				)}
			</FieldChecker>
		);
	}
}
