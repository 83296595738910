import classNames from 'classnames';
import React from 'react';
import { PopconfirmFaTrashIcon } from 'components';
import './card.scss';
import ArrowIcon from 'img/icons/techfires/card/arrow.svg';

export const Card = ({ children, title, icon, className, onDelete, collapsible, collapsed, onCollapseToggle }) => {
	const _className = classNames(className, 'card');

	return (
		<div className={_className}>
			<div className='card-title'>
				<div className='icon-title'>
					{icon}
					<h2>{title}</h2>
				</div>
				<div className='card-actions'>
					{onDelete && <PopconfirmFaTrashIcon onConfirm={onDelete} />}
					{collapsible && <ArrowIcon onClick={onCollapseToggle} className={collapsed ? 'collapsed' : ''} />}
				</div>
			</div>
			<div className={classNames('card-content', { collapsed })}>{children}</div>
		</div>
	);
};
