import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FilterLayout from '../FilterLayout';
import t from 'i18n';

@observer
export class ShipsFilter extends React.Component {
	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	render() {
		const { layer } = this.props;

		return <FilterLayout layer={layer} title={t('ship.plural')} />;
	}
}
