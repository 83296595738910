import React from 'react';
import { observer, inject } from 'mobx-react';

import { Form, Row, Field, DatePicker, Button, RecordSelect, Pager, Table, Column, RelationSelect } from '@smartplatform/ui';
import { DeleteButton } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { ListStore, TechFireAddressSearch } from 'components';
import t from 'i18n';
import store from 'client/store';
import { observable } from 'mobx';
import { getPrefixByCountyName } from 'client/tools/getPrefixByCountyName';

@inject('store')
@observer
export default class PlanVisitInfo extends React.Component {
	@observable techMovements;
	@observable moveAllowed = null;
	@observable hasSubType = false;

	constructor(props) {
		super(props);
		this.wayBillTab = this.props.location.pathname.includes('waybills');
		this.requiredFields = ['dateOfDeparture', 'fireDepId'];
		this.vehicleStore = new ListStore({ path: props.path, fields: {}, model: store.model['FireDepVehicle'] });
		this.filterMovements = [];
		const { firePlanVisit } = this.props.store;
		this.visitId = firePlanVisit.id;
		this.moveAllowed = store.model.roles.filter((role) => ['admin', 'tfires_main'].includes(role.name)).length > 0;
		this.hasSubType = ['PTZ', 'PTU'].includes(firePlanVisit.visitType?.code);
		this.init();
	}

	init = async () => {
		this.techMovements = await store.model.TechFireUnitMovement.find({ where: { techFireVisitId: this.visitId, returnedBack: false } });
		if (this.techMovements.length > 0 && this.visitId) {
			this.filterMovements = this.techMovements.map((item) => {
				const container = {};
				container['id'] = item.vehicleId;
				return container;
			});
			this.updateFilter();
		} else {
			this.techMovements = null;
		}
	};

	componentDidMount() {
		this.vehicleStore.setPerPage();
		this.updateFilter();
	}

	updateFilter = () => {
		const filter = {};
		filter.where = { or: this.filterMovements };
		this.filter = filter;
		this.vehicleStore.updateFilters(this.filter);
	};

	returnTechniques = async (firePlanVisit) => {
		for (let vehicle of this.techMovements) {
			await store.model.FireDepVehicle.updateAll({ id: vehicle.vehicleId }, { fireDepId: firePlanVisit.fireDepFromId });
		}
		await store.model.TechFireUnitMovement.updateAll({ techFireVisitId: firePlanVisit.id }, { returnedBack: true });
		this.init();
	};

	onChangeType(fireVisit) {
		this.type = fireVisit.visitType;
		this.subtype = null;
		fireVisit.visitSubType = null;
	}

	render() {
		const { firePlanVisit, onChange, requiredFieldsFilled, saveFire, goBack, saveUnitMovement, deleteFire, onObjectChange, onObjectReset } = this.props.store;
		const { tableProps, totalCount, perPage, page } = this.vehicleStore;

		if (firePlanVisit.visitType) {
			firePlanVisit.visitType.code === 'RELOCATE' ? (this.relocationOfTechnique = true) : (this.relocationOfTechnique = false);
			this.relocationOfTechnique
				? (this.requiredFields = ['dateOfDeparture', 'fireDepFrom', 'fireDepTo', 'fireDepId'])
				: (this.requiredFields = ['dateOfDeparture', 'fireDepId']);
		}
		const disabledSave = !requiredFieldsFilled(this.requiredFields);

		const hasSubType = ['PTZ', 'PTU'].includes(firePlanVisit.visitType?.code);
		this.noMovement = this.techMovements === null ? true : false;
		// const disabledForm = !firePlanVisit.fireDepId ? false : !store.isAdmin;

		return (
			<>
				<Button
					onClick={() => {
						store.route.push({ path: this.props.store.path });
					}}
				>
					<FontAwesomeIcon icon={faArrowLeft} />
				</Button>
				<Form record={firePlanVisit} noSave noDelete>
					<h3>{t('techFire.firesPlanVisits.title')}</h3>
					<Field relation='garrison' property='name' label={t('garrison')} disabled>
						<RecordSelect isRequired filter={{ where: { isGarrison: true } }} />
					</Field>
					<Row>
						<Field
							relation='visitType'
							property='name'
							label={t('techFire.firesPlanVisits.visitType')}
							disabled={this.relocationOfTechnique && this.noMovement}
						>
							<RecordSelect model={store.model.TechFireVisitType} onChange={() => this.onChangeType(firePlanVisit)} />
						</Field>
						<Field
							relation='visitSubType'
							property='name'
							label={t('techFire.firesPlanVisits.visitSubType')}
							filter={{ where: { parentId: firePlanVisit.visitType?.id } }}
							disabled={!hasSubType}
						/>
					</Row>
					<Row>
						<Field property='createdAt' label={t('techFire.firesPlanVisits.dateOfCreation')} disabled />
						{/* <Field relation='fireDep' property='name' label={t('techFireAddress.fireDep')} disabled={firePlanVisit.object?.fireDep !== null} /> */}
					</Row>
					{!this.relocationOfTechnique ? (
						<>
							<Field property='objectManual' label={t('techFire.firesPlanVisits.addressManual')} />
							<Field relation='object' label={t('techFire.firesPlanVisits.address')} onChange={onChange('object')}>
								<TechFireAddressSearch onChange={onObjectChange} onReset={onObjectReset} prefix={getPrefixByCountyName(store.userCounty?.name)} />
							</Field>
							<Field relation='fireDep' property='name' label={t('techFireAddress.fireDep')} isRequired>
								<RelationSelect showValue={firePlanVisit.fireDep ? firePlanVisit.fireDep.name : '-'} onChange={onChange('fireDep')} />
							</Field>
						</>
					) : (
						<Row>
							<Field relation='fireDepFrom' property='name' label={t('techFireAddress.fireDepFrom')} isRequired={this.relocationOfTechnique} disabled />
							<Field relation='fireDepTo' property='name' label={t('techFireAddress.fireDepTo')} isRequired={this.relocationOfTechnique} disabled />
						</Row>
					)}
					<Row>
						<Field property='dateOfDeparture' label={t('techFire.firesPlanVisits.dateOfDeparture')}>
							<DatePicker showTimeSelect />
						</Field>
						<Field property='dateOfReturn' label={t('techFire.firesPlanVisits.dateOfReturn')}>
							<DatePicker showTimeSelect />
						</Field>
					</Row>
					<Row>
						<Field property='resultOfVisit' label={t('techFire.firesPlanVisits.resultOfVisit')}>
							<textarea rows={3} />
						</Field>
					</Row>
				</Form>
				{this.relocationOfTechnique &&
					(this.techMovements ? (
						<>
							<h1>{t('fireDepVehicle.plural')}</h1>
							<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
							<Table {...tableProps} onRowClick={null}>
								<Column property='id' label={t('id')} width={30} />
								<Column property='stateNumber' label={t('fireDepVehicle.stateNumber')} width={80} />
								<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} width={130} />
								<Column relation='fireDep' property='name' label={t('fireDepVehicle.fireDep')} width={130} />
							</Table>
						</>
					) : (
						<h3>{t('techFire.firesPlanVisits.returnedBack')}</h3>
					))}
				{!this.wayBillTab && (
					<div className='main-actions'>
						{!this.relocationOfTechnique ? (
							<Button onClick={saveFire} variant='primary' disabled={disabledSave}>
								{t('save')}
							</Button>
						) : (
							<Button onClick={saveUnitMovement} variant='primary' disabled={disabledSave}>
								{t('save')}
							</Button>
						)}
						<Button onClick={goBack}>{t('cancel')}</Button>
						{this.relocationOfTechnique && this.techMovements && this.moveAllowed && (
							<Button onClick={() => this.returnTechniques(firePlanVisit)} variant='primary' disabled={disabledSave}>
								{t('techFire.firesPlanVisits.returnTechnique')}
							</Button>
						)}
						{!this.relocationOfTechnique && <DeleteButton text={t('techFire.firesPlanVisits.delete')} onConfirm={deleteFire} />}
					</div>
				)}
			</>
		);
	}
}
