export default {
	translation: {
		formation: 'Formation',
		reject: 'Reject',
		open: 'Open',
		cancelEdit: 'Cancel edit',
		fill: 'Fill',
		dashboard: 'Dashboard',
		zoom: 'Zoom',
		editAvatar: 'Edit avatar',
		addAvatar: 'Add avatar',
		return: 'Return',
		lastEditDate: 'Last modified',
		upload: 'Upload',
		profile: 'Profile',
		back: 'Back',
		administration: 'Administration',
		checkAll: 'Check all items',
		uncheckAll: 'Uncheck all items',
		nameLat: 'Lat name',
		departureDate: 'Departure date',
		returnDate: 'Departure date',
		addComment: 'Add comment',
		accessLevel: 'Access level',
		editComment: 'Edit comment',
		population: 'population',
		downloadReport: 'Download report',
		plannedVolume: 'Planned volume',
		flights: 'Flights',
		result: 'Result',
		note: 'Note',
		garrison: 'Garrison',
		division: 'Division',
		risks: 'Risks',
		peopleCount: 'People count',
		teenagersCount: 'teenagersCount',
		group: 'Group',
		geo: 'Geometry',
		geoPoint: 'Geometry (point)',
		id: 'Id',
		phoneNumber: 'Phone number',
		fireNotifier: 'Fire notifier',
		registries: 'Registries',
		notSave: 'Not save',
		historyLog: 'History log',
		agreement: 'Agreement',
		countShort: 'qnt',
		equipment: 'Equipment',
		measure: 'Measure',
		price: 'Price',
		quantity: 'Quantity',
		fullName_full: 'Fullname',
		fullName: 'Fullname',
		position: 'Position',
		attachFiles: 'Attach files',
		deleteFile: 'Delete file',
		clean: 'Clean',
		type: 'Type',
		number: '№',
		category: 'Category',
		categories: 'Categories',
		createdAt: 'Created At',
		createdRecord: 'Create record',
		selectedStatuses: 'Selected statuses',
		selectStatus: 'Select status',
		selectType: 'Select type',
		selectField: 'Select field',
		search: 'Search',
		color: 'Color',
		add: 'Add',
		accept: 'Accept',
		active: 'Active',
		cleanFilters: 'Clear filters',
		filter: 'Filter',
		week: 'Week',
		quarter: 'Quarter',
		year: 'Year',
		title: 'Situation monitoring',
		name: 'Name',
		noName: 'No name',
		shortName: 'Short name',
		LOGIN_FAILED: 'Wrong login or password',
		LOGIN_WAIT: 'Exceeded the limit of login attempts',
		AUTHORIZATION_REQUIRED: 'Authorization is required',
		description: 'Description',
		create: 'Create',
		save: 'Save',
		longitude: 'Longitude',
		latitude: 'Latitude',
		lonShort: 'lon',
		latShort: 'lat',
		coordinates: 'Coordinates',
		azimuth: 'Azimuth',
		distance: 'Distance',
		metersPerSecond: 'm/s',
		loading: 'Loading...',
		showOnMap: 'Show on map',
		addRecord: 'New record',
		editRecord: 'Edit record',
		copyRecord: 'Copy record',
		update: 'Update',
		send: 'Send',
		sending: 'Sending',
		export: 'Export',
		import: 'Import',
		confirm: 'Confirm?',
		squareKm: 'sq.km',
		km: 'km',
		ga: 'ha',
		all: 'All',
		off: 'Off',
		date: 'Date/Time',
		date_short: 'Date',
		date1: 'Date (from)',
		date2: 'Date (to)',
		age1: 'Age (from)',
		age2: 'Age (to)',
		attachment: 'attachment',
		attachments: 'attachments',
		default: 'Default',
		count: 'Count',
		count_short: 'Count',
		copy: 'Copy',
		mainInfo: 'Main info',
		info: 'Info',
		dateTime: 'Date/time',
		dateFrom: 'from',
		at: 'at',
		downloadExcel: 'Export to excel',
		no: 'no',
		opacity: 'Opacity',
		statuses: 'Statuses',
		recordNotFound: 'Record not found',
		requestIncident: {
			title: 'Incident',
			plural: 'Incidents',
			riverLevel: 'River level information',
			objects: "Information about the company's facilities",
			tracks: 'Tracks',
		},
		requestIncidentType: {
			title: 'Incident type',
			plural: 'Incident types',
		},
		requestIncidentLevel: {
			title: 'Incident level',
			plural: 'Incident levels',
		},
		requestIncidentStatus: {
			title: 'Incident status',
			plural: 'Incident statuses',
		},
		priority: 'Priority',
		admin: {
			title: 'Administration',
			titleShort: 'Admin',
		},
		module: {
			fires: 'Forest fires monitoring',
			covid: 'COVID-19',
			appeals: 'Citizens appeals',
			errands: 'Errands of the Republic Head',
			delivery: 'North delivery',
			emergency: 'Emergency planning',
			techfires: 'Technogenic fires',
			permafrost: 'Permafrost',
		},
		day: {
			sunday: 'Sunday',
			monday: 'Monday',
			tuesday: 'Tuesday',
			wednesday: 'Wednesday',
			thursday: 'Thursday',
			friday: 'Friday',
			saturday: 'Saturday',
		},
		month: {
			january: 'January',
			february: 'February',
			march: 'March',
			april: 'April',
			may: 'May',
			june: 'June',
			july: 'July',
			august: 'August',
			september: 'September',
			october: 'October',
			november: 'November',
			december: 'December',
		},
		validation: {
			REQUIRED_FIELDS: 'Not all required fields are filled',
			REQUIRED_CREWTYPE: 'Crew type is required',
			REQUIRED_EXTINGUISHING_AGENTS_TYPE: 'Extinguishing agents type is required',
			ALREADY_EXIST: 'Record already exist',
			INCORRECT_AIR: 'Wrong Air value',
			INCORRECT_EVACUATED: 'Wrong evacuated value',
		},
		dictionaries: {
			search: 'Find dictionary',
			title: 'Handbook',
			plural: 'Handbooks',
			editMode: 'Edit mode',
			group: {
				title: 'Handbook group',
				norm: 'Reference information and information on regulations',
				fires: 'Fire formation-registry',
				air: 'Aircraft flight',
				weather: 'Weather station',
				other: 'Other',
				region: 'Regional',
				reserves: 'Reserves',
			},
		},
		code: 'Code',
		codeForYasen: 'Code for Yasen',

		externalCode: 'External Code',
		weatherScaleValue: {
			title: 'Weather scale value',
			plural: 'Weather scale values',
			KPO1: 'KPO1',
			KPO2: 'KPO2',
			KPO3: 'KPO3',
			KPO4: 'KPO4',
			startDate: 'Start',
			endDate: 'End',
			temperature: 'Temperature',
			dewPoint: 'Dew point',
			windSpeed: 'Wind speed',
			precipitation: 'Precipitation',
			complexNumber: 'Complex number',
			KPPNesterov: 'KPP (Nesterov)',
			KPONesterov: 'KPO (Nesterov)',
		},
		forestUseType: {
			title: 'Forest use Type',
			plural: 'Forest use Typeы',
		},
		staffPosition: {
			title: 'Staff position',
			plural: 'Staff positions',
		},
		paymentGroup: {
			title: 'Payment group',
			plural: 'Payment group',
		},
		report: {
			search: 'Find report',
			downloadDate: 'Download date',
			title: 'Report',
			plural: 'Reports',
			group: 'Report group',
			type: 'Report type',
			download: 'Download report',
			downloadLO1: 'Download 1LO.Section 1',
			section: 'Section',
			date1: 'Date (from)',
			date2: 'Date (to)',
			inProgress: 'In progress...',
			name: 'Report name',
			summary: 'Summary',
			detailed: 'Detailed',
			first: 'Part 1',
			last: 'Part 2',
			FiresOnCountyAndDate: 'Fires on county and date',
			FireCard: 'Fire card',
			ExtinguishedFires: 'Extinguished fires',
			FiresOnDate: 'Fires on date',
			CumulativeFires: 'Cumulative fires',
			DetailedFireSituation: 'Detailed fire situation',
			FireAct: 'Fire act',
			FireRegistryOnDate: 'Fire registry on date',
			FireByArea: 'Fires by area',
			FireByMonitoringZone: 'Fires by monitoring zone',
			FireByCauseAndWorker: 'Fires by cause and worker',
			LO1Section1: 'LO1 Section 1',
			LO1Section2: 'LO1 Section 2',
			LO1Section3: 'LO1 Section 3',
			LO1Section4: 'LO1 Section 4',
			LO2: 'LO2',
			LO3: 'LO3',
			DetectionMethods: 'Detection methods',
			ExtinguishingCosts: 'Extinguishing costs',
			ExtinguishingVehiclesAndPeoples: 'Extinguishing vehicles and people',
			CityThreat: 'City threat',
			FireAreaCount: 'Fire area',
			RequestLog: 'Request log',
			ForestryHarm: 'Forestry harm',
			FireDangerDiary: 'Fire danger diary',
			InformationAboutIdentifyingPerpetrators: 'Information on identifying the perpetrators of forest fires and bringing persons to justice',
			FlightTimeControl: 'Control of the volume of flight time and financial resources',
			InformationAboutActiveForestFires: 'Information about active forest fires',
			AirRaidExtinguishForestFires: 'Air raid to extinguish forest fires',
			RegisterEliminatedForestFires: 'Register of eliminated forest fires (KhMAO)',
			ReportActiveForestFires: 'Report on active forest fires',
			DepartmentWorkRecordCard: 'Aviation department work record card',
			OperationalSituationInTerritory: 'Operational situation on the territory of the Republic of Sakha (Yakutia)',
			KpoMonthlyReport: 'KPO monthly report',
			ReportOnWorkWithCitizens: 'Report on work with citizens',
			CumulativeSummaryForestFires: 'Cumulative summary of forest fires',
			ReportOnForestFiresInTheTerritory: 'Report on forest fires in the territory',
			CumulativeIndicators: 'Cumulative indicators',
			InformationAboutLargeForestFires: 'Information about large forest fires',
			InformationAboutLargeForestFiresForDate: 'Information about large forest fires for date',
			InformationOnTheOperationOfAircraftForMonitoring: 'Information on the operation of aircraft for monitoring and extinguishing forest fires for date',
			OperationalSituationOnTheTerritoryOfTheSubject: 'Operational situation on the territory of the subject',
			InformationAboutActiveForestFiresForDate: 'Information about active forest fires on the date',
			InformationAboutForestFiresForDate: 'Information about forest fires on the date',
			FireDangerIndicators: 'Fire danger indicators',
			ListOfFlightStaff: 'Aircrew list for the year',
			DistributionFiresByLiquidationAreaAndExtinguishingTime: 'Distribution of fires by liquidation area and extinguishing time',
			InformationAboutWorkOfForestFireFormations: 'Information about the work of forest fire formations',
			JobDetailsAircraftForTheProtectionOfForests: 'Job details aircraft for the protection of forests and reindeer pastures',
			ListOfForestFires: 'List of forest fires',
			EliminatedFiresFoundInTheTotalArea: 'Eliminated fires found in the total area',
			ReportOnTheDetectionAndSuppressionForestFires: 'Report on the detection and suppression of forest fires',
			InformationOnTheDistributionOfTheFireSeasonByClass: 'Information on the distribution of the fire season by class',
			InformationAboutTheEliminationOfForestFire: 'Information about the elimination of a forest fire',
			InformationAboutForestFiresTakingIntoAccountPurposeForests: 'Information about forest fires, taking into account the purpose of forests',
			KeyIndicatorsForAviationProtectionOfForests: 'Key indicators for aviation protection of forests',
			InformationAboutTheWorkOfGroundFireDepartments: 'Information about the work of ground fire departments',
			DistributionOfIndicatorsByDatesOfTheFireHazardPeriod: 'Distribution of indicators by dates of the fire hazard period',
			NumberOfFiresForTheFireSeason: 'Number of fires',
			TotalFireAreaFireHazardPeriod: 'Total fire area',
			ForestAndNonForestAreasFires: 'Forest and non-forest areas of fires',
			KppForTheFireHazardPeriod: 'KPP for the fire hazard period',
			KpoForTheFireHazardPeriod: 'KPO for the fire hazard period',
			DistributionOfIndicatorsByDepartments: 'Distribution of indicators by departments',
			NumberOfFiresByDepartments: 'Number of fires by departments',
			TotalAreaByDepartments: 'Total area by departments',
			ForestAndNonForestAreaByDepartments: 'Forest and non-forest area by departments',
			KpoByDepartments: 'KPO by departments',
			KppByDepartments: 'KPP by departments',
			DistributionOfTheNumberOfFiresByTheirCauses: 'Distribution of the number of fires by their causes',
			FireSituationAnalysis: 'Fire situation analysis',
			InformationAboutTheWorkParachuteAndAirborneFireService:
				'Information about the work of the parachute and airborne fire service to extinguish forest and tundra fires',
			InformationAboutForestFiresAccordingClassificationOfForestFundLands:
				'Information about forest fires according to the classification of forest fund lands according to the purpose of forests and types of fires',
			pivotTableForm: 'Pivot table form',
			riverInformationForm: 'River information form',
			informationFormForDistricts: 'Information form for districts',
			formForTheHeadAndGovernment: 'Form for the head and government',
			formByUlus: 'Form by ulus',
			depositnew: 'Deposit form',
			dailyInformationFormFor: 'Daily information form',
			arcticUlusForm: 'Arctic ulus form',
			districtsEnterprisesRivers: 'Districts enterprises rivers',
			indicators: 'Indicators',
			ratioTo: 'Ratio to',
			forReference: 'For reference',
			codeForAnalysis: 'Code for analysis',
			rivers: 'Rivers',
			year: 'Year',
			yana: 'Yana',
			stsgRivers: 'Stsg Rivers',
			oil: 'Oil',
			terCustomers: 'TER Customers',
		},
		forceAvailability: {
			title: 'Forces and resources',
			plural: 'Forces and resources',
			newDate: 'Record date',
			copyWarning: 'Copy only saved data',
		},
		auth: {
			title: 'Authorization',
			welcome: 'Welcome',
			login: 'Login',
			order: 'Get access',
			insertLogin: 'Enter login',
			insertPassword: 'Enter the password',
			register: 'Registration',
			logout: 'Logout',
			forgotPassword: 'Forgot your password?',
			recover: 'Recover',
			required: 'Required',
			wait: 'Wait for',
			seconds: 'seconds',
			easy: 'Easy',
			fast: 'Fast',
			safely: 'Safely',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'E-mail',
			input: 'Enter your E-mail',
			exist: 'E-mail already exist',
		},
		password: {
			title: 'Password',
			hint: 'Password...',
			input: 'Enter the password',
			new: 'Enter new password',
			repeat: 'Repeat password ',
			newHint: 'If you dont want to change password leave the fields empty',
			empty: 'Enter the password in both fields',
			noMatch: 'Passwords do not match',
			match: 'Passwords match',
		},
		person: {
			firstName: 'First name',
			lastName: 'Last name',
			middleName: 'Middle name',
			fullName: 'Full name',
		},
		user: {
			title: 'User',
			plural: 'Users',
			position: 'Position',
			create: 'New user',
			lastAuthDate: 'Last authorization date',
			profile: 'Profile',
			email: 'E-mail',
			emailVerified: 'E-mail confirmed',
			name: 'Login',
			firstName: 'First name',
			lastName: 'Last name',
			middleName: 'Middle name',
			leftComment: 'posted a comment',
			createdTask: 'created a task',
			telegram: 'Telegram ID',
			username: 'Login',
			password: 'Password',
			avatar: 'Avatar',
			fullName: 'Full name',
			organizationName: 'Organization',
			searchFioPositionOrganization: 'Search by name, position, organization',
			error: {
				email: {
					custom: {
						email: 'Wrong e-mail',
					},
					uniqueness: 'Email address already registered',
					verifySent: 'To verify the data provided we have sent you a confirmation email',
				},
			},
		},
		role: {
			title: 'Role',
			plural: 'Roles',
			create: 'New role',
			created: 'Created',
			modified: 'Modified',
			name: 'Code',
			description: 'Description',
			isSaving: 'Saving...',
		},
		acl: {
			search: 'Search by model name or model description',
			title: 'ACL',
			plural: 'ACLs',
			create: 'New ACL',
			principalType: 'Principal type',
			app: 'App',
			role: 'Role',
			user: 'User',
			accessType: 'Access type',
			read: 'Read',
			write: 'Write',
			execute: 'Execute',
			all: 'All',
			principalId: 'Principal id',
			model: {
				title: 'Model',
				user: 'Users',
				project: 'Projects',
				person: 'Persons',
				description: 'Description',
			},
			permission: 'Permission',
			allow: 'Allow',
			deny: 'Deny',
			property: 'Property',
			allowAll: 'Allow all',
			list: 'Permission list',
		},
		roleMapping: {
			title: 'Role mapping',
			plural: 'Role mapping',
			create: 'New role mapping',
		},
		comments: {
			title: 'Comment',
			plural: 'Comments',
			placeholder: 'Type a comment',
			submit: 'Submit',
		},
		label: {
			title: 'Label',
			plural: 'Labels',
			create: 'New label',
		},
		file: {
			title: 'Attachment',
			plural: 'Attachmens',
			uploadError: 'Upload error',
			fileTooBig: 'File to big',
			uploadFile: 'Upload attachment',
			uploadImage: 'Upload image',
			uploadStart: 'Uploading begins',
			uploading: 'Uploading...',
			uploadComplete: 'Upload completed',
			notSelected: 'Attachment not selected',
			willBeUploaded: 'Will be uploaded',
			wrongType: 'Wrong attachment type',
		},
		organization: {
			title: 'Organization',
			plural: 'Organizations',
			name: 'Name',
			fullName: 'Name',
			shortName: 'Short name',
			ownershipForm: 'Form of ownership ',
			address: 'Legal address',
			headName: 'Full name of the Head',
			headPosition: 'Position of the Head',
			inn: 'TIN',
			ogrn: 'PSRN',
			kpp: 'TRRC',
			comment: 'Comment',
			externalId: 'ID of organization in the external system',
			externalData: 'Data of organization in the external system',
			position: 'Position',
			description: 'Organization description',
			info: 'Information',
			factAddress: 'Address',
			secondaryInfo: 'Secondary info',
			headInfo: 'Info of the Head',
			okato: 'ОКАТО',
			oktmo: 'ОКТМО',
			okved: 'ОКВЭД',
			okpo: 'ОКПО',
			noStaff: 'No staff found',
			noRequests: 'No requests found',
			noBankAccounts: 'No bank accounts found',
			contractor: 'Contractor',
			activityType: 'Activity type',
		},
		organizationType: {
			title: 'Тип организации',
			plural: 'Типы организации',
		},
		appeal: {
			title: 'Appeal',
			plural: 'Appeals',
			kanban: 'Registry',
			date: 'Appeal date',
			endDate: 'Processing date',
			status: 'Appal status',
			system: 'Source system',
		},
		appealStatus: {
			title: 'Appeal status',
			plural: 'Appeal statuses',
			default: 'Default',
			closing: 'Closing',
		},
		appealSystem: {
			title: 'Source system',
			plural: 'Source systems',
		},
		appealCategory: {
			title: 'Appeal category',
			plural: 'Appeal categories',
		},
		period: {
			period: 'Period',
			pick: 'Select period',
			start: 'Start date',
			end: 'End date',
			select: 'Period',
			day: 'Day',
			week: 'Week',
			month: 'Month',
			quarter: 'Quarter',
			year: 'Year',
			everyday: 'Everyday',
			everyweek: 'Every week',
			everymonth: 'Every month',
			lastDayOfMonth: 'Last day of month',
			dayOfMonth: 'of each month`',
		},
		stats: {
			title: 'Statistic',
			catAvg: 'Average duration of processing appeals by category',
			orgAvg: 'Average duration of processing appeals by organization',
			orgCount: 'Number of appeals by organization',
			sources: 'Appeals by sources',
			heatmap: 'Heat map',
		},
		audit: {
			title: 'Audit',
			model: 'Model',
			action: 'Action',
			create: 'Create',
			delete: 'Delete',
			update: 'Update',
			select: 'Select record',
			data: 'Record data',
			owner: 'Owner',
			hidden: '*hidden*',
		},
		heatPoint: {
			title: 'Heat point',
			plural: 'Heat points',
			source: 'Satellite',
			period: 'Period',
			periodHint: 'The maximum period is limited to one year. If it is exceeded, the dates will be adjusted.',
			reload: 'Reload',
			brightness: 'Brightness (in Kelvin)',
			dif: 'dif',
			eurasia: 'Eurasia',
			today: 'today',
			yesterday: 'yesterday',
			earlier: 'earlier',
			dayNight: 'Day/Night',
			frp: 'FRP',
			scan: 'Scan',
			track: 'Track',
			createFire: 'Add fire',
		},
		fire: {
			notFound: 'Fire not found',
			noAirRaids: 'No air raids',
			popup: {
				mainInfo: 'Main info',
				vpp: 'Runway info',
				activity: 'Involved in extinguishing',
			},
			allotmentNumber: 'Allotment number',
			id: 'SM Number',
			delete: 'Delete fire',
			fieldsSelected: 'Fields selected',
			fireNumber: 'Fire number',
			fireAct: 'Fire act',
			distances: 'Distance',
			numbers: 'Numbers',
			meteo: 'Meteo info',
			extinguishingCosts: 'Extinguishing costs',
			forestFireAct: 'Forest fire act',
			forestryDamage: 'Forestry damage and costs',
			cityAndAirfield: 'City and Airfield',
			dynamicDate: 'Date',
			new: 'New fire',
			name: 'Name',
			shortName: 'Short name',
			status: 'Status',
			title: 'Fire',
			plural: 'Fires',
			short: 'Fire',
			map: 'Map',
			add: 'Add',
			addForm: 'Add fire',
			mainInfo: 'Main info',
			resources: 'Forces and resources',
			secondaryInfo: 'Additional information',
			jumpings: 'Jumping',
			descents: 'Descents',
			delivered: 'Cargo delivered, ton',
			deliveredAvia: 'Including delivered by air, ton',
			threat: 'City threat',
			fireManager: 'Head of extinguishing',
			number: '№',
			numberLong: 'Fire number',
			created: 'Fire card',
			geo: 'Geometry',
			coordinates: 'Coordinates',
			coordsAndDistances: 'Coordinates and distance',
			cityAzimuth: 'City azimuth',
			cityDistance: 'City distance',
			airfieldAzimuth: 'Airfield azimuth',
			airfieldDistance: 'Airfield distance',
			aviaDepAzimuth: 'Air division azimuth',
			aviaDepDistance: 'Air division distance',
			transportRoutesDistance: 'Transport routes distance',
			fromMap: 'Draw',
			time: 'Time',
			detectDate: 'Detection date',
			reportDate: 'Message date',
			startDate: 'Extinguishing start date',
			extinguishedDate: 'Extinguishing date',
			localizationDate: 'Localization date',
			dynamic: 'Firelog',
			createDynamic: 'Create new firelog',
			edge: 'Edge, km',
			edgeShort: 'Edge',
			protectiveArea: 'Protective area, ha',
			protectiveAreaShort: 'Protective',
			operationalArea: 'Operational area, ha',
			operationalAreaShort: 'Operational',
			reserveArea: 'Reserve area, ha',
			reserveAreaShort: 'Reserve',
			soilArea: 'Soil area, ha',
			soilAreaShort: 'Soil',
			upperArea: 'Upper area, ha',
			upperAreaShort: 'Upper',
			lowerArea: 'Lower area, ha',
			lowerAreaShort: 'Lower',
			forestArea: 'Forest area, ha',
			forestAreaShort: 'Forest',
			nonForestArea: 'Non-forest area, ha',
			nonForestAreaShort: 'Non-forest',
			coverArea: 'Covered area, ha',
			coverAreaShort: 'Covered',
			nonCoverArea: 'Uncovered area, ha',
			nonCoverAreaShort: 'Uncovered',
			activeBurningArea: 'Active burning area, ha',
			activeBurningAreaShort: 'Burning area',
			description: 'Description',
			startDrawing: 'Paint',
			finishDrawing: 'Finish',
			showCounties: 'Show counties',
			showForestryQuarters: 'Show forestry quarters',
			area: 'Total area, ha',
			areaKm: 'Total area, sq.km',
			areaShort: 'Total area',
			areaTitle: 'Forest fire area, ha',
			leasedArea: 'Leased area, ha',
			geoChanged: 'Geometry is modified',
			forestOwnership: 'Forest ownership',
			combustionIntensity: 'Combustion intensity',
			combustionPattern: 'Combustion pattern',
			combustionType: 'Combustion type',
			combustionCharacteristic: 'Combustion characteristic',
			decisionDetailsOpb: 'Details of the decision of the CoES and OPB of the Republic of Sakha (Yakutia)',
			date: 'Date',
			protocolNumber: 'Protocol number',
			forestryPrecinct: 'Forestry precinct',
			cause: 'Fire cause',
			zone: 'Monitoring zone',
			forestryFireNumber: 'Forestry number',
			aviaDepFireNumber: 'Air division number',
			bigFireNumber: 'Big fire number',
			isdmFireNumber: 'ISDM number',
			isdmSync: 'Synchronization with ISDM',
			notLandingReason: 'Reason for not landing',
			notInspectionReason: 'Reason for not inpection',
			windSpeed: 'Wind speed',
			extinguishingCostsSubject: 'Subject',
			rentBC: 'Rent BC',
			totalCost: 'Total cost',
			actDate: 'Act date',
			actPercent: '% act',
			compiler: 'Compiler',
			woodLoss: 'Wood loss',
			youngWoodLoss: 'Young wood loss',
			burnedWood: 'Burned or damaged wood',
			burnedHarvestedWood: 'Burned or damaged harvested wood',
			KPOdetected: 'KPO detection',
			KPOliquidation: 'KPO liquidation',
			frontalPrecipitation: 'Frontal precipitation',
			origin: 'Origin fire point',
			pickPoint: 'Point on the map',
			pickPointHint: 'Pick point on the map with your cursor',
			lastDynamic: 'Last firelog date',
			updateMainInfo: 'Auto suggestion',
			updateMainInfoHint: 'Automatically fill some fields with data based on origin point or geometry of the fire',
			registry: {
				title: 'Registry',
			},
		},
		dynamic: {
			popup: {
				confirm: 'Confirm',
				message: 'The entered data will be lost',
			},
		},
		notifyRecipient: {
			title: 'Recipient',
			plural: 'Recipients',
			group_filter: 'Recipient group filter',
			group: 'Recipient group',
			name: 'Name',
			email: 'Email',
		},
		notifyRecipientGroup: {
			title: 'Recipient group',
			plural: 'Recipient groups',
			showAll: 'Show all',
		},
		transportData: {
			title: 'Transport data',
			period: 'Period',
			schedule: 'Current schedule',
			report: 'Report',
			nextDate: 'Next mailing date',
			disabled: 'The mailing is inactive',
			active: 'Acitve',
			minute: 'Minute',
			hour: 'Hour',
			day: 'Day of the month',
			dayOfWeek: 'Day of the week',
			subject: 'Subject',
			text: 'Text',
		},
		notLandingReason: {
			title: 'Reason for not landing',
			plural: 'Reasons for not landing',
		},
		notInspectionReason: {
			title: 'Reason for not inpection',
			plural: 'Reasons for not inpection',
		},
		aviaDep: {
			title: 'Air division',
			plural: 'Air divisions',
			location: 'Location',
			active: 'Active',
			area: 'Area',
		},
		forestry: {
			title: 'Forestry',
			plural: 'Forestries',
			forestOwnership: 'Forest ownership',
		},
		landCategory: {
			title: 'Land category',
			plural: 'Land categories',
		},
		fireAnalyticalIndicator: {
			title: 'Analytical indicator of fires',
			plural: 'Analytical indicator of fires',
		},
		tenant: {
			title: 'Tenant',
			plural: 'Tenants',
		},
		county: {
			my: 'My county',
			title: 'County',
			plural: 'Counties',
			oktmo: 'OKTMO',
			population: 'Population',
		},
		city: {
			my: 'My city',
			title: 'City',
			plural: 'Cities',
			pluralShort: 'Cities',
			add: 'Add city',
			okato: 'OKATO code',
			fireDanger: 'Fire danger',
			population: 'Population',
			area: 'Area',
			county: 'County',
			oktmo: 'OKTMO code',
			sufficiencyStatus: 'Sufficiency status',
			municipality: 'Municipality',
			nomenclatures: 'Nomenclature',
		},
		crewType: {
			title: 'Crew type',
			title_short: 'Crew type',
			plural: 'Crew types',
			formations: 'Firefighting formations',
		},
		extinguishingAgentsType: {
			title: 'Type of extinguishing agent',
			title_short: 'Extinguishing agent',
			plural: 'Extinguishing agents',
		},
		discoveryMessageType: {
			title: 'Discovery message',
			plural: 'Discovery message types',
		},
		breed: {
			title: 'Breed',
			plural: 'Breeds',
		},
		cover: {
			title: 'Forest cover',
			plural: 'Forest covers',
		},
		forestCategory: {
			title: 'Forest category',
			plural: 'Forest categories',
		},
		forestOwnership: {
			title: 'Forest ownership',
			plural: 'Forest ownerships',
			short: 'Ownership',
		},
		fireCause: {
			title: 'Fire cause',
			plural: 'Fire causes',
		},
		fireStatus: {
			title: 'Fire status',
			plural: 'Fire statuses',
			selected: 'Selected statuses',
			codes: {
				abandoned: 'Abandoned',
				extinguished: 'Extinguished',
				resumed: 'Resumed',
				localized: 'Localized',
				notspreading: 'Not spreading',
				weakens: 'Weakens',
				continues: 'Continues',
				intensifies: 'Intensifies',
				active: 'Active',
				zik: 'Other categories of land',
			},
		},
		determiningMethod: {
			title: 'Detection method',
			plural: 'Detection methods',
		},
		combustionType: {
			title: 'Combustion type',
			plural: 'Combustion types',
		},
		combustionIntensity: {
			title: 'Combustion intensity',
			plural: 'Combustion intensities',
		},
		combustionPattern: {
			title: 'Combustion pattern',
			plural: 'Combustion patterns',
		},
		airRaid: {
			title: 'Air raid',
			plural: 'Air raids',
			pluralLong: 'Air raid',
			add: 'Add air raid',
			requestNumber: 'Application number',
			requestDate: 'Application date',
			departureDate: 'Departure date',
			arrivalDate: 'Arrival date',
			mainInfo: 'Main info',
			addContract: 'add contract',
			noContracts: 'No active contracts',
			startDate: 'Date (from)',
			endDate: 'Date (to)',
			pickAirRaid: 'Select air raid',
		},
		airJob: {
			title: 'Aerial work',
			plural: 'Aerial works',
			add: 'Add Aerial work',
			duration: 'Duration time',
			duration_short: 'Duration',
			drains: 'Drains',
			effects: 'Effects',
			jumpings: 'Training jumps',
			jumpings_short: 'Jumps',
			descents: 'Training descents',
			descents_short: 'Descents',
		},
		airJobType: {
			title: 'Aerial work type',
			plural: 'Aerial work types',
		},
		staff: {
			title: 'Staff',
			plural: 'Staff',
			number: '№',
			fullName: 'Full name',
			category: 'Category',
			tableNumber: 'Table number',
		},
		unit: {
			title: 'Unit',
			plural: 'Units',
			nationalSymbol: 'Symbol (national)',
			internationalSymbol: 'Symbol (international)',
			nationalCode: 'Code designation (national)',
			internationalCode: 'Code designation (international)',
		},
		contract: {
			title: 'Contract',
			plural: 'Contracts',
			number: '№',
			date: 'Date',
			term: 'Validity period',
			organization: 'Organization',
		},
		contractor: {
			title: 'Contractor',
			plural: 'Contractors',
		},
		vehicleType: {
			title: 'Vehicle type',
			plural: 'Vehicles',
			callSign: 'Позывной',
		},
		aircraftType: {
			title: 'Aircraft type',
			plural: 'Aircraft types',
			group: 'Group',
			class: 'Class',
			speed: 'Speed',
			fuelConsumption: 'Fuel consumption',
			fuelSupply: 'Fuel reserve',
			runwayLength: 'Runway length',
			aeronauticalSupply: 'Aeronautical reserve',
		},
		weatherStation: {
			title: 'Weather station',
			plural: 'Weather stations',
			latitude: 'Latitude',
			longitude: 'Longitude',
			activity: 'Active',
			aviaDepId: 'Air division',
			value: 'Values',
		},
		airfieldRunway: {
			title: 'Airfield runway',
			plural: 'Airfield runways',
			add: 'Add airfield runway',
			size: 'Sizes',
			number: '№',
			class: 'Class',
			pcn: 'PCN',
			utpzCategory: 'USPZ category',
			icaoCategory: 'ICAO category',
			coverage: 'Coverage',
			magneticBearing: 'Magnetic landing course',
			lightingSystem: 'Lighting equipment',
		},
		airfield: {
			title: 'Airfield',
			plural: 'Airfields',
			name: 'Name',
			add: 'add airfield',
			icaoCode: 'The ICAO code',
			icaoLandingCategory: 'Landing category according to ICAO',
			localTime: 'Local time',
			latitude: 'Reference point: latitude',
			longitude: 'Reference point: longitude',
			exceeding: 'Exceeding the airfield',
			magneticDeclination: 'Magnetic declination',
			iataCode: 'IATA Airport Code',
		},
		plan: {
			title: 'Plan',
			plural: 'Plans',
			total: 'Total planned volume',
		},
		operInfo: {
			plural: 'Operational info',
		},

		fireFightingVehicle: {
			title: 'Firefighting equipment',
			plural: 'Firefighting equipments',
			plan: 'Plan',
			required: 'Required',
			current: 'Current',
			current_short: 'Current',
			date: 'Date',
		},
		fireFightingPeople: {
			title: 'Firefighting force',
			plural: 'Firefighting forces',
			plan: 'Plan',
			required: 'Required',
			current_short: 'Current',
			evacuated_short: 'Evacuated',
			delivered: 'Delivered',
			air_short: 'Air',
		},
		fireFightingFormation: {
			title: 'Firefighting formation',
			plural: 'Firefighting formations',
			title_short: 'Formation',
		},
		FireCharacteristics: {
			location: 'Terrain characteristics',
		},
		personalRespiratoryType: {
			title: 'Type of personal respiratory protection equipment',
		},
		personnelStatus: {
			title: 'Personnel status',
		},
		fireDepSentry: {
			title: 'Sentry',
			plural: 'Sentries',
		},
		pump: {
			title: 'Pump',
			plural: 'Pumps',
			capacity: 'Capacity',
		},
		cisternBulk: {
			title: 'Cistern bulk',
			plural: 'Cistern bulk',
			volume: 'Volume',
		},
		foamBulk: {
			title: 'Foam bulk',
		},
		fireEquipment: {
			title: 'Fire-fighting equipment',
		},
		observerPilot: {
			title: 'Observer pilot',
			plural: 'Observer pilots',
			inspector: 'Inspector',
			intern: 'Intern',
			add: 'Add observer pilot',
		},
		aircraftKind: {
			title: 'Kind of flying machine',
			title_short: 'Kind of FM',
			plural: 'Kind of flying machines',
		},
		silam: {
			title: 'Air pollution',
			on: 'Show',
		},
		monitoringZone: {
			title: 'Monitoring zone',
			plural: 'Monitoring zones',
			bigFireLimit: 'Big fire limit, ha',
		},
		emergencySituation: {
			title: 'Emergency mode',
			plural: 'Emergency modes',
			startAct: 'Implementation act number',
			endAct: 'Removal act number',
			republicStatus: 'On the entire territory of the Republic',
			comment: 'Note',
			startDate: 'Implementation date',
			endDate: 'Removal date',
			type: 'Type',
			counties: 'Counties',
		},
		emergencySituationDamage: {
			title: 'Damage from the emergency mode',
			title_short: 'Damage',
			plural: 'Damage from the emergency modes',
			dead: 'Dead',
			injured: 'Injured',
			affectedObjects: 'Affected objects',
			comment: 'Comment',
			legend: 'Legend',
		},
		emergencySituationType: {
			title: 'Emergency situation type',
			plural: 'Emergency situation types',
		},
		requestLog: {
			title: 'Request log',
			plural: 'Request logs',
			number: '№',
			date: 'Date',
			location: 'Location',
			description: 'Description',
			measures: 'Measures',
			result: 'Result',
			contactInfo: 'Contact info',
			messageType: 'Message type',
			operator: 'Operator',
			processed: 'Processed',
		},
		fireReport: {
			title: 'Fire report',
			plural: 'Fire reports',
			coordinats: 'Coordinates of the fire',
			tenant: 'Tenant',
			localization: 'Localization/extinguished date',
			peopleTypes: 'Additional types of people',
			stayed: 'People at the end of the day',
			year: 'Year',
			month: 'Month',
			groupBy: 'Group by',
			nonForestArea: 'Non-forest area',
			noAviaDep: 'No air division',
			noCounty: 'No county',
			noForestry: 'No forestry',
			group: 'Group',
			byArea: 'by area',
			byExtinguishingTime: 'by extinguishing time',
		},
		forestryQuarter: {
			title: 'Forestry quarter',
			short: 'quarter',
			plural: 'Forestry quarters',
			area: 'Area sq.km.',
			add: 'Add quarter',
			name: 'Name',
			code: 'Code',
			forestry: 'Forestry',
			forestryPrecinct: 'Forestry precinct',
			forestCategory: 'Forestry category',
			aviaDep: 'Air division',
			monitoringZone: 'Monitoring zone',
		},
		forestryPrecinct: {
			title: 'Forestry precinct',
			plural: 'Forestry precincts',
			short: 'Forestry prec.',
		},
		aircraft: {
			title: 'Aircraft',
			plural: 'Aircrafts',
			number: 'Aircraft number',
			number_short: '№',
			contract: 'Сontract',
		},
		weather: {
			title: 'Weather',
			temp: 'Temperature',
			Temperature: 'Temperature at a height of 2 m above the ground',
			tempMeasure: '°C',
			pressure: 'Pressure',
			pressureDescription: 'Atmospheric pressure',
			pressureMeasure: 'mmHg.',
			precipitation: 'Precipitation',
			precipitationDescription: 'Accumulation of precipitation in 3 hours',
			precipitationMeasure: 'kg/m2',
			humidity: 'Humidity',
			humidityDescription: 'Relative humidity at a height of 2 m above the ground',
			humidityMeasure: '%',
			pm25: 'PM2.5',
			pm25Description: 'Suspended solid microparticles and the smallest droplets of liquid with a diameter of 2.5 microns or less',
			pm25Measure: 'mcg/m3',
			pm10: 'PM10',
			pm10Description: 'Suspended solid microparticles and the smallest droplets of liquid with a diameter of 10 microns or less',
			pm10Measure: 'mcg/m3',
			co: 'CO',
			coDescription: 'Description of the CO layer',
			coMeasure: 'mcg/m3',
			no: 'NO',
			noDescription: 'Description of the NO layer',
			noMeasure: 'mcg/m3',
			no2: 'NO2',
			no2Description: 'Description of the NO2 layer',
			no2Measure: 'mcg/m3',
			ozone: 'Ozone',
			ozoneDescription: 'Description of the O3 layer',
			ozoneMeasure: 'DU',
			so2: 'SO2',
			so2Description: 'Description of the SO2 layer',
			so2Measure: 'kg/m2',
			feeling: 'Feels like',
		},
		firepopup: {
			fire: 'Fire',
			point: 'Heat point',
			info: 'Info',
			weather: 'Weather',
		},
		wind: {
			title: 'Wind',
			speedFactor: 'Speed',
			fadeOpacity: 'Fade opacity',
			particleLife: 'Particle life',
			opacity: 'Opacity',
		},
		notifyStatus: {
			title: 'Notification status',
			plural: 'Notification statuses',
			status: 'Status',
		},
		notifyType: {
			title: 'Notification type',
			plural: 'Notification types',
			type: 'Type',
			default: 'Default',
			select: 'Select notification type',
			active: 'Active API',
		},
		notifyTemplate: {
			title: 'Notification template',
			plural: 'Notification templates',
			template: 'Template',
			noTemplates: 'No templates',
		},
		notifyLog: {
			title: 'Notification log',
			plural: 'Notification logs',
			text: 'Text',
			phone: 'Recipient phone',
			recipient: 'Recipient',
			date: 'Notification date',
			add: 'New Notification',
			pickTemplate: 'Select template',
			mailing: 'Informing',
			recipientsCount: 'Number of recipients',
			notSent: 'Not sent',
			noApi: 'The API is not connected',
			accepted: 'Accepted',
			notAccepted: 'Not accepted',
			acceptedPlaceholder: 'Confirmation status',
		},
		notify: {
			massNotify: 'To confirm the mass mailing, you need to enter the code at the end of the form',
			code: 'Confirmation code',
		},
		case: {
			mainDate: 'Main info',
			messages: 'Messages',
			sendMessage: 'Send message',
			notifyFilter: 'Notify filters',
			noCounty: 'No county',
		},
		camera: {
			title: 'Camera',
			plural: 'Cameras',
			error: 'Connection error',
			maximize: 'Maximize',
			minimize: 'Minimize',
		},
		dzz: {
			title: 'Earth remote sensing',
		},
		genShtab: {
			title: 'General Staff of the USSR',
		},
		firePlanForcesAndMeans: {
			title: 'Forces and means',
			code: 'Type of report',
			organization: 'Name organization',
			municipality: 'Municipality',
			typeOfFormation: 'Type of formation',
			numberOfFormations: 'Number of formations',
			numberOfFireExtinguishingForcesPersons: 'Number of fire extinguishing forces persons (persons)',
			numberOfFireExtinguishingForcesGroups: 'Number of fire extinguishing forces persons (groups)',
			extinguishingLeaders: 'Extinguishing leaders (persons)',
			pilots: 'Pilots (persons)',
			fireParatroopersPersons: 'Fire paratroopers (persons)',
			fireParatroopersGroups: 'Fire paratroopers (groups)',
			firefightersPersons: 'Firefighters (persons)',
			firefightersGroups: 'Firefighters (groups)',
			groundWorkersPersons: 'Ground workers (persons)',
			groundWorkersGroups: 'Ground workers (groups)',
			airFireInstructorsPersons: 'Air fire instructors persons (persons)',
			airFireInstructorsGroups: 'Air fire instructors persons (groups)',
			permanentGroundWorkersPersons: 'Permanent ground workers persons (persons)',
			permanentGroundWorkersGroups: 'Permanent ground workers persons (groups)',
			temporaryGroundWorkersPersons: 'Temporary ground workers persons (persons)',
			temporaryGroundWorkersGroups: 'Temporary ground workers persons (groups)',
			forestFireTrucks: 'Forest fire trucks',
			forestFireTractors: 'Forest fire tractors',
			bulldozers: 'Bulldozers',
			forestPlows: 'Forest plows',
			trailers: 'Trailers',
			motorPumps: 'Motor pumps',
			chainsaws: 'Chainsaws',
			blowers: 'Blowers',
			unmannedAerialVehicles: 'Unmanned aerial vehicles',
			incendiaryDevices: 'Incendiary devices',
			helicopterSpillways: 'Helicopter spillways',
			aviationFireTanks: 'Aviation fire tanks',
			caterpillarTractor: 'Caterpillar tractor',
			wheeledTractor: 'Wheeled tractor',
			fireTrucksCar: 'Fire trucks car',
			excavators: 'Excavators',
			allTerrainVehicles: 'All-terrain vehicles',
			trucks: 'Trucks',
			busesShifts: 'Buses shifts',
			cars: 'Cars',
			trawls: 'Trawls',
			fireTanks: 'Fire tanks',
			backpackForestFireExtinguishers: 'Backpack forest fire extinguishers',
			shovels: 'Shovels',
			axesHoes: 'axes-hoes',
			YKVRadioStations: 'YKV-radio stations',
			KVRadioStations: 'KV-radio stations',
			helicopters: 'Helicopters',
			aircraft: 'Aircraft',
			specializedTrackedVehicles: 'Specialized tracked vehicles',
			forestFireBoatsAndMotorBoats: 'Forest fire boats and motor boats',
		},
		fireDashboard: {
			title: 'Dashborard',
			total: 'Total',
			active: 'Total active fires',
			fires: 'Fires',
			dynamics: 'Dynamics of Situation',
			cityDanger: 'Threat to settlements',
			area: 'Area, ha',
			totalArea: 'Total area, ha',
			dailyIncrease: 'Daily increase, ha',
			extinguished: 'Extinguished',
			firesCount: 'Number of fires',
			fullReport: 'Full report',
			operationalReport: 'Operational report',
			sectionDescription: 'On the situation with forest firefighters in the Republic of Sakha (Yakutia)',
			underway: 'Work is underway',
			suspended: 'Suspended',
			unattended: 'Unattended',
			activeArea: 'Total fires area, ha',
			extinguishedArea: 'Extinguished area, ha',
			noDanger: 'No threats',
			dailyExtinguished: 'Fires extinguished over the past day',
			dailyDetected: 'Fires detected over the past day',
			dailyLocalized: 'Fires localized over the past day',
			dailyUnderway: 'Fires underway over the past day',
			naturalGrouth: 'Natural grouth of fires',
			naturalGrouthShort: 'Natural grouth',
			personnel: 'Personnel',
			vehicles: 'Technical means',
			seasonFires: 'Fires registered this year',
			currentYear: 'This year',
			lastYear: 'Last year',
			seasonTotalCount: 'Fires',
			seasonTotalArea: 'Total area, ha',
			count: 'Сount',
			citiesCount: 'Cities count',
			distance: 'Distance, km',
			citiesSpecial: 'Under special control',
			citiesUnderway: 'Extinguishing in progress',
			citiesUnattended: 'Within 10 km range',
			citiesSpecialTest: 'Under special control (20 km)',
			citiesUnderwayTest: 'Extinguishing in progress (50 km)',
			citiesUnattendedTest: 'Within 50 km range',
			dynamicsType: {
				forest: 'Forest fires: dynamics of situation',
				oopt: 'Specially protected natural areas: dynamics of situation',
				other: 'Other categories of lands: dynamics of situation',
			},
		},
		address: {
			title: 'Address',
		},
		errand: {
			dashboard: 'Statistics on the execution of orders of the Head of the Republic of Sakha (Yakutia)',
			freeMode: 'Free mode',
			week: 'Week',
			month: 'Month',
			halfYear: 'Half year',
			year: 'Year',
			org: 'Perpetrator',
		},
		map: {
			hpSelectModeDisabled: 'Turn on the heat point layer',
			hpSelectAdd: 'Selection mode: add heat points',
			hpSelectSubtract: 'Selection mode: remove heat points',
			hpSelectRadius: 'Radius around the point',
			metersShort: 'm',
			kmShort: 'km',
			searchHint: 'Search by longitude/latitude',
			export: 'Export',
			exportHint: 'Export to and PNG image',
			exportFormat: 'Format',
			exportResolution: 'Resolution',
		},
		techFireReport: {
			techfaulty: 'Неисправная техника',
			'digital-notefree': 'Цифровая записка ЛС - Вольники',
			ls5pso: 'Цифровая записка ЛС - 5ОФПС',
			'112list': 'Список по 112',
			'112statistic': 'Статистика по 112',
			plural: 'Отчеты',
			fuel: 'Топливо',
			lsogps40: 'Цифровая записка ЛС - Вольники',
			saveTemplate: 'Сохранить шаблон',
			sign1: 'Подпись 1',
			sign2: 'Подпись 2',
			sign3: 'Подпись 3',
			header: 'Шапка',
			title: 'Заголовок',
			date: 'Дата формирования',
			summarylist: 'Сводная ведомость',
			numberOfEventsHeld: 'Number of events held',
			InfoFireService: 'Information about the fire service',
		},
		technoZone: {
			title: 'Technogenic zone',
			plural: 'Technogenic zones',
		},
		isdm: {
			title: 'RMIS',
			titleLong: 'Remote monitoring information system',
			METEO: '1-МЕТЕО',
			ISDM1T: '1T-ISDM',
			ISDM1: '1-ISDM',
			ISDM8: '8-ISDM',
			ISDM9: '9-ISDM',
			groupAirDepartments: 'By air departments',
			groupAdministrativeRegions: 'By administrative regions',
			noGroup: 'Do not group',
		},
		isdmFire: {
			title: 'RMIS',
			plural: 'Remote monitoring information system',
			fireNumber: 'Fire number',
			externalId: 'External ID',
			reportDate: 'Report Date',
			processed: 'Processed',
		},
		extinguishingAgentsGroup: {
			title: 'Extinguishing agents group',
			plural: 'Extinguishing agents groups',
		},
		nasleg: {
			title: 'Nasleg',
			plural: 'Naslegs',
		},
		delivery: {
			title: 'North delivery',
			map: 'Map',
		},
		reserve: {
			lastMovementUpdate: 'Last supply update',
			searchPlaceholder: 'Search by nomenclature, category, storehouse',
			plural: 'Storage',
			title: 'Storage',
			title_full: 'Storage control',
			measure: 'Measure',
			nomenclature: 'Nomenclature',
			storage: 'Storage',
			quantity: 'Quantity',
			fact: 'Fact',
			capacity: 'Storage capacity',
			available: 'Available',
			nomenclatureCard: 'Nomenclature card',
			createNomenclature: 'Create nomenclature',
			mainInfo: 'Main info',
			movement: 'Movement',
			newNomenclature: 'New nomenclature',
			date: 'Date',
			startQuantity: 'Начальный остаток',
			income: 'Income',
			cost: 'Cost',
			movementType: 'Movement type',
			category: 'Nomenclature category',
		},
		reserveMovement: {
			title: 'Nomenclature movement',
			plural: 'Nomenclature movement',
		},
		contragent: {
			title: 'Контрагент',
			plural: 'Контрагенты',
			delete: 'Удалить контрагента',
			create: 'Добавить контрагента',
			mainInfo: 'Общая информация',
			card: 'Карточка контрагента',
			staff: 'Сотрудники',
			bankAccounts: 'Банковские счета',
			requests: 'Заявки',
			agreements: 'Реестр соглашений',
		},
		transportShip: 'Vessel',
		nomenclature: {
			title: 'Nomenclature',
		},
		request: {
			closestPeriod: 'Closest request period',
			currentPeriod: 'Current request period',
			messageTitle: 'Type request',
			sendMessageToOwner: 'Request to owner',
			agreementRole: 'Agreement role',
			title: 'Request',
			plural: 'Request',
			number: 'Request number',
			noStatuses: 'No statuses',
			author: 'Author',
			Members: 'Members',
			selectMember: 'Select member',
			addMember: 'Add member',
			agreementMembers: 'Agreement members',
			customer: 'Customer',
			contractor: 'Contractor',
			river: 'River',
			direction: 'Direction',
			create: 'Create request',
			collective: 'Collective request',
			agreement: 'Agreement',
			mainInfo: 'Main info',
			mainInfo_full: 'Request main info',
			customerInfo: 'Customer',
			requestLog: 'Request log',
			discussion: 'Discussion',
			type: 'Request type',
			date: 'Request date',
			noOwnerOrganization: 'У создателя заявки нет организации',
			departureCity: 'Departure City',
			destinationCity: 'Destination City',
			deliveryDate: 'Delivery Date',
			trackNumber: '№ track',
			delete: 'Delete request',
			changed: 'Save changes?',
			positionsCount: 'Positions',
			addPlan: 'Add plan',
			shipment: 'Shipment count',
			levels: 'Request levels',
			selectCounty: 'Select count',
			selectDirection: 'Select direction',
			county: 'County',
			category: 'Category',
			export: {
				category: 'Category',
				nomenclature: 'Nomenclature',
				numberTrack: 'Bill of lading No',
				numberPlan: 'Plan No. (according to IAS)',
				quantityTNE: 'Plan, tons',
				shippedCount: 'Shipped, tons',
				deliveredCount: 'Delivered, tons',
				carrier: 'Cargo carrier',
				remainingToShip: 'Remaining for shipment, tons',
				shipmentPercentage: '% of shipment',
				deliveryPercentage: '% delivery',
			},
		},
		ladingBill: {
			title: 'Bill of lading',
			plural: 'Lading bills',
			add: 'Add lading bill',
			addFull: 'Add lading bill',
			delete: 'Remove lading bill',
			sender: 'Sender',
			payer: 'Payer',
			consignee: 'Consignee',
			totalItems: 'Total items',
			totalReleased: 'Total released',
			netWeight: 'Weight(net)',
			grossWeight: 'Weight(gross)',
			noLadingBill: 'Invoices not found',
			number: 'Document number',
			date: 'Document date',
			carrier: 'Сarrier',
		},
		requestTrack: {
			title: 'Request track',
			status: 'Track status',
		},
		history: {
			planApproved: 'Добавлен план ЧС, статус плана изменен',
			comments: 'Комментарии',
			leftComment: 'оставил(а) комментарий',
			leftCommentDiscussion: 'оставил(а) комментарий в обсуждении',
			updated: 'обновил(а)',
			deleteFile: 'удалил(а) файл',
			updateMemberStatus: 'изменил(а) статус согласования заявки на',
			updateMemberStatus_short: 'изменил(а) статус согласования заявки',
			to: 'на',
			requestCreate: 'создал(а) заявку',
			requestUpdate: 'изменил(а) поля заявки',
			addRequstMember: 'добавил(а) участника',
			removeRequestMember: 'удалил(а) участника',
			removeLadingBill: 'удалил(а) накладную',
			removeTrack: 'удалил(а) местоположение груза',
			requestStatus: 'изменил(а) статус заявки на',
			allAgreed: 'все участники согласовали заявку, статус заявки изменен',
			notAllAgreed: 'не все участники согласовали заявку, статус заявки изменен',
			addExplanatoryNoteFile: 'добавил файл пояснительной записки',
			removeExplanatoryNoteFile: 'удалил файл пояснительной записки',
			addPlanFile: 'добавил файл плана ЧС',
			removePlanFile: 'удалил файл плана ЧС',
			addCorrectionPlanFile: 'добавил файл плана ЧС',
			removeCorrectionPlanFile: 'удалил файл плана ЧС',
		},
		activityType: {
			title: 'Activity type',
			plural: 'Activity types',
		},
		direction: {
			title: 'Direction',
			plural: 'Directions',
		},
		emergency: {
			title: 'Планирование по ЧС',
			map: 'Map',
			registry: 'Registry',
			reserves: 'Reserves',
		},
		municipalSufficiencyStatus: {
			title: 'Sufficiency status',
			plural: 'Sufficiency statuses',
		},
		municipalReadinessStatus: {
			title: 'Readiness status',
			plural: 'Readiness statuses',
		},
		municipalFormation: {
			risks: 'Risks / people / teens',
			citiesCount: 'Cities',
			peopleCount: 'People',
			teenagersCount: 'Teenagers',
			sufficiencyStatus: 'Sufficiency status',
			readinessStatus: 'Readiness status',
		},
		bankAccount: {
			title: 'Bank account',
			plural: 'Bank accounts',
			bank: 'Bank',
			bic: 'БИК',
			swift: 'SWIFT',
			correspondentAccount: 'Correspondent account',
		},
		mineral: {
			plural: 'Minerals',
			mining: 'Mining',
			abc1: 'ABC1',
			c2: 'C2',
			zabC: 'Заб.С',
			PI: 'PI',
			PIType: 'PI type',
			units: 'Units',
		},
		speed: 'Скорость',
		course: 'Курс',
		ship: {
			title: 'Ship',
			plural: 'Ships',
			imo: 'IMO',
			source: 'Source',
			marineReg: 'Marine reg',
			receiveDate: 'Receive date',
		},
		storehouse: {
			title: 'Storehouse',
			plural: 'Storehouses',
			address: 'Address',
			area: 'Area, m2',
			wearout: 'Wearout, %',
			county: 'Ulus name',
		},
		analytics: {
			title: 'Analytics',
		},
		tracking: {
			title: 'Cargo tracking',
			titleShort: 'Tracking',
			shipmentDate: 'Shipping date',
			deliveredDate: 'Delivery date',
		},
		emergencySituationPlan: {
			levels: 'ES plan levels',
			title: 'ES plan',
			plural: 'ES plans',
			delete: 'Delete ES plan',
			create: 'Create ES plan',
			changed: 'Save changes?',
			attachPlanFiles: 'Attach plan file',
			attachCorrectionPlanFiles: 'Attach correction plan file',
			name: 'Plan ',
		},
		regionLevel: {
			title: 'Level',
			plural: 'Levels',
			select: 'Select level',
		},
		emergencyRiskCategory: {
			title: 'Risk category',
			plural: 'Risk categories',
		},
		emergencySituationPlanStatus: {
			title: 'ES status',
			plural: 'ES statuses',
		},
		emergencySituationPlanResource: {
			title: 'ES resources',
			title_short: 'Resources',
			personnelCount: 'Penronnel',
			vehicleCount: 'Vehicles',
			vehicle: 'Vehicle',
			vehicleType: 'Vehicle type',
		},
		emergencySituationPlanResourceTier: {
			title: 'ES Resource tier',
			plural: 'ES Resource tiers',
			title_short: 'Tier',
		},
		fireAlarmMessage: {
			newAdpiAlarm: 'New ADPI message',
			new112Alarm: 'New 112 message',
			title: 'Fire alarm message',
			plural: 'Fire alarm messages',
			trueMessage: 'Real threat',
			falseMessage: 'False message',
			call: 'Call',
		},
		fireAlarmMessageStatus: {
			title: 'Fire alarm message status',
			plural: 'Fire alarm message statuses',
		},
		emergencyDivision: {
			title: 'Emergnecy division',
			plural: 'Emergnecy divisions',
			contacts: 'Contacts',
		},
		emergencyDivisionGroup: {
			title: 'Emergnecy division group',
			plural: 'Emergnecy division groups',
		},
		fireDepSection: {
			title: 'FireDep section',
			plural: 'FireDep sections',
		},
		fireDepPersonnelStatus: {
			title: 'FireDep personnel status',
			plural: 'FireDep personnel status',
		},
		adpiDeviceType: {
			title: 'ADPI type',
			plural: 'ADPI types',
		},
		fireAlarmMessageSource: {
			title: 'Fire alarm message source',
			plural: 'Fire alarm message sources',
		},
		protectiveSuit: {
			title: 'Protective suit',
			plural: 'Protective suits',
		},
		fireAlarmObjectType: {
			title: 'Fire object type',
			plural: 'Fire object types',
		},
		adpiDeviceStatus: {
			title: 'ADPI status',
			plural: 'ADPI statuses',
		},
		hydraulicTool: {
			title: 'Hydraulic tool',
			plural: 'Hydraulic tools',
		},
		fireDepVehicleType: {
			title: 'FireDep vehicle type',
			plural: 'FireDep vehicle types',
			special: 'special machinery',
		},
		vehicle: {
			stateNumber: 'Registration number',
			passport: 'Passport',
			capacity: 'Capacity',
			pumpCapacity: 'Pump capacity',
			foamCapacity: 'Foam capacity',
			glonass: 'System GLONASS is available?',
			year: 'Year of manufacture',
			callSign: 'Call sign',
			actualFoamValue: 'Actual foam capacity',
			faxFoamValue: 'Actual foam capacity',
			description: 'Description',
			actualFuelValue: 'Actual fuel capacity',
			endDate: 'End date',
		},
		fireDepVehicleHydraulicTool: {
			title: 'Hydraulic tool',
			add: 'Add hydraulic tool',
		},
		fireDepVehicleFuel: {
			fuelValue: 'Fuel capacity',
		},
		fireDepVehicleBrand: {
			title: 'FireDep vehicle brand',
			plural: 'FireDep vehicle brands',
		},
		gasMask: {
			title: 'Gas mask',
			plural: 'Gas masks',
		},
		fireDepRank: {
			title: 'FireDep rank',
			plural: 'FireDep ranks',
		},
		fireDepPosition: {
			title: 'FireDep position',
			plural: 'FireDep positions',
		},
		fireDep: {
			add: 'Add fire deparment',
			title: 'Fire Department',
			plural: 'Fire Departments',
			callSigns: 'CallSings',
			code: 'Code',
			selectPlaceholder: 'Select fire department',
			selected: 'Selected fire departments',
		},
		techFire: {
			rank: 'Fire fank',
			map: {
				title: 'Map',
			},
			fires: {
				title: 'Fires',
			},
			firesPlanVisits: {
				title: 'Departure',
				plural: 'Departures',
				create: 'Create departure',
				dateOfCreation: 'Date of creation',
				dateOfDeparture: 'Date of departure',
				visitType: 'Type of departure',
				visitSubType: 'Subtype of departure',
				dateOfReturn: 'Date of return',
				resultOfVisit: 'Result of visit',
				moveTechnique: 'Move techniques',
				returnTechnique: 'Return techniques',
				delete: 'Delete departure',
				address: 'Address, object',
				returnedBack: 'Technique was returned back',
				addressManual: 'Address, object (manual)',
			},
			weather: {
				title: 'Weather',
			},
			calls: {
				title: 'Calls',
				updated: 'Updated',
				rank: 'Rank',
			},
			plans: {
				title: 'Plan for the year',
				plural: 'Plans for the year',
				year: 'Year',
				visitType: 'Type of departure',
				visitSubType: 'Subtype of departure',
				amount: 'Amount of departure',
			},
		},
		techFireCallStatus: {
			plural: 'Calls statuses',
			title: 'Call status',
			updatedAt: 'Updated At',
		},
		techFireVisitType: {
			plural: 'Visit types',
			title: 'Visit type',
			updatedAt: 'Updated At',
			parent: 'Parent',
		},
		techFireVisitSubType: {
			plural: 'Visit subtypes',
			title: 'Visit subtype',
			parent: 'Parent',
			updatedAt: 'Updated At',
		},
		techFireObjectType: {
			plural: 'Types of object',
			title: 'Type of object',
			updatedAt: 'Updated At',
		},
		techFireAddress: {
			registry: 'Address registry',
			fireDepFrom: 'From fire department',
			fireDepTo: 'To fire department',
		},
		fireDepNote: {
			title: 'Drill note',
		},
		techFireOrganization: {
			isGarrison: 'Garrison',
			parent: 'Parent category',
			title: 'Organization',
			plural: 'Organizations',
			staffUnits: 'Штат ед.',
			officerUnits: 'Офицеры ед.',
			supervisorUnits: 'Руководство ед.',
			supervisorOfficerUnits: 'Руководство офицеры ед.',
			OOSPandPUnits: 'ООСПиП штат ед.',
			OOSPandPOfficerUnits: 'ООСПиП офицеры ед.',
			laborProtectionUnits: 'Служба охраны труда штат ед.',
			laborProtectionOfficerUnits: 'Служба охраны труда офицеры ед.',
		},
		municipalityStatus: {
			title: 'Municipality status',
			plural: 'Municipality statuses',
		},
		municipality: {
			attachExplanatoryNoteFiles: 'Прикрепить файлы пояснительной записки',
			explanatoryNote: 'Краткие выводы из оценки ситуации',
			title: 'Муниципальное образование',
			plural: 'Муниципальные образования',
			name: 'Наименование',
			citiesCount: 'Населеных пунктов',
			population: 'Население',
			area: 'Общая площадь, кв.м',
			status: 'Статус',
		},
		analytic: {
			title: 'Oparative information',
			plural: 'Oparative information',
			productName: 'Название груза',
			date: 'Дата статистики',
			settlement: 'Населенные пункты',
			organization: 'Контрагенты',
			mainInfo: 'Основная информация',
			plan: 'План',
			ship: 'Отгружено',
			deliver: 'Доставлено',
			contragent: 'Контрагент',
			tab1: 'Страница 1',
			tab2: 'Страница 2',
		},
		supplyCategory: {
			title: 'Supply catogory',
			plural: 'Supply catogories',
			parent: 'Parent category',
		},
		nomenclatureCategory: {
			title: 'Supply catogory',
			plural: 'Supply catogories',
			parent: 'Parent category',
		},
		requestMemberStatus: {
			title: 'Request member status',
			plural: 'Request member statuses',
		},
		requestTrackStatus: {
			title: 'Request track status',
			plural: 'Request track statuses',
		},
		requestType: {
			title: 'Request type',
			plural: 'Request types',
		},
		requestStatus: {
			title: 'Request status',
			plural: 'Request statuses',
			forestry: 'Forestry',
		},
		firePlan: {
			title: 'Fire plan',
			plural: 'Fire plans',
			forestry: 'Forestry',
			new: 'New fire plan',
		},
		supplyMeasure: {
			title: 'Supply measure',
			plural: 'Supply measure',
		},
		supplyNomenclature: {
			title: 'Supply nomenclature',
			plural: 'Supply nomenclatures',
		},
		emergencyNomenclature: {
			title: 'Nomenclature',
			plural: 'Nomenclatures',
			cityId: 'City',
			norma: 'Norma',
			unit: 'Unit',
		},
		emergencyReserves: {
			title: 'Reserves',
			name: 'Name',
			nomenclature: 'Nomenclature',
			fact: 'Fact',
			norma: 'Norma',
			county: 'County',
			city: 'City',
			unit: 'Unit',
			category: 'Сategory',
			date: 'Date',
		},
		fireDepVehicleCallSign: {
			title: 'FireDep vehicle call sign',
			plural: 'FireDep vehicle call signs',
			fireDep: 'FireDep',
		},
		adpiDevice: {
			title: 'Fire-hazardous object',
			plural: 'Fire-hazardous objects',
			adpiStatus: 'ADPI status',
			address: 'Address',
			adpiType: 'ADPI type',
		},
		fireDepPersonnel: {
			responsibleForCalling: 'Responsible for calling',
		},
		waterSupply: {
			number: 'Number',
			title: 'Water supply',
			plural: 'Water supplies',
			division: 'Division',
			typeName: 'Type',
			ownership: 'Ownership',
			intake: 'Intake',
			reference: 'Ref',
			working: 'Working',
			address: 'Address',
			comment: 'Comment',
			depName: 'Department',
			volume: 'Volume',
			defectCause: 'Defect cause',
			name: 'name',
			dateLastCheck: 'Last check date',
			addition: 'Addition',
			scheduledInspectionDate: 'Scheduled inspection date',
			identifiedFault: 'Identified fault',
			remediationOrganization: 'Remediation organization',
			overdueInspection: 'Overdue inspection',
			inspectorFullName: 'Full name of the inspector',
			inspectorPosition: 'Position of the inspector',
			inspectorOrganization: 'Organization of the inspector',
			fireDepPersonnel: 'Inspector from the staff',
			inspectionDateFrom: 'Scheduled inspection date from',
			inspectionDateTo: 'Scheduled inspection date to',
			fromListOfPersonnel: 'Select from the list of personnel',
			manualInspector: 'Fill in the inspector manually',
		},
		waterSupplyType: {
			title: 'Water supply type',
			plural: 'Water supply types',
		},
		waterSupplyDivision: {
			title: 'Water supply division',
			plural: 'Water supply divisions',
		},
		dateRangePicker: {
			startDate: 'Start date',
			endDate: 'End date',
			period: 'Period',
		},
		contact: {
			title: 'Contract',
			plural: 'Contacts',
		},
		field: {
			empty: 'Empty',
		},
		permafrost: {
			map: 'Map',
			tube: 'Temperature tube',
			temperatureTube: {
				geo: 'Geo',
				building: 'Building',
				number: 'Number',
				type: 'Type',
				maxDepth: 'Max depth of temperature tube',
				status: 'Status',
				pile: 'Pile',
			},
		},
		permafrostTemperatureTubeBuilding: {
			title: 'Building',
			plural: 'Buildings',
		},
		permafrostTemperatureTubeMaxDepth: {
			title: 'Max depth of temperature tube',
			plural: 'Max depth of temperature tubes',
		},
		permafrostTemperatureTubeStatus: {
			title: 'Status',
			plural: 'Statuses',
		},
		permafrostTemperatureTubeType: {
			title: 'Type',
			plural: 'Types',
		},
	},
};
