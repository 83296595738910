import React from 'react';
import { Card, NumberInput, LabelField } from 'components';
import t from 'i18n';
import { Field, DatePicker, Row, Select, Input, RelationSelect } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

/**
 * CustomTechFireCard компонент, который рендерит карточку с динамическим набором полей.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.title - Заголовок карточки, локализованный ключ.
 * @param {React.ComponentType} props.icon - Иконка для карточки.
 * @param {Array} [props.rows=[]] - Массив строк (для вёрстки - один под другим), где каждая строка содержит массив объектов полей.
 * @param {Array} rows[].fields - Массив объектов, представляющих поля.
 * @param {string} fields[].property - Имя свойства поля.
 * @param {string} fields[].label - Локализованный ключ для метки поля.
 * @param {string} fields[].type - Тип поля (DatePicker, textarea, NumberInput, Select, Input, LabelField, relation, label).
 * @param {Object} [fields[].props] - Дополнительные свойства для компонента поля.
 * @param {string} [fields[].relation] - Используется для отношения, если type равен 'relation'.

 * Обратите внимание:
 * - Поле типа 'textarea' отображает placeholder, если передан в rows.
 * - Так же 'label' отображается только если он передан в rows.
 */

@observer
class CustomTechFireCard extends React.Component {
	@observable collapsed = false;

	handleCollapseToggle = () => {
		this.collapsed = !this.collapsed;
	};

	render() {
		const { title, icon: Icon, rows = [] } = this.props;

		return (
			<Card title={t(title)} icon={<Icon />} collapsible collapsed={this.collapsed} onCollapseToggle={this.handleCollapseToggle}>
				{rows.map((fields, rowIndex) => (
					<Row className='card-row' key={rowIndex}>
						{fields.map(({ property, label, placeholder, type, props, disabled, relation }, fieldIndex) => {
							const key = `${property}-${rowIndex}-${fieldIndex}`;
							switch (type) {
								case 'DatePicker':
									return (
										<Field key={key} property={property} label={label ? t(label) : undefined}>
											<DatePicker {...props} />
										</Field>
									);
								case 'textarea':
									return (
										<Field key={key} property={property} label={label ? t(label) : undefined}>
											<textarea {...props} placeholder={placeholder ? t(placeholder) : undefined} />
										</Field>
									);
								case 'NumberInput':
									return (
										<Field key={key} property={property} label={label ? t(label) : undefined} disabled={disabled}>
											<NumberInput {...props} />
										</Field>
									);
								case 'Select':
									return (
										<Field key={key} property={property} label={label ? t(label) : undefined}>
											<Select {...props} />
										</Field>
									);
								case 'Input':
									return (
										<Field key={key} property={property} label={label ? t(label) : undefined}>
											<Input {...props} />
										</Field>
									);
								case 'LabelField':
									return (
										<LabelField key={key} label={label ? t(label) : undefined}>
											<Input {...props} />
										</LabelField>
									);
								case 'relation':
									return (
										<Field key={key} relation={relation} property={property} label={label ? t(label) : undefined}>
											<RelationSelect {...props} />
										</Field>
									);
								case 'label':
									return <Field key={key} label={label ? t(label) : undefined} disabled />;
								default:
									return <div key={key} />;
							}
						})}
					</Row>
				))}
			</Card>
		);
	}
}

CustomTechFireCard.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.elementType.isRequired,
	rows: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				property: PropTypes.string.isRequired,
				label: PropTypes.string,
				placeholder: PropTypes.string,
				type: PropTypes.oneOf(['DatePicker', 'textarea', 'NumberInput', 'Select', 'Input', 'LabelField', 'relation', 'label']).isRequired,
				props: PropTypes.object,
				disabled: PropTypes.bool,
				relation: PropTypes.string,
			})
		)
	).isRequired,
};

export default CustomTechFireCard;
