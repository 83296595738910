import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar, ListStore } from 'components';
import store from 'client/store';
import t from 'i18n';
import { Column, Table, Pager } from '@smartplatform/ui';
import { TECH_FIRES_LIST_FILTER } from './constants';
import './techFiresList.scss';
import { observable } from 'mobx';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

@observer
export class TechFiresList extends React.Component {
	@observable garrison = null;
	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, model: store.model.TechFire, filters: TECH_FIRES_LIST_FILTER });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onGarrisonChange = async (value) => {
		this.garrison = value;
		const where = { or: [] };
		if (value) {
			const fireDepIds = (await store.model.ViewFireDep.find({ where: { garrisonId: value.id }, fields: ['id'] })).map((r) => r.id);
			where.or = [{ garrisonId: value.id }, { fireDepId: { inq: fireDepIds } }];
		}
		this.store.updateFilters({ ...TECH_FIRES_LIST_FILTER, where: { ...where } });
	};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<div className='tech-fires-list'>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<GarrisonSelect value={this.garrison} onChange={this.onGarrisonChange} width={200} />
					{/* нет полей для поиска, надо сделать вью */}
					{/* <Toolbar.SearchIconInput value={search} onChange={onSearch} /> */}
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table {...tableProps}>
						<Column property='id' label={t('id')} width={80} />
						<Column property='reportTime' label={t('techFire.messageFireDep')} width={140} />
						<Column relation='object' property='name' label={t('techFireAddress.title')} />
						<Column relation='source' property='description' label={t('techFireReportSource.title')} width={160} />
						<Column relation='status' property='name' label={t('status')} width={160} />
						<Column relation='rank' property='name' label={t('techFire.rank')} width={160} />
						<Column relation='fireDep' property='name' label={t('techFireAddress.fireDep')} width={120} />
						<Column relation='object' property='ptp' label={t('techFireAddress.ptp')} width={100} />
						<Column relation='object' property='ktp' label={t('techFireAddress.ktp')} width={100} />
						<Column relation='emergencyCode' property='code' label={t('techFireEmergencyCode.title')} width={160} />
						<Column property='destroyedBuildings' label={t('techFire.destroyedBuildings')} />
					</Table>
				</div>
			</div>
		);
	}
}
