import { getRoleNames } from 'client/tools';

export const INCLUDE_VEHICLES = {
	relation: 'vehicles',
	scope: {
		include: [
			{
				relation: 'type',
				scope: {
					include: ['specialType', 'vehicleCode'],
				},
			},
			{
				relation: 'brand',
				scope: {
					fields: ['id', 'name'],
				},
			},
			{
				relation: 'pump',
				scope: {
					fields: ['id', 'capacity'],
				},
			},
			{
				relation: 'cisternBulk',
				scope: {
					fields: ['id', 'volume'],
				},
			},
			{
				relation: 'status',
				scope: {
					fields: ['id', 'name', 'code'],
				},
			},
			{
				relation: 'callSign',
				scope: {
					fields: ['id', 'name'],
				},
			},
			'protectiveSuits',
			'personnel',
		],
		order: 'id asc',
	},
};

export const INCLUDE_PERSONNEL = {
	relation: 'personnel',
	scope: {
		where: { fireDepShiftId: null },
		include: [
			{ relation: 'vehicle', scope: { include: ['brand', 'type', 'callSign', { relation: 'status', scope: { fields: ['id', 'name', 'code'] } }] } },
			{ relation: 'status', scope: { fields: ['id', 'name', 'code'] } },
			{ relation: 'position', scope: { fields: ['id', 'name'] } },
		],
	},
};

export const INCLUDE_VIEW_PERSONNEL = [
	{ relation: 'vehicle', scope: { include: ['brand', 'type', 'callSign', { relation: 'status', scope: { fields: ['id', 'name', 'code'] } }] } },
	{ relation: 'status', scope: { fields: ['id', 'name', 'code'] } },
	{ relation: 'position', scope: { fields: ['id', 'name'] } },
];

export const isRadiotelephonist = () => {
	const roleNames = getRoleNames();
	return !!roleNames.includes('tfires');
};
