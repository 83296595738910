import { observable } from 'mobx';
import store from 'client/store';
import { CountiesLayer, GenShtabLayer, WeatherLayer, FiresLayer, WindLayer, ShipsLayer, FireDepLayer, IncidentLayer, TrackingLayer, ModelsLayer } from 'components';

class DeliveryMap {
	@observable mapInitialized = false;
	@observable popup = null;
	@observable date = new Date();

	layers = {};

	onMapInit = async (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		this.layersSettings = store.local.delivery.layers;
		this.layers.modelsLayer = new ModelsLayer(mapStore, this.layersSettings.models);
		this.layers.modelsLayer.init();

		this.layers.counties = new CountiesLayer(mapStore, this.layersSettings.counties);
		this.layers.genShtab = new GenShtabLayer(mapStore, this.layersSettings.genShtab);
		this.layers.weather = new WeatherLayer(mapStore, this.layersSettings.weather);
		this.layers.wind = new WindLayer(mapStore, this.layersSettings.wind);
		this.layers.firesLayer = new FiresLayer(mapStore, this.layersSettings.fires);
		this.layers.firesLayer.init();

		this.layers.fireDepLayer = new FireDepLayer(mapStore, this.layersSettings.fireDep);
		this.layers.fireDepLayer.init();

		this.layers.incidentLayer = new IncidentLayer(mapStore, this.layersSettings.incidents);
		this.layers.incidentLayer.init();

		this.layers.trackingLayer = new TrackingLayer(mapStore, this.layersSettings.tracking);
		this.layers.trackingLayer.init();

		this.layers.counties.init();
		this.layers.genShtab.init();
		this.layers.weather.init();
		this.layers.wind.init();

		this.layers.ships = new ShipsLayer(mapStore, this.layersSettings.ships);
		this.layers.ships.init();

		// this.map.on('click', this.onClick);
		this.map.on('pointermove', this.onPointerMove);

		mapStore.on('cleanup', this.destroy);
		this.mapInitialized = true;
	};

	onTileSourceChange = (tileSource) => {
		store.local.delivery.tileSource = tileSource;
		store.local.save();
	};

	onLayerToggle = (layer, localPath) => (isVisible) => {
		localPath.show = isVisible;
		store.local.save();
		layer.setVisible(isVisible);
	};

	onSettingsChange = (layer, localPath) => (settings) => {
		console.log('onSettingsChange', settings);
		Object.keys(settings).forEach((key) => {
			localPath.$mobx.write(localPath, key, settings[key]);
		});
		store.local.save();
		layer.updateSettings && layer.updateSettings(settings);
		layer.setVisible && layer.setVisible(settings.show);
	};

	/*
	onClick = async (e) => {
		const features = await this.map.getFeaturesAtPixel(e.pixel);
		const coords = this.map.getCoordinateFromPixel(e.pixel);
		const lonLat = transform(coords, 'EPSG:3857', 'EPSG:4326');

		const [lon, lat] = lonLat

		if (features.length === 0) {
			this.popup = null;
			return;
		}

		let popup = { lonLat: [lon, lat], offset: 0, objects: [] };
		let initial = true;

		for (let feature of features) {
			const data = feature.getProperties();
			console.log('> data', data);

			// initial нужна для того чтобы вывести попап по координатам первого (самого верхнего) объекта
			if (data.record && initial) {
				if (data.record.MODEL) {
					if (data.record.geo.type === 'Point') {
						popup.lonLat = data.record.geo.coordinates;
					}
					else {
						const geometry = geoJSON.readGeometry(data.record.geo);
						const feature = new Feature({ geometry, ...data });
						const center = toLonLat(getFeatureCenter(feature));
						popup.lonLat = center;
					}
					popup.offset = 20;
				}
				else if (data.record.type === 'ship') {
					popup.lonLat = [ data.record.LON, data.record.LAT ];
				}
				popup.objects.push(data);
				initial = false;
			}
		}

		this.popup = popup;
	};
*/

	destroy = () => {
		this.mapStore.off('cleanup', this.destroy);
		this.mapInitialized = false;
	};

	onPointerMove = async (e) => {
		this.layers.weather.updateTip(e);
	};

	switchFilters = (maximize) => {
		store.local.delivery.filtersMaximized = maximize !== undefined ? maximize : !store.local.delivery.filtersMaximized;
		store.local.save();
	};
}

export default new DeliveryMap();
