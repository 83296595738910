import React from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import store from 'client/store';
import module from './index';
import t from 'i18n';
import { ModuleWrapper } from 'components';
import Map from './map';
import { DynamicLayers } from '@smartplatform/map/client';
import Fires from './fires';
import Calls from './calls';
import { FireDepartments } from './firedepartments';
import Note from './note';
import { Notifier } from './notifier';
import Reports from './reports';
import AddressRegistry from './address-registry';
import Weather from './weather';
import Shifts from './shifts';
import { DICTIONARIES_MODELS, REGISTRY_MODELS } from './constants';
import loadable from '@loadable/component';
const TransportModule = loadable(() => import('components/transport-module'));
const DictionariesPage = loadable(() => import('components/dictionaries/dictionaries-page'));
import './style.scss';
import Dashboard from './dashboard';
import techFiresStore from './techFiresStore';
import NotePage from 'techfires/note/NotePage';

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		if (!techFiresStore.isInitialized) techFiresStore.init();
		store.techFiresStore = techFiresStore;
	}

	render() {
		if (!techFiresStore.isInitialized) return null;
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='techfires-module' module={module} path={path} redirectPath={`${path}/map`}>
				<Route path={`${path}/map`} component={Map} />
				<Route path={`${path}/layers`} render={(routeProps) => <DynamicLayers {...routeProps} store={store} />} />
				<Route>
					<div className='techfires-page'>
						<Route path={`${path}/dashboard`} component={Dashboard} />
						<Route path={`${path}/fires`} component={Fires} />
						<Route path={`${path}/weather`} component={Weather} />
						<Route path={`${path}/firedep`} component={FireDepartments} />
						<Route path={`${path}/note`} render={(props) => <NotePage {...props} />} />
						<Route path={`${path}/addresses`} component={AddressRegistry} />
						<Route path={`${path}/addresses-objects`} component={AddressRegistry} />
						<Route path={`${path}/registry`} render={(props) => <DictionariesPage models={REGISTRY_MODELS} title={t('fire.registry.title')} {...props} />} />
						<Route path={`${path}/notifier`} component={Notifier} />
						<Route path={`${path}/calls`} component={Calls} />
						<Route path={`${path}/reports`} component={Reports} />
						<Route path={`${path}/transport`} render={() => <TransportModule reportRelation='techFireReport' />} />
						<Route path={`${path}/dictionaries`} render={(props) => <DictionariesPage models={DICTIONARIES_MODELS} {...props} />} />
						<Route path={`${path}/shifts`} component={Shifts} />
					</div>
				</Route>
			</ModuleWrapper>
		);
	}
}
