import React from 'react';
import '../style.scss';
import OperationReport from './operation/OperationReport';
import DashboardStore from 'techfires/dashboard/store';
import { observer, Provider } from 'mobx-react';
import DashboardToolbar from 'techfires/dashboard/main/toolbar/DashboardToolbar';
import t from '@smartplatform/map/i18n';

@observer
export default class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.store = DashboardStore;
		this.store.init();
	}

	render() {
		const { canView } = this.store;

		if (!canView) return t('noneAccessPage');

		return (
			<Provider store={this.store}>
				<div className='techfires-page-dashboard'>
					<DashboardToolbar />
					<OperationReport />
				</div>
			</Provider>
		);
	}
}
