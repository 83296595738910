import React from 'react';
import './style.scss';
import { inject, observer } from 'mobx-react';
import { Loader } from '@smartplatform/ui';
import AlertTriangleIcon from 'img/icons/fires/dashboard/alert-triangle.svg';
import HomeLineIcon from 'img/icons/fires/dashboard/home-line.svg';
import EmergencySituationIcon from 'img/icons/fires/dashboard/emergency-situation.svg';
import { Card } from 'components';
import ArrowIntact from 'client/img/icons/techfires/arrows/arrow_intact.svg';
import ArrowDownRed from 'client/img/icons/techfires/arrows/arrow_down_red.svg';
import ArrowDownGreen from 'client/img/icons/techfires/arrows/arrow_down_green.svg';
import ArrowUpRed from 'client/img/icons/techfires/arrows/arrow_up_red.svg';
import ArrowUpGreen from 'client/img/icons/techfires/arrows/arrow_up_green.svg';
import t from 'i18n';

const CODE = 'DashboardStat';

@inject('store')
@observer
export default class StatisticsReport extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.findOrCreateReport(CODE, t('techFire.statistics.dashboardStat'));
	}

	render() {
		const { isLoading, statData } = this.store;
		if (isLoading) return <Loader size={20} />;
		
		const data = statData.length ? statData[0] : null;

		return (
			<>
				<Card icon={<AlertTriangleIcon />} title={t('techFire.statistics.firesAndDamage')}>
					<div className='numbers-row'>
						<div className='number-block red'>
							<label>{t('techFire.statistics.numberOfFires')}</label>
							<strong className='number-value'>{data ? data.totalfires || 0 : 0}</strong>
							<em className={data.percenttotalfires > 0 ? 'number-percent red' : data.percenttotalfires < 0 ? 'number-percent green' : 'number-percent'}>
								{data.percenttotalfires > 0 ? <ArrowUpRed /> : data.percenttotalfires < 0 ? <ArrowDownGreen /> : <ArrowIntact />}
								{data.percenttotalfires} %
							</em>
						</div>
						<div className='number-block red'>
							<label>{t('techFire.statistics.fireDamage')}, {t('techFire.statistics.thousandRub')}</label>						
							<strong className='number-value'>{data ? data.totaldamage || 0 : 0}</strong>
							<em className={data.percenttotaldamage > 0 ? 'number-percent red' : data.percenttotaldamage < 0 ? 'number-percent green' : 'number-percent'}>
								{data.percenttotaldamage > 0 ? <ArrowUpRed /> : data.percenttotaldamage < 0 ? <ArrowDownGreen /> : <ArrowIntact />}
								{data.percenttotaldamage} %
							</em>
						</div>
						<div className='number-block green'>
							<label>{t('techFire.statistics.materialAssetsSaved')}, {t('techFire.statistics.thousandRub')}</label>						
							<strong className='number-value'>{data ? data.totalsavedvalues || 0 : 0}</strong>
							<em className={data.percenttotalsavedvalues > 0 ? 'number-percent red' : data.percenttotalsavedvalues < 0 ? 'number-percent green' : 'number-percent'}>
								{data.percenttotalsavedvalues > 0 ? <ArrowUpRed /> : data.percenttotalsavedvalues < 0 ? <ArrowDownGreen /> : <ArrowIntact />}
								{data.percenttotalsavedvalues} %
							</em>
						</div>
					</div>
				</Card>
				<Card icon={<HomeLineIcon />} title={t('techFire.statistics.casualties')}>
					<div className='numbers-row'>
						<div className='number-block red'>
							<label>{t('total')}</label>
							<strong className='number-value'>{data ? data.totaldeceased || 0 : 0}</strong>
							<em className={data.percenttotaldeceased > 0 ? 'number-percent red' : data.percenttotaldeceased < 0 ? 'number-percent green' : 'number-percent'}>
								{data.percenttotaldeceased > 0 ? <ArrowUpRed /> : data.percenttotaldeceased < 0 ? <ArrowDownGreen /> : <ArrowIntact />}
								{data.percenttotaldeceased} %
							</em>
						</div>
						<div className='number-block red'>
							<label>{t('techFire.statistics.includingChildren')}</label>						
							<strong className='number-value'>{data ? data.totalchilddeceased || 0 : 0}</strong>
							<em className={data.percenttotalchilddeceased > 0 ? 'number-percent red' : data.percenttotalchilddeceased < 0 ? 'number-percent green' : 'number-percent'}>
								{data.percenttotalchilddeceased > 0 ? <ArrowUpRed /> : data.percenttotalchilddeceased < 0 ? <ArrowDownGreen /> :  <ArrowIntact />}
								{data.percenttotalchilddeceased} %
							</em>
						</div>
						<div className='number-block green'>
							<label>{t('techFire.statistics.peopleSaved')}</label>						
							<strong className='number-value'>{data ? data.totalrescuedpeople || 0 : 0}</strong>
							<em className={data.percenttotalrescuedpeople > 0 ? 'number-percent green' : data.percenttotalrescuedpeople < 0 ? 'number-percent red' : 'number-percent'}>
								{data.percenttotalrescuedpeople > 0 ? <ArrowUpGreen /> : data.percenttotalrescuedpeople < 0 ? <ArrowDownRed /> : <ArrowIntact />}
								{data.percenttotalrescuedpeople} %
							</em>
						</div>
					</div>
				</Card>
				<Card icon={<EmergencySituationIcon />} title={t('techFire.statistics.injuriesReceived')}>
					<div className='numbers-row'>
						<div className='number-block red'>
							<label>{t('total')}</label>
							<strong className='number-value'>{data ? data.totalinjured || 0 : 0}</strong>
							<em className={data.percenttotalinjured > 0 ? 'number-percent red' : data.percenttotalinjured < 0 ? 'number-percent green' : 'number-percent'}>
								{data.percenttotalinjured > 0 ? <ArrowUpRed /> : data.percenttotalinjured < 0 ? <ArrowDownGreen /> : <ArrowIntact />}
								{data.percenttotalinjured} %
							</em>
						</div>
						<div className='number-block red'>
							<label>{t('techFire.statistics.includingChildren')}</label>						
							<strong className='number-value'>{data ? data.totalchildinjured || 0 : 0}</strong>
							<em className={data.percenttotalchildinjured > 0 ? 'number-percent red' : data.percenttotalchildinjured < 0 ? 'number-percent green' : 'number-percent'}>
								{data.percenttotalchildinjured > 0 ?  <ArrowUpRed /> : data.percenttotalchildinjured < 0 ? <ArrowDownGreen /> : <ArrowIntact />}
								{data.percenttotalchildinjured} %
							</em>
						</div>
						<div className='number-block green'>
							<label>{t('techFire.statistics.peopleEvacuated')}</label>						
							<strong className='number-value'>{data ? data.totalsavedpeople || 0 : 0}</strong>
							<em className={data.percenttotalsavedpeople > 0 ? 'number-percent green' : data.percenttotalsavedpeople < 0 ? 'number-percent red' : 'number-percent'}>
								{data.percenttotalsavedpeople > 0 ? <ArrowUpGreen /> : data.percenttotalsavedpeople < 0 ? <ArrowDownRed /> : <ArrowIntact />}
								{data.percenttotalsavedpeople} %
							</em>
						</div>
					</div>
				</Card>
			</>
		);
	}
}