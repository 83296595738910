import React from 'react';
import { Style, Icon } from 'ol/style';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { createXYZ } from 'ol/tilegrid';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import t from 'i18n';
import shipIconUrl from 'img/icons/shared/shipMarker.svg?url';
import { ShipPopup } from './ShipPopup';

const TILE_SIZE = 512;

export class ShipsLayer {
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({
				extent: getProj('EPSG:3857').getExtent(),
				maxZoom: 18,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction(this.renderPopup),
		});

		this.layer = new VectorTileLayer({
			source: this.source,
			style: this.getStyle,
		});

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
	};
	getStyle = (feature) => {
		const { record } = feature.getProperties();
		return new Style({
			image: new Icon({
				src: shipIconUrl,
				scale: 0.7,
				rotateWithView: true,
				rotation: (Math.PI / 180) * Number(record.course),
				anchor: [17.5, 30],
				anchorXUnits: 'pixels',
				anchorYUnits: 'pixels',	
			}),
		});
	};

	tileUrlFunction = (coords) => {
		let filter = '&filter=';
		return `/api/mvt?model=RequestShip&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}${filter}&noCache=1&columns=id,speed,course&buffer=${TILE_SIZE}&extent=${TILE_SIZE}`;
	};

	tileLoadFunction = (render) => (tile, url) => {
		tile.setLoader(function (extent, resolution, projection) {
			fetch(url).then(function (response) {
				response.arrayBuffer().then(function (data) {
					const format = tile.getFormat(); // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});
					renderFeatures.forEach((f) => {
						const record = f.getProperties();
						f.properties_ = {
							title: t('ship.title') + ' №' + record.id,
							record,
							render,
						};
					});

					tile.setFeatures(renderFeatures);
				});
			});
		});
	};

	setVisible = () => this.layer.setVisible(this.settings.show);
	renderPopup = (data) => <ShipPopup record={data} />;
}
