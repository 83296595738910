import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar, ListStore } from 'components';
import store from 'client/store';
import t from 'i18n';
import { Column, Table, Pager } from '@smartplatform/ui';
import { observable } from 'mobx';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

const FILTER = { include: [{ relation: 'fireDep', scope: { fields: ['id', 'name'] } }] };

@observer
export class PlanVisitsList extends React.Component {
	@observable garrison = null;
	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, model: store.model.viewTechFirePlanVisit, filters: FILTER });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onGarrisonChange = async (value) => {
		this.garrison = value;
		const where = {};
		if (value) {
			const fireDepIds = (await store.model.ViewFireDep.find({ where: { garrisonId: value.id }, fields: ['id'] })).map((r) => r.id);
			where.or = [{ garrisonId: value.id }, { fireDepId: { inq: fireDepIds } }];
		}
		this.store.updateFilters({ ...FILTER, where: { ...where } });
		this.reload();
	};

	visitTypeAndSubType = (record) => {
		if (!record) return '';
		return record.subtypeid ? `${record.typename || ''} / ${record.subtypename || ''}` : `${record.typename || ''}`;
	};

	getFireDeps = (record) => {
		if (!record) return '';
		if (record.typecode === 'RELOCATE') return `${record.firedepfromname} / ${record.firedeptoname}`;
		return record.firedeps;
	};

	reload = () => setTimeout(() => this.store.reload && this.store.reload(), 0);

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<div className='plan-visits-list'>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<GarrisonSelect value={this.garrison} onChange={this.onGarrisonChange} width={200} />
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table {...tableProps}>
						<Column property='id' label={t('id')} width={80} />
						<Column property='createdAt' label={t('techFire.firesPlanVisits.dateOfCreation')} />
						<Column relation='garrison' property='name' label={t('garrison')} />
						<Column computed={this.visitTypeAndSubType} label={t('techFire.firesPlanVisits.visitType')} />
						<Column relation='fireDep' property='name' label={t('techFireAddress.fireDep')} />
						<Column computed={this.getFireDeps} label={t('techFire.firesPlanVisits.fireDeps')} />
						<Column property='dateOfDeparture' label={t('techFire.firesPlanVisits.dateOfDeparture')} />
						<Column property='dateOfReturn' label={t('techFire.firesPlanVisits.dateOfReturn')} />
					</Table>
				</div>
			</div>
		);
	}
}
