import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import MarkerPinIcon from 'img/icons/fires/card/marker-pin-01.svg';
import { Card, LabelField, DegreeInput, Hint } from 'components';
import FireMap from '../map';
import { observable } from 'mobx';
import { Button, Row } from '@smartplatform/ui';
import classNames from 'classnames';

@inject('card')
@observer
export class Location extends React.Component {
	@observable lon = null;
	@observable lat = null;

	constructor(props) {
		super(props);
		if (props.card) {
			props.card.location = {
				updateLocationFields: this.updateLocationFields,
			};
		}
		this.updateLocationFields();
	}

	updateLocationFields = () => {
		const fire = this.props.card.fire;
		if (fire && fire.hqGeo) {
			const [lon, lat] = fire.hqGeo.coordinates;
			this.lon = lon;
			this.lat = lat;
		}
	};

	onLatChange = (value, e) => {
		this.lat = value;
		this.props.card.map.checkCoords(this.lon, this.lat);
	};

	onLonChange = (value) => {
		this.lon = value;
		this.props.card.map.checkCoords(this.lon, this.lat);
	};

	render() {
		const { card, disabled } = this.props;
		const cardClassName = classNames(card.mapMaximized ? 'full' : 'preview');
		return (
			<Card title={t('fire.geo')} icon={<MarkerPinIcon />} className='map-card'>
				<FireMap className={cardClassName} />

				<Row className='mt-2'>
					<LabelField label={t('latitude')} isRequired>
						<DegreeInput type='latitude' value={this.lat} onChange={this.onLatChange} className={!this.lat && 'field-empty'}/>
					</LabelField>
					<LabelField label={t('longitude')} isRequired>
						<DegreeInput type='longitude' value={this.lon} onChange={this.onLonChange} className={!this.lon && 'field-empty'}/>
					</LabelField>

					{card.map.mapStore && (
						<LabelField label={<Hint size='2x' text={t('fire.pickPointHint')} trigger='hover' />}>
							{!card.map.mapStore.picking ? (
								<Button variant='primary' disabled={disabled} onClick={card.map.startPickingHqGeo}>
									{t('fire.pickPoint')}
								</Button>
							) : (
								<Button variant='primary' onClick={card.map.cancelPickingHqGeo}>
									{t('cancel')}
								</Button>
							)}
						</LabelField>
					)}
				</Row>
			</Card>
		);
	}
}
