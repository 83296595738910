import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import { Input, Row, StringValue, Form } from '@smartplatform/ui';
import { Card } from 'components';
import { renderStatus } from 'client/tools';
import { ALARM_TYPE_ADPI, ALARM_TYPE_112, ALARM_TYPE_PHONE, ALARM_TYPE_MANUAL } from 'techfires/constants';
import t from 'i18n';
import UserIcon from 'img/icons/techfires/card/user.svg';
import AlarmAdpi from './Adpi';
import Alarm112 from './112';
import AlarmPhone from './Phone';
import AlarmManual from './Manual';
import FieldChecker from '../../../../../helpers/fieldChecker';

@inject('fireStore')
@observer
export default class InfoAboutPerson extends React.Component {
	@observable alarmMessage = null;
	@observable error = null;
	@observable isLoading = true;
	@observable collapsed = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { id } = this.props.fireStore;
		try {
			const fire = await store.model.TechFire.findById(id, {
				fields: ['id', 'alarmMessageId', 'sourceId'],
				include: [
					{
						relation: 'alarmMessage',
						scope: {
							include: [
								'source',
								{
									relation: 'adpiDevice',
									scope: {
										include: [
											{
												relation: 'address',
												scope: {
													include: [{ relation: 'fireDep', scope: { fields: ['id', 'name'] } }, { relation: 'address' }, { relation: 'rank' }],
												},
											},
											'organization',
										],
									},
								},
								'status',
							],
						},
					},
				],
			});
			this.alarmMessage = fire.alarmMessage;
		} catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};

	onSave = () => {
		store.ui.setNotification({
			message: 'Запись сохранена',
			type: 'SUCCESS',
		});
	};

	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('infoAboutPerson', areFieldsFilled);
	};

	handleCollapseToggle = () => {
		this.collapsed = !this.collapsed;
	};

	render() {
		if (this.error) return this.error;
		if (this.isLoading) return;
		const { fire } = this.props.fireStore;
		const { alarmMessage } = fire;
		const alarmType = this.alarmMessage && this.alarmMessage.source?.code;
		return (
			<FieldChecker
				fields={[alarmMessage?.reportingPersonPhone, alarmMessage?.reportingPersonName, alarmMessage?.reportingPersonAddress, alarmMessage?.information]}
				onStatusChange={this.handleStatusChange}
			>
				{(areFieldsFilled) => (
					<Card
						title={t('techFire.infoAboutPerson')}
						icon={<UserIcon />}
						isValid={areFieldsFilled}
						collapsible
						collapsed={this.collapsed}
						onCollapseToggle={this.handleCollapseToggle}
					>
						{this.alarmMessage ? (
							<Form record={this.alarmMessage} className='alarm-message' onSave={this.onSave} noDelete stay>
								<Row className='card-row'>
									<div className='form-field'>
										<label>{t('techFireReportSource.title')}</label>
										<Input value={this.alarmMessage?.source?.description} disabled />
									</div>
									<div className='form-field'>
										<label>{t('techFireReportSource.title')}</label>
										<StringValue value={renderStatus(this.alarmMessage.status)} />
									</div>
									<div />
								</Row>
								{alarmType === ALARM_TYPE_ADPI && <AlarmAdpi alarmMessage={this.alarmMessage} />}
								{alarmType === ALARM_TYPE_112 && <Alarm112 alarmMessage={this.alarmMessage} />}
								{alarmType === ALARM_TYPE_PHONE && <AlarmPhone record={this.alarmMessage} canEdit />}
								{alarmType === ALARM_TYPE_MANUAL && <AlarmManual record={this.alarmMessage} canEdit />}
							</Form>
						) : (
							<div>Нету сообщения о пожаре</div>
						)}
					</Card>
				)}
			</FieldChecker>
		);
	}
}
