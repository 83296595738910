import React from 'react';
import { observer } from 'mobx-react';

import { Form, Row, Field, Button, DatePicker, Loader, Pager, Table, Column, RecordSelect, Checkbox, RelationSelect } from '@smartplatform/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PlanVisitCreationStore from './store';
import { ListStore, TechFireAddressSearch } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { observable } from 'mobx';
import { getPrefixByCountyName } from 'client/tools/getPrefixByCountyName';

@observer
export default class CreateFirePlanVisit extends React.Component {
	@observable fireDepFrom = null;
	@observable fireDepTo = null;
	@observable selectedVehicles = [];
	@observable moveAllowed = null;
	@observable type = null;
	@observable subtype = null;
	@observable showSubtype = false;

	constructor(props) {
		super(props);
		this.createdDate = new Date();
		this.store = new PlanVisitCreationStore();
		this.store.path = props.path;
		this.requiredFields = ['dateOfDeparture', 'fireDepId'];
		this.relocationOfTechnique = false;
		this.vehicleStore = new ListStore({ path: props.path, fields: {}, model: store.model['ViewFireDepVehicle'] }); // FireDepVehicle
		this.fireDepFrom = null;
		this.fireDepTo = null;
		this.selectedVehicles = [];
		this.moveAllowed = store.model.roles.filter((role) => ['admin', 'tfires_main'].includes(role.name)).length > 0;
		this.type = null;
	}

	componentDidMount() {
		this.vehicleStore.setPerPage();
		this.updateFilter();
	}

	onChangeFireDepFrom(fireDepFrom) {
		this.fireDepFrom = fireDepFrom;
		this.updateFilter();
	}

	onChangeFireDepTo(fireDepTo) {
		this.fireDepTo = fireDepTo;
		this.updateFilter();
	}

	onVehicleToggle = (vehicle) => (value) => {
		const index = this.selectedVehicles.findIndex((vehicleId) => vehicleId === vehicle.id);
		if (value) {
			if (index === -1) this.selectedVehicles.push(vehicle.id);
		} else {
			if (index !== -1) this.selectedVehicles.splice(index, 1);
		}
	};

	renderCheckbox = ({ record }) => {
		const checked = this.selectedVehicles.includes(record.id);
		return <Checkbox checked={checked} onChange={this.onVehicleToggle(record)} />;
	};

	onChangeType(fireVisit) {
		this.type = fireVisit.visitType;
		this.subtype = null;
		fireVisit.visitSubType = null;
		this.type !== null && ['PTZ', 'PTU'].includes(this.type.code) ? (this.showSubtype = true) : (this.showSubtype = false);
	}

	onSaveRelocationOfTechniques = () => {
		const { saveRelocationOfTechnique } = this.store;
		saveRelocationOfTechnique(this.selectedVehicles);
	};

	updateFilter = () => {
		const filter = {};
		filter.where = { and: [{ statusCode: { neq: 'FF' } }] }; //перемещять можно любую технику, кроме той что на пожаре!
		//filter.where = { and: [{ statusCode: { neq: null } }] };
		//filter.where.and.push({ or: [{ statusCode: 'CC4' }, { statusCode: 'RESERV27' }] });
		if (this.fireDepFrom) {
			filter.where.and.push({ fireDepId: this.fireDepFrom.id });
		}
		this.filter = filter;
		this.vehicleStore.updateFilters(this.filter);
	};

	render() {
		const { firePlanVisit, isLoading, saveFire, cancelFire, onChange, requiredFieldsFilled, onObjectChange, onObjectReset } = this.store;
		const { tableProps, totalCount, perPage, page } = this.vehicleStore;

		if (isLoading) return <Loader />;

		if (firePlanVisit.visitType) {
			firePlanVisit.visitType.code === 'RELOCATE' ? (this.relocationOfTechnique = true) : (this.relocationOfTechnique = false);
			this.relocationOfTechnique
				? (this.requiredFields = ['dateOfDeparture', 'fireDepFrom', 'fireDepTo', 'fireDepId'])
				: (this.requiredFields = ['dateOfDeparture', 'fireDepId']);
		}
		const disabledSave = !requiredFieldsFilled(this.requiredFields);
		//const disabledForm = firePlanVisit.fireDepId ? false : true;

		return (
			<div className='techfire-create'>
				<h1>{t('techFire.firesPlanVisits.create')}</h1>
				<Button
					onClick={() => {
						store.route.push({ path: this.props.path });
					}}
				>
					<FontAwesomeIcon icon={faArrowLeft} />
				</Button>
				<div className='form-and-waybill'>
					<div className='edit'>
						<Form record={firePlanVisit} noSave noDelete>
							<Field relation='garrison' property='name' label={t('garrison')} disabled>
								<RecordSelect isRequired filter={{ where: { isGarrison: true } }} />
							</Field>
							<Row>
								<Field relation='visitType' property='name' label={t('techFire.firesPlanVisits.visitType')}>
									<RecordSelect model={store.model.TechFireVisitType} onChange={() => this.onChangeType(firePlanVisit)} />
								</Field>
								{this.showSubtype && (
									<Field relation='visitSubType' property='name' label={t('techFire.firesPlanVisits.visitSubType')}>
										<RecordSelect model={store.model.TechFireVisitSubType} filter={{ where: { parentId: this.type?.id } }} />
									</Field>
								)}
							</Row>
							{firePlanVisit?.visitType?.name === 'АСР' && <Field property='isDtp' label={t('techFire.isDtp')} />}
							{!this.relocationOfTechnique ? (
								<>
									<Field property='objectManual' label={t('techFire.firesPlanVisits.addressManual')} />
									<Field relation='object' label={t('techFire.firesPlanVisits.address')} onChange={onChange('object')}>
										<TechFireAddressSearch onChange={onObjectChange} onReset={onObjectReset} prefix={getPrefixByCountyName(store.userCounty?.name)} />
									</Field>
									<Field relation='fireDep' property='name' label={t('techFireAddress.fireDep')} isRequired>
										<RelationSelect showValue={firePlanVisit.fireDep ? firePlanVisit.fireDep.name : '-'} onChange={onChange('fireDep')} />
									</Field>
								</>
							) : (
								<Row>
									<Field relation='fireDepFrom' property='name' label={t('techFireAddress.fireDepFrom')} isRequired={this.relocationOfTechnique}>
										<RecordSelect model={store.model.FireDep} onChange={() => this.onChangeFireDepFrom(firePlanVisit.fireDepFrom)} />
									</Field>
									<Field relation='fireDepTo' property='name' label={t('techFireAddress.fireDepTo')} isRequired={this.relocationOfTechnique}>
										<RecordSelect model={store.model.FireDep} onChange={() => this.onChangeFireDepTo(firePlanVisit.fireDepTo)} />
									</Field>
								</Row>
							)}
							<Row>
								<Field property='dateOfDeparture' label={t('techFire.firesPlanVisits.dateOfDeparture')}>
									<DatePicker showTimeSelect />
								</Field>
								<Field property='dateOfReturn' label={t('techFire.firesPlanVisits.dateOfReturn')}>
									<DatePicker showTimeSelect />
								</Field>
							</Row>
							<Row>
								<Field property='resultOfVisit' label={t('techFire.firesPlanVisits.resultOfVisit')}>
									<textarea rows={3} />
								</Field>
							</Row>
						</Form>
						{this.relocationOfTechnique && firePlanVisit.fireDepFrom && (
							<>
								<h1>{t('fireDepVehicle.plural')}</h1>
								<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
								<Table {...tableProps} onRowClick={null}>
									<Column width={30}>{this.renderCheckbox}</Column>
									<Column property='stateNumber' label={t('fireDepVehicle.stateNumber')} width={80} />
									<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} width={130} />
									<Column relation='fireDep' property='name' label={t('fireDepVehicle.fireDep')} width={130} />
								</Table>
							</>
						)}
						<div className='main-actions'>
							{!this.relocationOfTechnique ? (
								<Button onClick={saveFire} variant='primary' disabled={disabledSave}>
									{t('save')}
								</Button>
							) : (
								this.moveAllowed && (
									<Button onClick={() => this.onSaveRelocationOfTechniques()} variant='primary' disabled={disabledSave}>
										{t('techFire.firesPlanVisits.moveTechnique')}
									</Button>
								)
							)}
							<Button onClick={cancelFire}>{t('cancel')}</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
